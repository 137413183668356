import styled from "styled-components";
import { motion } from "framer-motion";

export const PackagesContainer = styled(motion.div)`
  min-width: calc(100vw - 4px);
  max-width: calc(100vw - 4px);
  min-height: calc(100px - 10px);
  padding: 5px 2px;
  position: absolute;
  bottom: 45px;
  margin-bottom: 5px;
  left: 0px;
  z-index: 99;
  /* background-color: red; */
  display: flex;
  flex-direction: row;
  /* justify-content: flex-start; */
  align-items: flex-start;
  overflow: scroll;

  .scrollable {
    display: flex;
    position: relative;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      /* background: cyan; */
      height: 85px;
      width: 85px;
      margin: 0px 2px;
      border-radius: 10px;
      position: relative;
      overflow: hidden;

      .overlay {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.8)
        );
      }

      label {
        position: absolute;
        bottom: 10px;
        font-size: 0.6rem;
        text-transform: uppercase;
        font-weight: bold;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
`;
