import React, { useState, Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Outlet,
} from "react-router-dom";
import Preloader from "../shared/Components/Preloader";
import { createBrowserHistory } from "history";
import Home from "../pages/Home";
import SeletorDeModelos from "../pages/SeletorDeModelos";
import AssistenteVirtual from "../pages/AssistenteVirtual";
import MapaConcessionarias from "../pages/MapaConcessionarias";
import Configurador from "../pages/Configurador";
import Header from "../shared/Components/Header";

import { useUser } from "../contexts/user";

const history = createBrowserHistory();

const RoutesTracbel = () => {
  const { user, setUser } = useUser();
  const [instructionOff, setInstructionOff] = useState(false);

  useEffect(() => {
    const sourceTrackLink = document.referrer;
    let domain = "";
    let mediaName = "Direto";
    if (
      sourceTrackLink !== "" &&
      sourceTrackLink !== null &&
      sourceTrackLink !== undefined
    ) {
      let url = new URL(sourceTrackLink);
      domain = url.hostname.replace("www.", "");
      switch (domain) {
        case "facebook.com":
          mediaName = "Facebook";
          break;
        case "instagram.com":
          mediaName = "Instagram";
          break;
        case "google.com":
          mediaName = "Google";
          break;
        case "youtube.com":
          mediaName = "Youtube";
          break;
        case "linkedin.com":
          mediaName = "Linkedin";
          break;
        case "tracbel.com.br":
          mediaName = "Direto";
          break;
        case "blog.tracbel.com.br":
          mediaName = "Blog Tracbel";
          break;
        case "maquinas.tracbel.com.br":
          mediaName = "Direto";
          break;
        default:
          mediaName = "Direto";
          break;
      }
    }

    if (window.location.search) {
      let valorUtm = window.location.search
        .split("?")[1]
        .split("&")[0]
        .split("=")[1];
      if (valorUtm !== "" && valorUtm !== undefined && valorUtm !== null) {
        switch (valorUtm) {
          case "facebook":
            mediaName = "Facebook Pago";
            break;
          case "instagram":
            mediaName = "Instagram Pago";
            break;
          case "google":
            mediaName = "Google Pago";
            break;
          case "youtube":
            mediaName = "Youtube Pago";
            break;
          case "linkedin":
            mediaName = "Linkedin Pago";
            break;
        }
      }
    }
    console.log(mediaName);
    setUser((values) => ({
      ...values,
      Midia: mediaName,
    }));
  }, []);
  return (
    <>
      <Suspense fallback={<Preloader />}>
        <Router history={history} basename="">
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/seletordemodelos" element={<SeletorDeModelos />} />
            <Route path="/assistentevirtual" element={<AssistenteVirtual />} />
            <Route
              path="/mapaconcessionarias"
              element={<MapaConcessionarias />}
            />
            <Route
              path="/configurador"
              element={
                <Configurador
                  instructionOff={instructionOff}
                  setInstructionOff={setInstructionOff}
                />
              }
            />
          </Routes>
        </Router>
      </Suspense>
    </>
  );
};

export default RoutesTracbel;
