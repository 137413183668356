import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 80px;
  /* background-color: red; */
  height: calc(100vh - 170px);
  top: 70px;
  /* background-color: red; */
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  margin-right: 15px;
  right: 0px;
  padding: 10px 0px;
  flex-direction: column;
  z-index: 1000000000;

  a {
    /* background-color: cyan; */
    margin: 5px 10px;
  }

  .custom-border {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    /* background-color: silver; */
    transition: all 0.2s linear;
    opacity: 1;

    .row {
      display: flex;
      flex: 1;
      width: 100%;

      span {
        display: flex;
        flex: 1;
        position: relative;
      }

      &:nth-child(1) {
        span {
          :nth-child(1) {
            /* margin-right: 2px; */
            position: relative;
            top: -3px;
            left: -3px;
            border-top: 4px solid white;
            border-left: 4px solid white;
          }

          :nth-child(2) {
            /* margin-right: 2px; */
            position: relative;
            top: -3px;
            right: -3px;
            border-top: 4px solid white;
            border-right: 4px solid white;
          }
        }
      }

      &:nth-child(2) {
        span {
          :nth-child(1) {
            /* margin-right: 2px; */
            position: relative;
            bottom: -3px;
            left: -3px;
            border-bottom: 4px solid white;
            border-left: 4px solid white;
          }

          :nth-child(2) {
            /* margin-right: 2px; */
            position: relative;
            bottom: -3px;
            right: -3px;
            border-bottom: 4px solid white;
            border-right: 4px solid white;
          }
        }
      }
    }
  }

  .right-menu-wrapper {
    /* background-color: red; */
    position: relative;
    flex-direction: row;

    &.on{
      &:hover{
          &.five {
          
            .label {
              display: none;
            }
          }
        
      }
    }

    &:hover {
      cursor: pointer;
      .label {
        /* left: -100px; */
        display: block;
      }

      /* &.second {
        .label {
          left: -110px;
        }
      }

      &.third {
        .label {
          left: -100px;
        }
      }

      &.fourth {
        .label {
          left: -90px;
        }
      }*/

      
    }

    .label {
      position: absolute;
      border-radius: 10px;
      padding: 8px 10px 4px 10px;
      min-width: 20px;
      /* margin-top: 10px; */
      /* min-height: 20px; */
      background-color: ${({ theme }) =>
        theme.items.configurador.colors.greyInfoBox};
      color: ${({ theme }) =>
        theme.items.configurador.colors.greyItemBottomRight};
      left: -100px;
      top: 20%;
      font-family: ${({ theme }) => theme.items.configurador.fonts.description};
      font-size: 0.8rem;
      /* margin-right: 10px; */
      line-height: 1rem;
      /* opacity: 0; */
      display: none;

      transition: all 0.3s ease;
    }

    &.second {
      .label {
        left: -110px;
      }
    }

    &.third {
      .label {
        left: -100px;
      }
    }

    &.fourth {
      .label {
        left: -90px;
      }
    }

    &.five {
      .label {
        left: -110px;
      }
    }
  }

  .show-hide-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s linear;
    /* border: 2px solid white; */
    min-width: 35px;
    max-width: 35px;
    min-height: 35px;
    max-height: 35px;
    text-align: center;
    position: relative;

    .tutorial-highlight{
      position: absolute;
      top: -25%;
      left: -25%;
      width: 150%;
      height: 150%;

      &.highlighted {
        @keyframes pulse {
          0% {
            box-shadow: 0 0 10px #ffb800;
          }
          50%{
            box-shadow: 0 0 20px #ffb800;
          }
          100% {
            box-shadow: 0 0 10px #ffb800;
          }
        }


        &:after {
          content: "";
          position: absolute;
          top: -20%;
          left: -10%;
          width: 120%;
          height: 140%;
          border: 1px solid #fff;
          border-color: #ffb800;
          box-shadow: 0 0 20px #ffb800;
          border-radius: 5px;
          animation pulse 2s infinite;
        }
      }


      &.highlighted-top {
        top: -220%;

        @keyframes pulse {
          0% {
            box-shadow: 0 0 10px #ffb800;
          }
          50%{
            box-shadow: 0 0 20px #ffb800;
          }
          100% {
            box-shadow: 0 0 10px #ffb800;
          }
        }


        &:after {
          content: "";
          position: absolute;
          top: -10%;
          left: -10%;
          width: 120%;
          height: 120%;
          border: 1px solid #fff;
          border-color: #ffb800;
          box-shadow: 0 0 20px #ffb800;
          border-radius: 5px;
          animation pulse 2s infinite;
        }
      }


    }

    .inner {
      font-size: 1.5rem;
      transition: all 0.2s linear;
    }

    &:after {
      content: "";
      position: absolute;
      background-color: white;
      height: 100%;
      width: 100%;
      -webkit-clip-path: polygon(
        93% 0,
        100% 0,
        100% 6%,
        6% 100%,
        0 100%,
        0 100%,
        0 93%
      );
      clip-path: polygon(
        93% 0,
        100% 0,
        100% 6%,
        6% 100%,
        0 100%,
        0 100%,
        0 93%
      );
      transition: all 0.4s linear;
    }

    &.on {
      border: none;
      transform: rotateZ(-45deg);
      /* offset-rotate: -45deg; */
      max-width: 45px !important;
      max-height: 45px;

      &:after {
        content: "";
        background-color: transparent;
        transition: all 0.1s linear;
      }

      .inner {
        transform: rotateZ(45deg);
      }

      .custom-border {
        .row {
          &:nth-child(1) {
            span {
              :nth-child(1) {
                /* margin-right: 2px; */
                position: relative;
                top: -3px;
                left: -3px;
                border-top: 4px solid
                  ${({ theme }) => theme.items.defaults.text.selected};
                border-left: 4px solid
                  ${({ theme }) => theme.items.defaults.text.selected};
              }

              :nth-child(2) {
                /* margin-right: 2px; */
                position: relative;
                top: -3px;
                right: -3px;
                border-top: 4px solid
                  ${({ theme }) => theme.items.defaults.text.selected};
                border-right: 4px solid
                  ${({ theme }) => theme.items.defaults.text.selected};
              }
            }
          }

          &:nth-child(2) {
            span {
              :nth-child(1) {
                /* margin-right: 2px; */
                position: relative;
                bottom: -3px;
                left: -3px;
                border-bottom: 4px solid
                  ${({ theme }) => theme.items.defaults.text.selected};
                border-left: 4px solid
                  ${({ theme }) => theme.items.defaults.text.selected};
              }

              :nth-child(2) {
                /* margin-right: 2px; */
                position: relative;
                bottom: -3px;
                right: -3px;
                border-bottom: 4px solid
                  ${({ theme }) => theme.items.defaults.text.selected};
                border-right: 4px solid
                  ${({ theme }) => theme.items.defaults.text.selected};
              }
            }
          }
        }
      }
    }
  }

  .show-hide-radius {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s linear;
    min-width: 35px;
    max-width: 35px;
    min-height: 35px;
    max-height: 35px;
    text-align: center;
    position: relative;
    margin: 10px 0px;

    img {
      position: absolute;
    }

    .ico {
      position: absolute;
      font-size: 1.7rem;
      transform: rotateX(70deg);
      color: white;
    }

    .tutorial-highlight{
      position: absolute;
      top: -5%;
      left: -25%;
      width: 150%;
      height: 110%;

      &.highlighted {
        @keyframes pulse {
          0% {
            box-shadow: 0 0 10px #ffb800;
          }
          50%{
            box-shadow: 0 0 20px #ffb800;
          }
          100% {
            box-shadow: 0 0 10px #ffb800;
          }
        }


        &:after {
          content: "";
          position: absolute;
          top: -20%;
          left: -10%;
          width: 120%;
          height: 140%;
          border: 1px solid #fff;
          border-color: #ffb800;
          box-shadow: 0 0 20px #ffb800;
          border-radius: 5px;
          animation pulse 2s infinite;
        }
      }

    }

    &.on {
      .ico {
        color: ${({ theme }) => theme.items.defaults.text.selected};

        &:nth-child(2) {
          font-size: 3rem;
        }
      }

      img {
        &:nth-child(2) {
          width: 45px;
        }
      }
    }
  }

  .show-hide-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s linear;
    min-width: 35px;
    max-width: 35px;
    min-height: 35px;
    max-height: 35px;
    text-align: center;
    position: relative;
    margin: 10px 0px;

    .tutorial-highlight{
      position: absolute;
      top: -25%;
      left: -25%;
      width: 150%;
      height: 150%;

      &.highlighted {
        @keyframes pulse {
          0% {
            box-shadow: 0 0 10px #ffb800;
          }
          50%{
            box-shadow: 0 0 20px #ffb800;
          }
          100% {
            box-shadow: 0 0 10px #ffb800;
          }
        }


        &:after {
          content: "";
          position: absolute;
          top: -10%;
          left: -10%;
          width: 120%;
          height: 120%;
          border: 1px solid #fff;
          border-color: #ffb800;
          box-shadow: 0 0 20px #ffb800;
          border-radius: 5px;
          animation pulse 2s infinite;
        }
      }
    }

    .inner {
      font-size: 2.5rem;
    }

    &.on {
      color: ${({ theme }) => theme.items.defaults.text.selected};
    }
  }

  .show-bulldozer-list {
    img {
      height: 55px;
    }
  }

  .bulldozer-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
    margin: 10px 0px;
    /* background-color: red; */

    .tutorial-highlight{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &.highlighted {
        @keyframes pulse {
          0% {
            box-shadow: 0 0 10px #ffb800;
          }
          50%{
            box-shadow: 0 0 20px #ffb800;
          }
          100% {
            box-shadow: 0 0 10px #ffb800;
          }
        }


        &:after {
          content: "";
          position: absolute;
          top: -10%;
          left: -10%;
          width: 120%;
          height: 120%;
          border: 1px solid #fff;
          border-color: #ffb800;
          box-shadow: 0 0 20px #ffb800;
          border-radius: 5px;
          animation pulse 2s infinite;
        }
      }
    }

    .bulldozer-list {
      display: none;
    }

    &.on {
      width: 500px;
      left: calc(-290px + 65px);

      .right-menu-wrapper {
        .label {
          display: none;
        }
      }

      .bulldozer-list {
        display: flex;
        justify-content: flex-start;
        background-color: rgba(0, 0, 0, 0.6);
        align-items: center;
        flex-direction: row;
        border-radius: 10px;
        /* background-color: black; */
        position: relative;
        /* overflow-x: scroll; */
        width: auto;
        /* background-color: silver; */
        &::-webkit-scrollbar {
          /* height: 20px;
                    width: 100%; */
          /* background-color: red; */
        }

        .bulldozer-item-list {
          cursor: pointer;
          min-width: 60px;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 0.8rem;
          color: ${({ theme }) => theme.items.configurador.colors.text_grey};
          font-family: ${({ theme }) => theme.items.configurador.fonts.title};

          label {
            margin-top: 10px;
          }
        }
      }
    }
  }

  .packages-container{
    display: flex;
    flex-direction: row-reverse;
    /* background: red; */
    position: relative;

    &.on{
      width: 700px;
      right: calc(350px - 50px);
      min-height: 150px;
    }

    .show-hide-packages {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s linear;
      /* border: 2px solid white; */
      min-width: 35px;
      max-width: 35px;
      min-height: 35px;
      max-height: 35px;
      text-align: center;
      position: relative;

      .tutorial-highlight{
        position: absolute;
        top: -25%;
        left: -25%;
        width: 150%;
        height: 150%;

        &.highlighted {
          @keyframes pulse {
            0% {
              box-shadow: 0 0 10px #ffb800;
            }
            50%{
              box-shadow: 0 0 20px #ffb800;
            }
            100% {
              box-shadow: 0 0 10px #ffb800;
            }
          }


          &:after {
            content: "";
            position: absolute;
            top: -10%;
            left: -10%;
            width: 120%;
            height: 120%;
            border: 1px solid #fff;
            border-color: #ffb800;
            box-shadow: 0 0 20px #ffb800;
            border-radius: 5px;
            animation pulse 2s infinite;
          }
        }
      }

      &.on {
        min-width: 60px;
        max-width: 60px;
        min-height: 60px;
        max-height: 60px;
        left: -12px;

        .inner {
          font-size: 2rem;
        }

        .custom-border {
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          position: absolute;
          width: 100%;
          height: 100%;
          /* background-color: silver; */
          transition: all 0.2s linear;
          opacity: 1;

          .row {
            display: flex;
            flex: 1;
            width: 100%;

            span {
              display: flex;
              flex: 1;
              position: relative;
            }

            &:nth-child(1) {
              span {
                :nth-child(1) {
                  margin-right: 10px;
                  margin-bottom: 10px;
                  position: relative;
                  top: -3px;
                  left: -3px;
                  border-top: 4px solid
                    ${({ theme }) => theme.items.defaults.text.selected};
                  border-left: 4px solid
                    ${({ theme }) => theme.items.defaults.text.selected};
                }

                :nth-child(2) {
                  margin-left: 10px;
                  margin-bottom: 10px;
                  position: relative;
                  top: -3px;
                  right: -3px;
                  border-top: 4px solid
                    ${({ theme }) => theme.items.defaults.text.selected};
                  border-right: 4px solid
                    ${({ theme }) => theme.items.defaults.text.selected};
                }
              }
            }

            &:nth-child(2) {
              span {
                :nth-child(1) {
                  margin-right: 10px;
                  margin-top: 10px;
                  position: relative;
                  bottom: -3px;
                  left: -3px;
                  border-bottom: 4px solid
                    ${({ theme }) => theme.items.defaults.text.selected};
                  border-left: 4px solid
                    ${({ theme }) => theme.items.defaults.text.selected};
                }

                :nth-child(2) {
                  margin-left: 10px;
                  margin-top: 10px;
                  position: relative;
                  bottom: -3px;
                  right: -3px;
                  border-bottom: 4px solid
                    ${({ theme }) => theme.items.defaults.text.selected};
                  border-right: 4px solid
                    ${({ theme }) => theme.items.defaults.text.selected};
                }
              }
            }
          }
        }
      }
    }
  }

  .packages-list {
    display: none;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    /* background-color: black; */
    position: absolute;
    left: -300px;
    top: 0px;
    /* right: -600px; */
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    &.on {
      display: flex;
      min-width: 700px;
      max-width: 700px;
      min-height: 130px;
      /* min-height: ; */
      left: -130px;

      &:before{
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.9);
        z-index: -1;
        border-radius: 5px;
      }
    }

    .package-item {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      /* background-color: silver; */
      width: 100%;
      min-height: 100px;
      align-items: center;
      margin: 10px 0px;

      label {
        color: ${({ theme }) => theme.items.configurador.colors.text_grey};
        font-family: ${({ theme }) => theme.items.configurador.fonts.title};
        font-size: 1rem;
        margin-top: 10px;
        line-height: 1rem;

        &.thin{
          font-size: 0.8rem;
          margin-top: 2px;
        }
      }

      img {
        width: 60px;
        height: 60px;
        border-radius: 8px;
      }

      &.selected {
        label {
          color: ${({ theme }) => theme.items.configurador.colors.primary};
        }

        img {
          border: 2px solid
            ${({ theme }) => theme.items.configurador.colors.primary};
        }
      }
    }
  }

`;

export const BlockActions = styled.div`
  position: absolute;
  right: 10px;
  top: 0px;
  width: 80px;
  height: 240px;
  background: rgba(0, 0, 0, 1);
  border: 2px dashed white;
  opacity: 0.5;
  border-radius: 8px;
`;
