import React from "react";

import {
  Container,
  DataHeader,
  BulldozerDataContainer,
  Description,
  ConfigurationLabel,
} from "./styles";

import leftViewImage from "../../../../../shared/assets/images/leftView.png";
import topViewImage from "../../../../../shared/assets/images/topView.png";

import { useConfigurator } from "../../../../../contexts/configurator";
export default function ConfigurationItem({ config, index }) {
  const { currentConfig } = useConfigurator();
  return (
    <Container>
      <ConfigurationLabel>
        <label className="title">{`Configuração ${index + 1}`}</label>
      </ConfigurationLabel>
      <DataHeader>
        <label className="title">
          Nome: <label className="title normal">{config.model}</label>
        </label>
        <label className="title">
          Ton:{" "}
          <label className="title normal">
            {config.model === "EC140DL" ? "14 Ton" : "15 Ton"}
          </label>
        </label>
      </DataHeader>
      <BulldozerDataContainer>
        <div className="column">
          <div className="diameters">
            <img src={leftViewImage} />
            <span className="widthValue">
              <label>{config?.config.filter(i => i.name.toLowerCase() === "braço")[0].selected[0].name === "2,5m" ? "7,720m" : (config.model === "EC140DL" ?'7,650m':'7,690m')}</label>
            </span>
            <span className="heightValue">
              <label>{config?.config.filter(i => i.name.toLowerCase() === "braço")[0].selected[0].name === "2,5m" ? (config.model === "EC140DL" ?'2,8m':'2,850m') : (config.model === "EC140DL" ?'3,180m':'3,160m')}</label>
            </span>
          </div>
        </div>
        <div className="column">
          <div className="circumference">
            <img src={topViewImage} />
            <label className="valueRange">{config?.config.filter(i => i.name.toLowerCase() === "braço")[0].selected[0].name === "2,5m" ? "8,3m" : "8,8m"}</label>
          </div>
        </div>
      </BulldozerDataContainer>
      <Description>
        {config?.config?.map((i, index) => (
          <span key={index}>
            {i.name}:
            {i.selected.map((opt, index2) => (
              <label key={index2}>
                {opt.name}
                <br />
              </label>
            ))}
          </span>
        ))}
      </Description>
    </Container>
  );
}
