import React from "react";
import { KeyboardContainer } from "./style.js";

const Keyboard = ({
  isOnKeyboard,
  setIsOnKeyboard,
  pressedKey,
  setPressedKey,
  setEscavadeiraRotate,
  setTimeScale,
}) => {
  const onKeyDown = (key) => {
    switch (key) {
      case "a":
        setPressedKey("a");
        isOnKeyboard && setEscavadeiraRotate("left");
        break;
      case "w":
        setPressedKey("w");
        isOnKeyboard && setTimeScale(1);
        break;
      case "d":
        setPressedKey("d");
        isOnKeyboard && setEscavadeiraRotate("right");
        break;
      case "s":
        setPressedKey("s");
        isOnKeyboard && setTimeScale(-1);
        break;
    }
  };
  const onKeyUp = (key) => {
    switch (key) {
      case "a":
        setPressedKey("");
        isOnKeyboard && setEscavadeiraRotate(null);
        break;
      case "w":
        setPressedKey("");
        isOnKeyboard && setTimeScale(0);
        break;
      case "d":
        setPressedKey("");
        isOnKeyboard && setEscavadeiraRotate(null);
        break;
      case "s":
        setPressedKey("");
        isOnKeyboard && setTimeScale(0);
        break;
    }
  };
  return (
    <KeyboardContainer className={isOnKeyboard ? "on" : ""}>
      <div className="botoes">
        <div
          onMouseDown={() => onKeyDown("a")}
          onTouchStart={(e) => {
            e.preventDefault();
            onKeyDown("a");
          }}
          onMouseUp={() => onKeyUp("a")}
          onTouchEnd={(e) => {
            e.preventDefault();
            onKeyUp("a");
          }}
          onMouseLeave={() => onKeyUp("a")}
          className={`a ${pressedKey === "a" ? "pressed" : ""}`}
        ></div>
        <div
          onMouseDown={() => onKeyDown("w")}
          onTouchStart={(e) => {
            e.preventDefault();
            onKeyDown("w");
          }}
          onMouseUp={() => onKeyUp("w")}
          onTouchEnd={(e) => {
            e.preventDefault();
            onKeyUp("w");
          }}
          onMouseLeave={() => onKeyUp("w")}
          className={`w ${pressedKey === "w" ? "pressed" : ""}`}
        ></div>
        <div
          onMouseDown={() => onKeyDown("s")}
          onTouchStart={(e) => {
            e.preventDefault();
            onKeyDown("s");
          }}
          onMouseUp={() => onKeyUp("s")}
          onTouchEnd={(e) => {
            e.preventDefault();
            onKeyUp("s");
          }}
          onMouseLeave={() => onKeyUp("s")}
          className={`s ${pressedKey === "s" ? "pressed" : ""}`}
        ></div>
        <div
          onMouseDown={() => onKeyDown("d")}
          onTouchStart={(e) => {
            e.preventDefault();
            onKeyDown("d");
          }}
          onMouseUp={() => onKeyUp("d")}
          onTouchEnd={(e) => {
            e.preventDefault();
            onKeyUp("d");
          }}
          onMouseLeave={() => onKeyUp("d")}
          className={`d ${pressedKey === "d" ? "pressed" : ""}`}
        ></div>
      </div>
    </KeyboardContainer>
  );
};

export default React.memo(Keyboard);
