import styled from "styled-components";

export const MenuHolder = styled.div`
  pointer-events: all;
  position: absolute;
  width: 70px;
  height: 70px;
  right: 0px;
`;

export const Menu = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  width: 30px;
  height: 20px;
  top: calc((70px - 20px) / 2);
  z-index: 100;
  opacity: 0;
  z-index: 10;

  &:checked {
    background-color: #4caf50;
  }

  &:checked + label:before {
    background-color: #4caf50;
  }

  &:checked + label div:first-child {
    top: -4px;
    right: -4px;
    transform: rotateZ(45deg);
  }

  &:checked + label div:last-child {
    top: 5px;
    right: -1px;
    transform: rotateZ(45deg);
  }

  &:checked + label div:nth-child(2) {
    width: 37px;
    top: 0px;
    right: 1px;
    transform: rotateZ(-45deg);
  }
`;

export const MenuLines = styled.label`
  position: absolute;
  display: block;
  width: 30px;
  height: 20px;
  z-index: 1;
  top: calc((70px - 20px) / 2);
  cursor: pointer;

  &:before {
    content: "";
    transition: 1.2s cubic-bezier(0, 0.96, 1, 0.02) background-color;
  }
`;

export const MenuLine = styled.div`
  position: relative;
  top: 0;
  height: 2px;
  background-color: ${({ theme }) => theme.items.header.ui.color};
  margin-bottom: 7px;
  transition: 0.3s ease transform, 0.3s ease top, 0.3s ease width,
    0.3s ease right;
  border-radius: 2px;

  &:first-child {
    transform-origin: 0px;
  }

  &:last-child {
    margin-bottom: 0;
    transform-origin: 30px;
  }

  &:nth-child(2) {
    right: 7px;
    width: 30px;
  }
`;

export const MenuItems = styled.ul`
  display: flex;
  flex-flow: row wrap;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  width: 100vw;
  margin: 0;
  padding: 90px 0px;
  position: fixed;
  height: 100vh;
  z-index: 0;
  top: -100%;
  left: 0%;
  opacity: 0;
  backdrop-filter: ${({ theme }) => theme.items.menu.background.blur};
  transition: top 0s linear 0.5s, opacity 0.6s ease-in-out 0s;

  @media (max-width: 640px) {
    height: 100vh;
  }

  &.active {
    top: 0%;
    opacity: 1;
    transition: top 0s linear 0s, opacity 0.6s ease-in-out 0.2s;
  }
`;

export const MenuItem = styled.li`
  width: 80%;
  font-family: ${({ theme }) => theme.items.menu.text.font};
  font-size: ${({ theme }) => theme.items.menu.text.size};
  user-select: none;
  cursor: pointer;
  -webkit-text-stroke: 0.08vw ${({ theme }) => theme.items.menu.text.color};
  -webkit-text-fill-color: transparent;
  margin-left: -20%;
  transition: margin-left 0.6s ease-in-out 0s, opacity 0.6s ease-in-out 0s;

  &.activeItem {
    &:nth-child(1) {
      margin-left: 0%;
      transition-delay: 0s;
    }
    &:nth-child(2) {
      margin-left: 0%;
      transition-delay: 0.1s;
    }
    &:nth-child(3) {
      margin-left: 0%;
      transition-delay: 0.2s;
    }
    &:nth-child(4) {
      margin-left: 0%;
      transition-delay: 0.3s;
    }
    &:nth-child(5) {
      margin-left: 0%;
      transition-delay: 0.4s;
    }
  }

  &:hover {
    -webkit-text-stroke: 0vw;
    -webkit-text-fill-color: ${({ theme }) => theme.items.menu.text.color};
  }

  @media (max-width: 640px) {
    margin-bottom: 0px;
    font-size: 6vw;
    -webkit-text-stroke: none;
    -webkit-text-fill-color: ${({ theme }) => theme.items.menu.text.color};
  }
`;
