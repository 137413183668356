import React from "react";
import * as ReactDOMClient from "react-dom/client";
import App from "./App";
import "./index.scss";
import "normalize.css";

import * as themes from "./theme/schema.json";
import { setToLS } from "./shared/utils/storage";

const Index = () => {
  setToLS("all-themes", themes.default);
  return <App />;
};

const root = ReactDOMClient.createRoot(document.getElementById("root"));

root.render(<Index />);
