import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background: black;
  position: absolute;
  top: 0;
  z-index: 100000000;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 70px;

  label {
    color: white;
    font-size: 2rem;
    font-family: ${({ theme }) => theme.items.configurador.fonts.title};
    letter-spacing: 0.02px;
    position: relative;

    &:after {
      content: "";
      display: block;
      min-width: 12px;
      min-height: 12px;
      border-radius: 4px;
      background-color: white;
      position: absolute;
      right: -20px;
      top: 8px;
    }

    &:before {
      content: "";
      display: block;
      min-width: 12px;
      min-height: 12px;
      border-radius: 4px;
      background-color: white;
      position: absolute;
      left: -20px;
      top: 8px;
    }
  }
`;

export const ContentButtons = styled.div`
  display: flex;
  flex-flow: row nowrap;
  max-width: 1200px;
  align-items: center;
`;
export const ButtonPurpose = styled.a`
  display: flex;
  padding: 10px 25px;
  color: black;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.items.configurador.colors.primary};
  transition: all 0.5s ease;
  margin: 0px 10px;

  &.orcamento {
    padding: 20px 25px;
    font-weight: bold;
    border-radius: 30px;
  }

  &:hover {
    /* transform: scale(1.05); */
    cursor: pointer;
    filter: ${({ theme }) =>
      `drop-shadow(0 0 0.75rem ${theme.items.configurador.colors.primary})`};
  }

  &.voltar {
    background-color: ${({ theme }) =>
      theme.items.configurador.colors.greySteps};
    &:hover {
      /* transform: scale(1.05); */
      cursor: pointer;
      filter: ${({ theme }) =>
        `drop-shadow(0 0 0.75rem ${theme.items.configurador.colors.greySteps})`};
    }
  }
`;

export const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  height: 100%;
  width: 100vw;
  /* background-color: red; */
`;

export const ConfigurationsArea = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  max-height: 80vh;
  max-width: 1200px;
  overflow: auto;

  ::-webkit-scrollbar {
    display: block;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${({ theme }) =>
      theme.items.configurador.colors.backgroundResumeDark};
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) =>
      theme.items.configurador.colors.backgroundResumeLight};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.items.configurador.colors.primary};
  }

  @media only screen and (max-width: 1400px) {
    max-height: 75vh;
  }
`;

export const Steps = styled.div`
  display: flex;
  justify-content: flex-start;
  /* background-color: black; */
  min-width: 100px;
  position: relative;
  margin: 10px 0px;

  &:before {
    content: "";
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) =>
      theme.items.configurador.colors.greySteps};
    position: absolute;
    top: 50%;
    z-index: 0;
  }

  .step-item {
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    padding: 10px 20px;
    background-color: ${({ theme }) =>
      theme.items.configurador.colors.greySteps};
    border-radius: 50px;
    /* min-width: calc(28px - 20px); */
    width: auto;
    min-height: calc(28px - 20px);
    /* max-width: calc(28px - 20px); */
    max-height: calc(28px - 20px);
    font-family: ${({ theme }) => theme.items.configurador.fonts.descriptions};
    color: ${({ theme }) => theme.items.configurador.colors.greyTextSteps};
    font-size: 1rem;
    z-index: 9;
    transition: all 0.4s ease;
    cursor: pointer;

    &.on {
      background-color: ${({ theme }) =>
        theme.items.configurador.colors.primary};
      color: ${({ theme }) =>
        theme.items.configurador.colors.greySelectedTextSteps};
    }

    &:nth-child(4) {
      margin-right: 0;
    }
  }
`;

export const ModalPurpose = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;

  .backdrop {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
  }

  .purpose-area {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: black; */

    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 50%;
      height: 80%;
      background: ${({ theme }) =>
        theme.items.configurador.colors.backgroundResumeDark};
      border-radius: 20px;
      border: 2px solid white;
      padding: 10px 20px;
      z-index: 9;

      .row {
        display: flex;
      }

      .rest {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex: 1;
        /* background: red; */
      }
    }
  }
`;
