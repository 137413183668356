import React, { forwardRef, useEffect, useRef, useState } from "react";
import useDynamicRefs from "use-dynamic-refs";
import * as THREE from "three";
import { useFrame, useThree } from "@react-three/fiber";
import { Html } from "@react-three/drei";
import { Group } from "three";

const MyPointCanvas = forwardRef((props, ref) => {
  useEffect(() => {
    // Add mesh to camera
    const meshRef2 = ref.current;
  }, [ref.current]);

  return (
    <mesh
      ref={ref}
      position={[props.posX, props.posY, props.posZ]}
      rotation={[0, 0, 0]}
      scale={[1, 1, 1]}
      visible={
        props.hoverMenu
          ? props.value.opacity === 0
            ? false
            : true
          : props.opacity > 0
          ? true
          : false
      }
    >
      <boxBufferGeometry
        attach="geometry"
        args={[props.size, props.size, props.size]}
      />
      <meshBasicMaterial
        color="#FDC92D"
        side={THREE.DoubleSide}
        transparent={props.opacity < 1 ? true : false}
        opacity={props.opacity}
      />
    </mesh>
  );
});

const MyPointCamera = forwardRef((props, ref) => {
  const { camera } = useThree();
  const refBoard = useRef(null);
  useEffect(() => {
    // Add mesh to camera
    const meshRefBoard = refBoard.current;
    const meshRef = ref.current;
    camera.add(meshRefBoard);
    camera.add(meshRef);
    // props.virtualCamera.current.add(meshRefBoard);
    // props.virtualCamera.current.add(meshRef);

    // // virtualCamera cleanup on unmount
    // return () => {
    //   props.virtualCamera.current.remove(meshRefBoard);
    //   props.virtualCamera.current.remove(meshRef);
    // };
  }, []);

  const selectRadio = (e, item, parent) => {
    e.preventDefault();
    e.stopPropagation();
    props.setAlert({
      show: true,
      time: 2000,
      message: "Opcional configurado",
    });
    return props.selectOption(parent, "radio", item);
  };

  const selectToogle = (e, item, parent) => {
    e.preventDefault();
    e.stopPropagation();
    props.setAlert({
      show: true,
      time: 2000,
      message: "Opcional configurado",
    });
    return props.selectOption(parent, "checkbox", item);
  };

  const showMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.setHoverMenu(true);
    if (e.target.parentElement.classList.contains("bg-hover-d")) {
      e.target.parentElement.style.pointerEvents = "none";
      e.target.parentElement.parentElement.parentElement.parentElement.style.zIndex = 1000000000;
      let menuContainer = document.getElementsByClassName(
        "menu-container-d-opcionais"
      );
      for (let i = 0; i < menuContainer.length; i++) {
        if (!menuContainer[i].classList.contains(`index${props.index}`)) {
          menuContainer[i].parentElement.parentElement.style.zIndex = 10000;
          menuContainer[i].classList.add("off");
          let itemTemp = menuContainer[i].querySelector(".menu-title");
          itemTemp.style.pointerEvents = "none";
          // console.log(itemTemp);
        }
      }
      const clone = require("rfdc")();
      const menuItemsTemp = clone(props.menuItems);
      for (let o = 0; o < menuItemsTemp.length; o++) {
        menuItemsTemp[o].opacity = 0;
      }
      menuItemsTemp[props.index].opacity = 1;
      props.setMenuItems(menuItemsTemp);
      e.target.parentElement.querySelectorAll(":scope > div").forEach((e) => {
        e.classList.add("opened");
      });
    }
  };

  const hideMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.setHoverMenu(false);
    let menuContainer = document.getElementsByClassName(
      "menu-container-d-opcionais"
    );
    for (let i = 0; i < menuContainer.length; i++) {
      menuContainer[i].parentElement.parentElement.style.zIndex = 10000;
      if (!menuContainer[i].classList.contains(`index${props.index}`)) {
        menuContainer[i].classList.remove("off");
        let itemTemp = menuContainer[i].querySelector(".menu-title");
        itemTemp.style.pointerEvents = "all";
      } else {
      }
    }
    const clone = require("rfdc")();
    // const menuItemsTemp = clone(props.menuItems);
    // for (let o = 0; o < menuItemsTemp.length; o++) {
    //   menuItemsTemp[o].opacity = 1;
    // }
    // props.setMenuItems(menuItemsTemp);
    let menus = document.getElementsByClassName("bg-hover-d");

    for (let i = 0; i < menus.length; i++) {
      menus[i].querySelectorAll(":scope > div").forEach((e) => {
        e.classList.remove("opened");
      });
    }
  };

  useEffect(() => {
    document.addEventListener("click", hideMenu);
  }, []);

  return (
    <>
      <mesh
        ref={refBoard}
        position={[props.posX, props.posY, props.posZ]}
        rotation={[0, 0, 0]}
        scale={[0.001, 0.001, 0.001]}
        visible={
          props.hoverMenu
            ? props.value.opacity === 0
              ? false
              : true
            : props.opacity > 0
            ? true
            : false
        }
      >
        <planeBufferGeometry
          attach="geometry"
          args={[props.width, props.height]}
        />
        <Html>
          <div
            className={`menu-container-d-opcionais ${props.align} index${props.index}`}
            style={{
              visibility: props.visible ? "visible" : "hidden",
              opacity: props.opacity,
            }}
          >
            {/* <div className="bg-hover-d" onPointerLeave={(e) => hideMenu(e)}> */}
            <div className="bg-hover-d">
              <h1
                className={`menu-title opcionais ${props.align}`}
                onClick={(e) => showMenu(e)}
              >
                {props.title}
              </h1>
              {props.items.map((value, index) => {
                if (value.type === "radio") {
                  return (
                    <div
                      key={index}
                      className={`item-container ${props.align} ${
                        props.open ? "opened" : ""
                      }`}
                      style={{
                        display: props.visible ? "flex" : "none",
                      }}
                    >
                      {value.options.map((value2, index2) => {
                        return (
                          <div
                            className={`item ${props.align} ${
                              value2.selected ? "selected" : ""
                            }`}
                            key={index2}
                            onClick={(e) =>
                              selectRadio(e, value2.item, value2.parent)
                            }
                          >
                            {value2.title}
                            <div className="info"></div>
                          </div>
                        );
                      })}
                    </div>
                  );
                }
                if (value.type === "radio-big") {
                  return (
                    <div
                      key={index}
                      className={`item-container  ${props.align} ${
                        props.open ? "opened" : ""
                      }`}
                    >
                      <div className="item-container-title">{value.title}</div>
                      {value.options.map((value2, index2) => {
                        return (
                          <div
                            className={`item-radio-big ${props.align} ${
                              value2.selected ? "selected" : ""
                            }`}
                            key={value2.value + index2}
                            onClick={(e) =>
                              selectRadio(e, value2.item, value2.parent)
                            }
                          >
                            <div className="circle">
                              {value2.selected && (
                                <div className={`selected`}></div>
                              )}
                            </div>
                            {value2.title}
                            <div className="info"></div>
                          </div>
                        );
                      })}
                    </div>
                  );
                }
                if (value.type === "radio-big-2") {
                  return (
                    <div
                      key={index}
                      className={`item-container  ${props.align} ${
                        props.open ? "opened" : ""
                      }`}
                    >
                      <div className="item-container-title">{value.title}</div>
                      {value.options.map((value2, index2) => {
                        return (
                          <div
                            className={`item-radio-big ${props.align} ${
                              value2.selected ? "selected" : ""
                            }`}
                            key={value2.value + index2}
                            onClick={(e) =>
                              selectToogle(e, value2.item, value2.parent)
                            }
                          >
                            <div className="circle">
                              {value2.selected && (
                                <div className={`selected`}></div>
                              )}
                            </div>
                            {value2.title}
                            <div className="info"></div>
                          </div>
                        );
                      })}
                    </div>
                  );
                }
                if (value.type === "toogle") {
                  return (
                    <div
                      key={index}
                      className={`item-container  ${props.align} ${
                        props.open ? "opened" : ""
                      }`}
                    >
                      {value.options.map((value2, index2) => {
                        return (
                          <div
                            className={`item-toogle ${props.align} ${
                              value2.selected ? "selected" : ""
                            }`}
                            key={index2}
                            onClick={(e) =>
                              selectToogle(e, value2.item, value2.parent)
                            }
                          >
                            {!value2.item.lock && (
                              <div className="circle">
                                <div
                                  className={`selected ${value2.selected &&
                                    "on"}`}
                                >
                                  +
                                </div>
                              </div>
                            )}
                            {value2.title}
                            <div className="info"></div>
                          </div>
                        );
                      })}
                    </div>
                  );
                }
                if (value.type === "fixed") {
                  return (
                    <div
                      key={index}
                      className={`item-container  ${props.align} ${
                        props.open ? "opened" : ""
                      }`}
                    >
                      {value.options.map((value2, index2) => {
                        return (
                          <div
                            className={`item-fixed`}
                            key={index2}
                            onClick={(e) =>
                              selectToogle(e, value2.item, value2.parent)
                            }
                          >
                            {value2.title}
                            <div className="info"></div>
                          </div>
                        );
                      })}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </Html>
        <meshBasicMaterial
          color="green"
          side={THREE.DoubleSide}
          depthWrite={false}
          depthTest={false}
          transparent={true}
          opacity={0}
        />
      </mesh>
      <mesh
        ref={ref}
        position={[
          props.posX - (props.width * 0.001) / 2 - 0.0005,
          props.posY,
          props.posZ,
        ]}
        rotation={[0, 0, 0]}
        scale={[0.001, 0.001, 0.001]}
        visible={
          props.hoverMenu
            ? props.value.opacity === 0
              ? false
              : true
            : props.visible
        }
      >
        <planeBufferGeometry attach="geometry" args={[0.5, 0.5]} />
        <meshBasicMaterial
          color="#FDC92D"
          side={THREE.DoubleSide}
          depthWrite={false}
          depthTest={false}
          transparent={props.opacity < 1 ? true : false}
          opacity={props.opacity}
        />
      </mesh>
    </>
  );
});

const CustomLine = forwardRef((props, ref) => {
  var origin = new THREE.Vector3();
  var target = new THREE.Vector3();
  let lineGeometry;
  useFrame(() => {
    if (props.originRef.current !== null && props.targetRef.current !== null) {
      props.originRef.current.getWorldPosition(origin);
      props.targetRef.current.getWorldPosition(target);
      lineGeometry = new THREE.BufferGeometry().setFromPoints([origin, target]);
      ref.current.geometry = lineGeometry;
    }
  }, []);

  return (
    <group
      position={[0, 0, 0]}
      visible={
        props.hoverMenu
          ? props.value.opacity === 0
            ? false
            : true
          : props.opacity > 0
          ? true
          : false
      }
    >
      <line ref={ref}>
        <lineBasicMaterial
          attach="material"
          color={"#FDC92D"}
          linewidth={10}
          linecap={"round"}
          linejoin={"round"}
          depthWrite={false}
          transparent={props.opacity < 1 ? true : false}
          opacity={props.opacity}
        />
      </line>
    </group>
  );
});

const Menu3DOpcionais = (props) => {
  const [getRef, setRef] = useDynamicRefs();
  const meshRef = useRef(null);
  const meshCanvasRef = useRef(null);
  const lineRef = useRef();
  const [hoverMenu, setHoverMenu] = useState(false);

  useEffect(() => {
    if (props.visible) {
      const clone = require("rfdc")();
      const menuItemsTemp = clone(props.menuItems);

      for (let i = 0; i < menuItemsTemp.length; i++) {
        menuItemsTemp[i].opacity = 1;
      }

      props.setMenuItems(menuItemsTemp);
    } else {
      const clone = require("rfdc")();
      const menuItemsTemp = clone(props.menuItems);

      for (let i = 0; i < menuItemsTemp.length; i++) {
        menuItemsTemp[i].opacity = 0;
      }

      props.setMenuItems(menuItemsTemp);
    }
  }, [props.visible]);
  return props.menuItems.map((value, index) => (
    <group key={index} visible={props.visible}>
      <MyPointCanvas
        size={2}
        posX={value.position.start.x}
        posY={value.position.start.y}
        posZ={value.position.start.z}
        ref={setRef(value.title + "canvas")}
        opacity={props.opacity}
        visible={props.visible}
        index={index}
        hoverMenu={hoverMenu}
        value={value}
      />
      <MyPointCamera
        alert={props.alert}
        setAlert={props.setAlert}
        width={20}
        height={3}
        title={value.title}
        items={value.items}
        posX={value.position.end.x}
        posY={value.position.end.y}
        posZ={-0.1}
        ref={setRef(value.title + "camera")}
        // virtualCamera={props.virtualCamera}
        opacity={props.opacity}
        align={value.align}
        menuItems={props.menuItems}
        setMenuItems={props.setMenuItems}
        selectOption={props.selectOption}
        open={value.open}
        visible={props.visible}
        index={index}
        hoverMenu={hoverMenu}
        setHoverMenu={setHoverMenu}
        value={value}
      />
      <CustomLine
        ref={setRef(value.title + "line")}
        originRef={getRef(value.title + "canvas")}
        targetRef={getRef(value.title + "camera")}
        opacity={props.opacity}
        visible={props.visible}
        index={index}
        hoverMenu={hoverMenu}
        value={value}
      />
    </group>
  ));
};

export default React.memo(Menu3DOpcionais);
