import React, { useState } from "react";

import { Container, Text, ButtonsArea, Button, StepsContainer } from "./styles";

export const PopUp = ({ skipTutorial, setTutorialStep, tutorialStep }) => {
  const [hide, setHide] = useState(false);
  // const [ step, setStep ] = useState(1)

  return (
    <Container
      className={`${hide ? "hide" : ""} step-tutorial-${tutorialStep}`}
    >
      {tutorialStep === 1 && (
        <>
          <Text>Versões configuradas</Text>
          <Text className="thin">
            Aqui ficará o histórico das versões configuradas. Para criar uma
            outra versão é necessário escolher o modelo da escavadeira.
          </Text>
          <ButtonsArea>
            <Button onClick={() => setTutorialStep(tutorialStep + 1)}>
              Próximo
            </Button>
            <Button onClick={skipTutorial}>Pular</Button>
          </ButtonsArea>
        </>
      )}
      {tutorialStep === 2 && (
        <>
          <Text>Utilize a barra inferior para navegar entre as opções</Text>
          <Text className="thin">
            Navegue entre Configurações, Opcionais e Resumo da escavadeira
            configurada ao final
          </Text>
          <ButtonsArea>
            <Button onClick={() => setTutorialStep(tutorialStep + 1)}>
              Próximo
            </Button>
            <Button onClick={skipTutorial}>Pular</Button>
          </ButtonsArea>
        </>
      )}
      {tutorialStep === 3 && (
        <>
          <Text>Descrição do Produto</Text>
          <Text className="thin">
            Aqui você encontrará as informações de cada item que estará
            configurando, juntamente com suas aplicações e funcionalidades.
          </Text>
          <ButtonsArea>
            <Button onClick={() => setTutorialStep(tutorialStep + 1)}>
              Próximo
            </Button>
            <Button onClick={skipTutorial}>Pular</Button>
          </ButtonsArea>
        </>
      )}
      {tutorialStep === 4 && (
        <>
          <Text>Menu</Text>
          <Text className="thin">
            Navegue entre as sessões! Aqui você pode acessar outras áreas do
            configurador, iniciar uma conversa com nosso Assistente Virtual,
            abrir o Mapa de Concessionárias, além de poder Compartilhar o site
            com outras pessoas!
          </Text>
          <ButtonsArea>
            <Button onClick={() => setTutorialStep(tutorialStep + 1)}>
              Próximo
            </Button>
            <Button onClick={skipTutorial}>Pular</Button>
          </ButtonsArea>
        </>
      )}
      {tutorialStep === 5 && (
        <>
          <Text>Visualização</Text>
          <Text className="thin">
            Ative ou desative os itens configuráveis da escavadeira!
          </Text>
          <ButtonsArea>
            <Button onClick={() => setTutorialStep(tutorialStep + 1)}>
              Próximo
            </Button>
            <Button onClick={skipTutorial}>Pular</Button>
          </ButtonsArea>
        </>
      )}
      {tutorialStep === 6 && (
        <>
          <Text>Raio</Text>
          <Text className="thin">
            Clique para exibir o raio de giro do contrapeso da escavadeira!
          </Text>
          <ButtonsArea>
            <Button onClick={() => setTutorialStep(tutorialStep + 1)}>
              Próximo
            </Button>
            <Button onClick={skipTutorial}>Pular</Button>
          </ButtonsArea>
        </>
      )}
      {tutorialStep === 7 && (
        <>
          <Text>Controles</Text>
          <Text className="thin">
            Ative os controles de movimentação das articulações e de rotação da
            cabine. (É possível controlar pelas teclas do teclado ou clicando
            sobre os botões)
          </Text>
          <ButtonsArea>
            <Button onClick={() => setTutorialStep(tutorialStep + 1)}>
              Próximo
            </Button>
            <Button onClick={skipTutorial}>Pular</Button>
          </ButtonsArea>
        </>
      )}
      {tutorialStep === 8 && (
        <>
          <Text>Modelo</Text>
          <Text className="thin">
            Clique para configurar um novo modelo de escavadeira! O novo modelo
            será exibido no canto esquerdo, na sessão "versões configuradas"
            onde você poderá alternar entre as outras configurações.
          </Text>
          <ButtonsArea>
            <Button onClick={() => setTutorialStep(tutorialStep + 1)}>
              Próximo
            </Button>
            <Button onClick={skipTutorial}>Pular</Button>
          </ButtonsArea>
        </>
      )}
      {tutorialStep === 9 && (
        <>
          <Text>Aplicação</Text>
          <Text className="thin">
            Selecione a aplicação que melhor representa o seu tipo de atividade.
            Caso não haja, selecione a versão Padrão (Standard).
          </Text>
        </>
      )}
      {/* <StepsContainer step={tutorialStep}>
        <span
          className={`stepItem ${tutorialStep >= 1 ? "active" : ""}`}
        ></span>
        <span
          className={`stepItem ${tutorialStep >= 2 ? "active" : ""}`}
        ></span>
        <span
          className={`stepItem ${tutorialStep >= 3 ? "active" : ""}`}
        ></span>
        <span
          className={`stepItem ${tutorialStep >= 4 ? "active" : ""}`}
        ></span>
        <span
          className={`stepItem ${tutorialStep >= 5 ? "active" : ""}`}
        ></span>
        <span
          className={`stepItem ${tutorialStep >= 6 ? "active" : ""}`}
        ></span>
        <span
          className={`stepItem ${tutorialStep >= 7 ? "active" : ""}`}
        ></span>
        <span
          className={`stepItem ${tutorialStep >= 8 ? "active" : ""}`}
        ></span>
        <span
          className={`stepItem ${tutorialStep >= 9 ? "active" : ""}`}
        ></span>
      </StepsContainer> */}
    </Container>
  );
};
