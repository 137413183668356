import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  MenuHolder,
  Menu,
  MenuLines,
  MenuLine,
  MenuItems,
  MenuItem,
} from "./style";

const BurgerMenu = () => {
  const menuRef = useRef(null);
  let navigate = useNavigate();
  const [menuActive, setMenuActive] = useState(false);

  const checkMenu = (e) => {
    setMenuActive(e.target.checked);
  };

  const goTo = (section) => {
    navigate(section);
    menuRef.current.checked = false;
    setMenuActive(false);
  };

  const sharePage = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Configurador Tracbel",
          text: "Configure sua Escavadeira.",
          url: process.env.PUBLIC_URL,
        })
        .then(() => {
          // console.log("Abrir compartilhamento ok!");
        })
        .catch(console.error);
    } else {
      // shareDialog.classList.add('is-open');
      // console.log("Problema com o navigator.share");
    }
  };

  return (
    <MenuHolder>
      <Menu onChange={checkMenu} ref={menuRef} />
      <MenuLines>
        <MenuLine />
        <MenuLine />
        <MenuLine />
      </MenuLines>
      <MenuItems className={menuActive && "active"}>
        <MenuItem
          className={menuActive && "activeItem"}
          onClick={() => goTo("/seletordemodelos")}
        >
          Seletor de Modelos
        </MenuItem>
        <MenuItem
          className={menuActive && "activeItem"}
          onClick={() => goTo("/assistentevirtual")}
        >
          Assistente Virtual
        </MenuItem>
        <MenuItem
          className={menuActive && "activeItem"}
          onClick={() => goTo("/mapaconcessionarias")}
        >
          Mapa Concessionárias
        </MenuItem>
        <MenuItem
          className={menuActive && "activeItem"}
          onClick={() => sharePage()}
        >
          Compartilhar
        </MenuItem>
        <MenuItem
          className={menuActive && "activeItem"}
          onClick={() => goTo("/configurador")}
        >
          Retomar Configuração
        </MenuItem>
      </MenuItems>
    </MenuHolder>
  );
};

export default BurgerMenu;
