import React, { useEffect, useContext, createContext } from "react";
import { authSL, sendLead } from "../../services/salesforce-auth";

const AuthContext = createContext();

export default function AuthProvider({ children }) {
  async function getToken() {
    var responseToken = await authSL();
    return responseToken;
  }

  async function createLead(data, token) {
    var responseLead = await sendLead(data, token);
    return responseLead;
  }

  useEffect(() => {
    // getToken();
  }, []);

  return (
    <AuthContext.Provider value={{ getToken, createLead }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) throw new Error("useAuth must be used within a AuthProvider");
  const { getToken, createLead } = context;
  return { getToken, createLead };
}
