import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { HeaderRowContainer, RowContainer, Switch } from "./styles";
import { FaLock, FaUnlock } from "react-icons/fa";
import { childrenAnimationShow } from "../../animations";

export const RowItem = ({ item, index, onClick }) => {
  const [isLocked, setIslocked] = useState(item.isLocked);

  const handleChangeLockItem = (index) => {
    // update context com index

    setIslocked(!isLocked);
  };

  return (
    <>
      <HeaderRowContainer locked={isLocked}>
        <label>{item.header}</label>
        <span
          className="lock-container"
          onClick={() => handleChangeLockItem(index)}
        >
          {isLocked ? (
            <FaLock className="icon" size={"12"} />
          ) : (
            <FaUnlock className="icon" size={"12"} />
          )}
        </span>
      </HeaderRowContainer>
      <RowContainer
        className={`${isLocked ? "locked" : ""}`}
        variants={childrenAnimationShow}
        initial="hidden"
        animate="visible"
      >
        {item.itens?.checkbox?.map((i, ind) => {
          return (
            <motion.div
              key={ind}
              variants={childrenAnimationShow}
              initial="hidden"
              animate="visible"
              className={`item ${i.selected ? "active" : ""}`}
            >
              <div className="line"></div>
              <div className="selector">
                <label>{i.name}</label>
                <Switch
                  className={`${i.selected ? "active" : ""}`}
                  onClick={() => onClick(i, "checkbox")}
                >
                  <span className="line-1"></span>
                  <span className="line-2"></span>
                </Switch>
              </div>
            </motion.div>
          );
        })}
        {item.itens?.radio?.map((i, ind) => {
          return (
            <motion.div
              key={ind}
              variants={childrenAnimationShow}
              initial="hidden"
              animate="visible"
              className={`item ${i.selected ? "active" : ""}`}
            >
              <div className="line"></div>
              <div className="selector">
                <label>{i.name}</label>
                <Switch
                  className={`${i.selected ? "active" : ""}`}
                  onClick={() => onClick(i, "radio")}
                >
                  <span className="line-1"></span>
                  <span className="line-2"></span>
                </Switch>
              </div>
            </motion.div>
          );
        })}
      </RowContainer>
    </>
  );
};
