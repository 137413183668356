import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  margin: 0 5px;
  flex-direction: column;
  /* min-height: 90%; */
  max-height: 90%;
  padding: 0px 0px;
  min-width: 380px;
  max-width: 380px;
  background: ${({ theme }) =>
    theme.items.configurador.colors.backgroundResumeDark};
  border-radius: 20px;
  border-bottom-right-radius: 0px;
  border: 2px solid ${({ theme }) => theme.items.configurador.colors.primary};
`;

export const ConfigurationLabel = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 5px;

  .title {
    font-family: ${({ theme }) => theme.items.configurador.colors.selected};
    font-size: 1.2rem;
    font-weight: bold;
    color: white;

    &.normal {
      font-weight: normal;
    }
  }
`;

export const DataHeader = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 5px;
  border-top: 2px solid rgba(255, 255, 255, 0.2);
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);

  .title {
    font-family: ${({ theme }) => theme.items.configurador.colors.selected};
    font-size: 1.2rem;
    font-weight: bold;
    color: white;

    &.normal {
      font-weight: normal;
    }
  }
`;

export const BulldozerDataContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: auto;
  height: calc(180px - 20px);
  margin: 10px 0px;
  /* background: red; */
  padding: 10px 5px;
  font-family: ${({ theme }) => theme.items.configurador.fonts.descriptions};

  .column {
    display: flex;
    height: 120px;
    max-width: 120px;
    justify-content: center;
    align-items: center;
    flex: 1;
    /* background: silver; */
    margin: 10px 10px;
    position: relative;

    .diameters {
      display: flex;
      width: 100%;
      height: 100%;
      border: 1px solid white;
      position: relative;
      justify-content: center;
      align-items: center;

      img {
        width: 80%;
      }

      .widthValue {
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: -30px;
        position: absolute;
        /* background: white; */
        width: 100%;
        color: black;

        &:before {
          position: absolute;
          content: "";
          width: 100%;
          height: 1px;
          background: white;
          z-index: -1;
        }

        label {
          background: black;
          color: white;
          padding: 2px 5px;
          font-family: ${({ theme }) =>
            theme.items.configurador.fonts.descriptions};
          font-weight: 700;
        }
      }

      .heightValue {
        display: flex;
        justify-content: center;
        align-items: center;
        /* bottom: -30px; */
        position: absolute;
        /* background: white; */
        width: 120px;
        color: black;
        transform: rotateZ(90deg);
        right: calc(-65px - 10px);

        &:before {
          position: absolute;
          content: "";
          width: 100%;
          height: 1px;
          background: white;
          z-index: -1;
        }

        label {
          background: black;
          color: white;
          padding: 2px 5px;
          font-family: ${({ theme }) =>
            theme.items.configurador.fonts.descriptions};
          font-weight: 700;
        }
      }
    }

    .circumference {
      display: flex;
      width: 100%;
      height: 100%;
      border-radius: 90%;
      border: 1px solid white;
      position: relative;
      justify-content: center;
      align-items: center;

      img {
        width: 60%;
      }

      .valueRange {
        position: absolute;
        bottom: -10px;
        background: black;
        padding: 2px 5px;
        font-family: ${({ theme }) =>
          theme.items.configurador.fonts.descriptions};
        font-weight: 700;
      }
    }
  }
`;

export const Description = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: ${({ theme }) => theme.items.configurador.colors.backgroundData};
  /* max-height: 100%; */
  margin-top: 10px;
  padding: 0px 10px;
  font-size: 0.85rem;
  color: white;
  overflow-y: auto;
  border-radius: 20px;
  border-bottom-right-radius: 0px;
  position: relative;

  span {
    font-weight: bold;
    margin: 10px 0px;

    label {
      margin-left: 10px;
      font-weight: 300;
      color: ${({ theme }) => theme.items.configurador.colors.greyFont};
    }
  }

  &::-webkit-scrollbar {
    width: 10px;
    height: 80%;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.items.configurador.colors.greyFont};
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.items.configurador.colors.primary};
    border-radius: 20px; /* roundness of the scroll thumb */
    width: 5px;
  }
`;
