import React from "react";

import { Container } from "./styles";

export const InputText = ({
  title,
  text,
  updateUser,
  param,
  width,
  maxLength,
}) => {
  return (
    <Container width={width !== null ? width : null}>
      <label>{title}:</label>
      <input
        maxLength={maxLength}
        defaultValue={text !== null ? text : ""}
        onChange={(e) => updateUser(param, e.target.value)}
      />
    </Container>
  );
};
