const prazoEntregaJSON = {
  prazoEntrega: [
    {
      clicked: false,
      nome: "Até 1 mês",
    },
    {
      clicked: false,
      nome: "Até 2 meses",
    },
    {
      clicked: false,
      nome: "De 3 a 5 meses",
    },
    {
      clicked: false,
      nome: "Acima de 5 meses",
    },
  ],
};

export default prazoEntregaJSON;
