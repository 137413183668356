import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    flex-direction: column;
    width: 100vw;
    height: calc(100vh - 70px);
    height: -o-calc(100vh - 70px); /* opera */
    height: -webkit-calc(100vh - 70px); /* google, safari */
    height: -moz-calc(100vh - 70px); /* firefox */

    .home-title-container{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin: 10px 5%;
        font-family: ${({ theme }) => theme.items.home.text.font};

        h1{
           color: ${({ theme }) => theme.items.home.text.primary_color};
           font-size: 2.2rem;
           line-height: 2.1rem;
           margin: 0;
           margin-bottom: 0px;
           text-transform: uppercase;
        }

        label{
            font-size: 0.78rem;
            line-height: 0.78rem;
            margin: 0;
            color: ${({ theme }) => theme.items.home.text.secondary_color};
        }
    }

    .home-buttons-area{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        /* background-color: red; */
        padding: 10px;
        width: 78vw;

        div{
            margin-bottom: 5px;
        }
    }
`;


export const SelectedBulldozerContainer = styled.div`
    display: flex;
    width: calc(100vw - 40px);
    height: calc(100vh - 100px);
    padding: 15px 20px;
    /* background: red; */
    justify-content: flex-start;
    flex-direction: column;

    .header{
        margin: 0px 15px;
        display: flex;
        justify-content: center;
        color: ${({ theme }) => theme.items.home.text.tertiary_color};
        border-bottom: 2px solid ${({ theme }) => theme.items.home.colors.primary};

        &.alt{
            border-bottom: 2px solid ${({ theme }) => theme.items.home.text.tertiary_color};
        }
    }

    .cards{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        max-height: calc(100vh - 90px);
        /* background: cyan; */
        margin: 10px 0px;
        overflow: scroll;
    }

    .card{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 38vw;
        height: 55vw;
        background: black;
        margin: 5px;
        border-radius: 10%;
        border: 4px solid ${({ theme }) => theme.items.home.colors.border};
        position: relative;

        img{
            width: 80%;
        }

        .truckTitle{
            position: absolute;
            bottom: 10px;
            font-family: ${({ theme }) => theme.items.home.text.font};
            color: ${({ theme }) => theme.items.home.text.tertiary_color};
            font-weight: 600;
            font-size: 1.2rem;
            letter-spacing: 0.01em;
        }
    }

    &.confirmContainer{
        justify-content: space-between;
        width: 100vw;
        height: calc(100vh - 70px);
        padding: 0px 0px;

        .backAction{
            display: flex;
            color: ${({ theme }) => theme.items.home.text.tertiary_color};
            font-weight: 600;
            font-size: 2.4rem;
            align-items: center;
            margin-left: 10px;
        }

        img{
            width: 80%;
            align-self: center;
        }

        .buttonConfirm{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            background-color: ${({ theme }) => theme.items.home.colors.primary};
            
            label{
                /* font-family: ${({ theme }) => theme.items.home.text.font}; */
                color: ${({ theme }) => theme.items.defaults.text.black};
                font-weight: 900;
                text-transform: uppercase;
                font-size: 1.5rem;
                line-height: 1.5rem;
                margin: 0;
                padding: 0;
            }
            
        }
    }

    

`;