import React from "react";

import { Container } from "./styles";

export const InputCheckbox = ({ title, value, updateUser, param, width }) => {
  return (
    <Container width={width !== null ? width : null}>
      <input
        type="checkbox"
        name={title}
        checked={value}
        onChange={(e) => updateUser(param, e.target.checked)}
      />
      <label htmlFor={title} dangerouslySetInnerHTML={{ __html: title }} />
    </Container>
  );
};
