import { Container } from "./styles";

export const PrimaryButton = ({ text, size, action }) => {
  return (
    <Container>
      <div className={`primary ${size ? size : ""}`} onClick={() => action()}>
        {text}
      </div>
    </Container>
  );
};
