import { createContext, useContext } from "react";
import * as THREE from "three";
import { Canvas } from "@react-three/fiber";
import { useConfigurator } from "../configurator";

const context = createContext({});
function ForwardCanvasContext({ children, ...props }) {
  const value = useConfigurator();
  return (
    <Canvas {...props}>
      <context.Provider value={value}>{children}</context.Provider>
    </Canvas>
  );
}
export const useCanvasContext = () => useContext(context);
export default ForwardCanvasContext;
