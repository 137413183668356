import { motion, AnimatePresence } from 'framer-motion';


export const showAnimation = {
    hidden: {
        opacity: 0,
        x: '20px',
    },
    visible: {
        opacity: 1,
        x: '0',
        transition: { delay: 0.2}
    }
}

export const showAnimationUp = {
    hidden: {
        opacity: 0,
        y: '0',
    },
    visible: {
        opacity: 1,
        y: '0',
        transition: { delay: 0.2, ease: "anticipate", staggerChildren: 0.5}
    }
}

export const childrenAnimationShow = {
    hidden: {
        opacity: 0,
        x: "-20px"
    },
    visible: {
        opacity: 1,
        x: "0",
        transition: { staggerChildren: 0.5, delay: 0.5, }
    }
}

export const showDot = {
    hidden: {
        opacity: 0,
        y: '20px',
    },
    visible: {
        opacity: 1,
        y: '0px',
        transition: { duration: 0.2 }
    }
}


export const showAnimationInfoBox = {
    hidden: {
        opacity: 0,
        y: '100vh',
    },
    visible: {
        opacity: 1,
        y: '0',
        transition: { delay: 0.2}
    }
}