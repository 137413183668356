import React from "react";

import { isMobile } from "react-device-detect";
import ConfiguradorDesktop from "./desktop";
import ConfiguradorMobile from "./mobile";

const Configurador = () => {
  return <>{isMobile ? <ConfiguradorMobile /> : <ConfiguradorDesktop />}</>;
};

export default Configurador;
