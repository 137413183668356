import React from "react";

import { Container } from "./styles";

export const Select = ({
  title,
  value,
  updateUser,
  param,
  width,
  lista,
  placeholder,
}) => {
  return (
    <Container width={width !== null ? width : null}>
      <label>{title}:</label>
      <select
        // name="estadoUser"
        // id="estadoUser"
        placeholder={placeholder}
        autoFocus
        autoComplete="off"
        onChange={(e) => updateUser(param, e.target.value)}
        defaultValue={value}
      >
        {lista.estados.map((value, index) => {
          return (
            <option key={index} value={value.sigla}>
              {value.sigla}
            </option>
          );
        })}
      </select>
    </Container>
  );
};
