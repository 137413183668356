import React from "react";

import { PrimaryButton } from '../../shared/Components/Buttons'
import { isMobile } from "react-device-detect";

import HomeDesktop from "./desktop";
import HomeMobile from "./mobile";

const Home = () => {
  return (<>
    {isMobile ? <HomeMobile/>: <HomeDesktop/>} 
  </>);
};

export default Home;
