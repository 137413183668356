import { useEffect, useState, useRef } from "react";
import { Wrapper } from "./styled";
import { useAlert } from "../../../contexts/alert";

const Alert = () => {
  const { alert, setAlert } = useAlert();
  const [classname, setClassname] = useState("");
  let timer = useRef(null);

  useEffect(() => {
    if (alert.show) {
      setClassname("on");
      try {
        clearTimeout(timer.current);
      } catch (err) {}
      timer.current = setTimeout(() => {
        setAlert((values) => ({
          ...values,
          show: false,
        }));
      }, alert.time);
    } else {
      setClassname("");
    }
  }, [alert]);

  return <Wrapper className={classname}>{alert.message}</Wrapper>;
};

export default Alert;
