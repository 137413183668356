const pesoOperacionalJSON = {
  pesoOperacional: [
    {
      clicked: false,
      nome: "14 ton",
      model: ["EC140DL"],
    },
    {
      clicked: false,
      nome: "15 ton",
      model: ["EC140DLM"],
    },
    {
      clicked: false,
      nome: "20 ton",
      model: ["EC200D"],
    },
    {
      clicked: false,
      nome: "21 ton",
      model: ["EC210D", "EC220D"],
    },
    {
      clicked: false,
      nome: "22 ton",
      model: ["EC210DL", "EC220DL"],
    },
    {
      clicked: false,
      nome: "26 ton",
      model: ["EC250DL"],
    },
  ],
};

export default pesoOperacionalJSON;
