import React, {
  Suspense,
  useEffect,
  useState,
  useRef,
  forwardRef,
} from "react";
import Canvas from "../../../../contexts/ForwardCanvasContext";
import * as THREE from "three";
// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import {
  useFrame,
  extend,
  useThree,
  useLoader,
  invalidate,
} from "@react-three/fiber";
import {
  Stats,
  PerspectiveCamera,
  Box,
  RoundedBox,
  Ring,
  useTexture,
  Environment,
  QuadraticBezierLine,
  Line,
  Html,
  OrbitControls,
  Text,
  AdaptiveDpr,
  AdaptiveEvents,
  ContactShadows,
  Billboard,
  Plane,
  Image,
} from "@react-three/drei";
import PreloaderCanvas from "../../../../shared/Components/PreloaderCanvas";
import "./style.scss";
// import Envi from "./Envi";
import Menu3D from "./Menu3D";
import Menu3DOpcionais from "./Menu3DOpcionais";
import hdri from "./Envi/shudu_lake_1k-yellow.hdr";
import { R } from "styled-icons/crypto";
import { Timer } from "styled-icons/boxicons-regular";
import { useConfigurator } from "../../../../contexts/configurator";
import { useAlert } from "../../../../contexts/alert";
import trezentosesessenta from "../../../../assets/images/360.png";
import closeInstruction from "../../../../assets/images/close.png";
import montserrat from "../../../../assets/fonts/montserrat-v24-latin-700.woff";

// extend({ OrbitControls });

const EC140 = React.lazy(() => import("../../../../assets/Modelos/EC140DLNEW"));
// const Test = React.lazy(() => import("../../../../assets/Modelos/Test"));
// const VMCHASSI8x4 = React.lazy(() =>
//   import("../../../../assets/Modelos/VMCHASSI8x4")
// );

const Light = (props) => {
  const targetObject = new THREE.Object3D();
  const { scene, gl } = useThree();
  scene.add(targetObject);
  targetObject.position.set(
    props.targetPos[0],
    props.targetPos[1],
    props.targetPos[2]
  );
  const light = new THREE.DirectionalLight(props.lightColor, props.intensity);
  light.position.set(props.pos[0], props.pos[1], props.pos[2]);
  light.target = targetObject;
  light.castShadow = true;
  light.shadow.mapSize.width = 512;
  light.shadow.mapSize.height = 512;
  light.shadow.camera.near = 0.1;
  light.shadow.camera.far = 1000;
  light.shadow.camera.top = -400;
  light.shadow.camera.right = 400;
  light.shadow.camera.left = -400;
  light.shadow.camera.bottom = 400;
  light.shadow.bias = -0.01;

  return <primitive object={light} />;
};

function AdaptivePixelRatio() {
  const { gl, performance } = useThree();
  const current = performance.current;

  function lerp(start, end, amt) {
    return (1 - amt) * start + amt * end;
  }

  useEffect(() => {
    performance.debounce = 10;
  }, []);

  useFrame(() => {
    let pr = gl.getPixelRatio();
    if (current < 1) {
      invalidate();
      gl.setPixelRatio(lerp(pr, window.devicePixelRatio * current, 0.1));
    } else {
      if (pr < window.devicePixelRatio - 0.01) {
        invalidate();
        gl.setPixelRatio(lerp(pr, window.devicePixelRatio * current, 0.1));
      } else if (
        pr >= window.devicePixelRatio - 0.01 &&
        pr < window.devicePixelRatio
      ) {
        invalidate();
        gl.setPixelRatio(window.devicePixelRatio * current);
      }
    }
  });
  return null;
}

const CameraControls = forwardRef((props, refOrbit) => {
  const {
    camera,
    gl: { domElement },
  } = useThree();

  return (
    <OrbitControls
      ref={refOrbit}
      args={[camera, domElement]}
      minPolarAngle={-Math.PI / 20}
      maxPolarAngle={Math.PI / 2}
      minDistance={400}
      maxDistance={800}
      enableZoom={true}
      enablePan={false}
      enableRotate={true}
      autoRotate={false}
      enableDamping={true}
      dampingFactor={0.2}
      regress
    />
  );
});

const CheckMenu = ({ data, setData, isOnMenu, reseted }) => {
  useEffect(() => {
    if (isOnMenu && reseted) {
      setData((values) => ({
        ...values,
        menuOpacity: 1,
      }));
    } else {
      setData((values) => ({
        ...values,
        menuOpacity: 0,
      }));
    }
  }, [isOnMenu, reseted]);
};

const AnimateCamera = ({
  instructionOff,
  interactionStarted,
  setInteractionStarted,
  data,
  refOrbit,
}) => {
  const { camera, gl } = useThree();
  const [animationSpeed, setAnimationSpeed] = useState(0.03);

  useFrame(() => {
    if (!interactionStarted) {
      camera.position.lerp(
        new THREE.Vector3(data.camPosX, data.camPosY, data.camPosZ),
        animationSpeed
      );
      refOrbit.current.target = new THREE.Vector3(
        data.targetPosX,
        data.targetPosY,
        data.targetPosZ
      );
      refOrbit.current.update();
      if (camera.position.x >= data.camPosX - 5) {
        setInteractionStarted(true);
        // if (screenStep === 2) {
        //   setIsOnMenu(true);
        // }
      }
    } else {
      if (!instructionOff) {
        camera.position.x = data.camPosX;
        camera.position.y = data.camPosY;
        camera.position.z = data.camPosZ;
      }
    }
  });
};

const RingGround = ({ isOnRadius }) => {
  const ringRef = useRef(null);

  useFrame(() => {
    if (ringRef.current !== null) {
      if (isOnRadius) {
        if (ringRef.current.scale.x < 1.072) {
          ringRef.current.scale.x = ringRef.current.scale.y = ringRef.current.scale.z =
            ringRef.current.scale.x + 0.025;
          invalidate();
        }
      } else {
        if (ringRef.current.scale.x > 0) {
          ringRef.current.scale.x = ringRef.current.scale.y = ringRef.current.scale.z =
            ringRef.current.scale.x - 0.025;
          invalidate();
        }
      }
    }
  });
  return (
    <group ref={ringRef}>
      <Ring
        args={[63, 63.8, 100]}
        position={[0, 1, 0]}
        rotation={[-Math.PI / 2, 0, 0]}
      >
        <meshBasicMaterial attach="material" color="#FFB800" />
      </Ring>
      <Billboard follow={true}>
        <Line
          points={[
            [0, -30, 0],
            [0, 1, 0],
            [0, 1, -62.5],
            [0, -30, -62.5],
          ]} // Array of points
          color="#FFB800" // Default
          lineWidth={0.5} // In pixels (default)
          dashed={true} // Default
          rotation={[Math.PI / 2, -Math.PI / 2, Math.PI / 2]}
        />
        <Text
          color={"#FFB800"}
          fontSize={10}
          maxWidth={200}
          lineHeight={1}
          letterSpacing={0.02}
          textAlign={"left"}
          font={
            "https://db.onlinewebfonts.com/t/d60b13a925ef3dfc380e1dcd4cc5673b.woff"
          }
          anchorX="center"
          anchorY="middle"
          position={[30, -30, 0]}
        >
          2.200mm
        </Text>
      </Billboard>
    </group>
  );
};

const Config3d = ({
  instructionOff,
  setInstructionOff,
  firstView,
  canvasClass,
  isOnMenu,
  setIsOnMenu,
  isOnKeyboard,
  setIsOnKeyboard,
  reseted,
  setReseted,
  pressedKey,
  setPressedKey,
  isOnRadius,
  setIsOnRadius,
  screenStep,
  escavadeiraRotate,
  setEscavadeiraRotate,
  timeScale,
  setTimeScale,
}) => {
  const { alert, setAlert } = useAlert();
  const [shouldInvalidate, toggleInvalidate] = useState(true);
  const isOnKeyboardRef = useRef(isOnKeyboard);
  const virtualCamera = useRef(null);
  const refOrbit = useRef(null);
  const [interactionStarted, setInteractionStarted] = useState(false);

  const {
    getOptions,
    loadedConfig,
    selectOption,
    _3DConfig,
    config: contextConfig,
  } = useConfigurator();

  const [menuItems, setMenuItems] = useState([]);
  const [menuItemsOpcionais, setMenuItemsOpcionais] = useState([]);

  useEffect(() => {
    if (!loadedConfig) return;
    setMenuItems([
      {
        title: "Sapata",
        open: true,
        items: [
          {
            type: "radio",
            options: getOptions("sapata").radio.map((i) => ({
              item: i,
              parent: getOptions("sapata"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            })),
          },
        ],
        position: {
          start: {
            x: 38,
            y: 25,
            z: -60,
          },
          end: {
            x: 0.029,
            y: -0.0,
          },
        },
        align: "left",
      },
      {
        title: "Lança",
        open: true,
        items: [
          {
            type: "radio",
            options: getOptions("Lança").radio.map((i) => ({
              item: i,
              parent: getOptions("Lança"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            })),
          },
        ],
        position: {
          start: {
            x: -5,
            y: 125,
            z: 70,
          },
          end: {
            x: 0.01,
            y: 0.02,
          },
        },
        align: "left",
      },
      {
        title: "Braço",
        open: true,
        items: [
          {
            type: "radio",
            options: getOptions("Braço").radio.map((i) => ({
              item: i,
              parent: getOptions("Braço"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            })),
          },
        ],
        position: {
          start: {
            x: -6,
            y: 142,
            z: 130,
          },
          end: {
            x: -0.016,
            y: 0.021,
          },
        },
        align: "right",
      },
      {
        title: "Caçamba",
        open: true,
        items: [
          {
            type: "radio",
            options: getOptions("caçamba").radio.map((i) => ({
              item: i,
              parent: getOptions("caçamba"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            })),
          },
          {
            type: "toogle",
            options: getOptions("caçamba").checkbox.map((i) => ({
              item: i,
              parent: getOptions("caçamba"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            })),
          },
        ],
        position: {
          start: {
            x: -8,
            y: 20,
            z: 135,
          },
          end: {
            x: -0.014,
            y: -0.014,
          },
        },
        align: "right",
      },
    ]);
    setMenuItemsOpcionais([
      {
        title: "Linha Hidráulica",
        open: false,
        items: [
          {
            type: "radio-big",
            title: "X1",
            options: getOptions("Linha Hidráulica").radio.map((i) => ({
              item: i,
              parent: getOptions("Linha Hidráulica"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            })),
          },
          {
            type: "radio-big-2",
            title: "X3",
            options: getOptions("Linha Hidráulica X3").checkbox.map((i) => ({
              item: i,
              parent: getOptions("Linha Hidráulica X3"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            })),
          },
        ],
        position: {
          start: {
            x: -8,
            y: 125,
            z: 55,
          },
          end: {
            x: -0.015,
            y: 0.026,
          },
        },
        align: "right",
        open: false,
        visible: false,
        opacity: 0,
        fadeOut: false,
      },
      {
        title: "Sistema Elétrico",
        items: [
          {
            type: "radio",
            options: getOptions("Luzes").radio.map((i) => ({
              item: i,
              parent: getOptions("Luzes"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            })),
          },
          {
            type: "toogle",
            options: getOptions("Sistema Elétrico").checkbox.map((i) => ({
              item: i,
              parent: getOptions("Sistema Elétrico"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            })),
          },
        ],
        position: {
          start: {
            x: 6,
            y: 93,
            z: -5,
          },
          end: {
            x: 0.006,
            y: 0.022,
          },
        },
        align: "right",
        open: false,
        visible: false,
        opacity: 0,
        fadeOut: false,
      },
      {
        title: "Cabine",
        items: [
          {
            type: "toogle",
            options: getOptions("Cabine").checkbox.map((i) => ({
              item: i,
              parent: getOptions("Cabine"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            })),
          },
        ],
        position: {
          start: {
            x: 20,
            y: 85,
            z: 38,
          },
          end: {
            x: -0.02,
            y: 0.01,
          },
        },
        align: "right",
        open: false,
        visible: false,
        opacity: 0,
        fadeOut: false,
      },
      {
        title: "Sistema Hidráulico",
        items: [
          {
            type: "toogle",
            options: getOptions("Sistema Hidráulico").checkbox.map((i) => ({
              item: i,
              parent: getOptions("Sistema Hidráulico"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            })),
          },
        ],
        position: {
          start: {
            x: -0.2,
            y: 77,
            z: -50,
          },
          end: {
            x: 0.009,
            y: 0.024,
          },
        },
        align: "left",
        open: false,
        visible: false,
        opacity: 0,
        fadeOut: false,
      },
      {
        title: "Proteção do Carro",
        items: [
          {
            type: "radio",
            options: [...getOptions("Proteção do Carro Inferior").radio.map((i) => ({
              item: i,
              parent: getOptions("Proteção do Carro Inferior"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            })),...getOptions("Proteção do Carro Superior").radio.map((i) => ({
              item: i,
              parent: getOptions("Proteção do Carro Superior"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            }))],
          },
        ],
        position: {
          start: {
            x: 45,
            y: 85,
            z: -7,
          },
          end: {
            x: 0.025,
            y: 0.022,
          },
        },
        align: "left",
        open: false,
        visible: false,
        opacity: 0,
        fadeOut: false,
      },
      {
        title: "Assento",
        items: [
          {
            type: "radio",
            options: getOptions("Assento").radio.map((i) => ({
              item: i,
              parent: getOptions("Assento"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            })),
          },
          {
            type: "toogle",
            options: getOptions("Assento").checkbox.map((i) => ({
              item: i,
              parent: getOptions("Assento"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            })),
          },
        ],
        position: {
          start: {
            x: 40,
            y: 50,
            z: 50,
          },
          end: {
            x: -0.019,
            y: -0.005,
          },
        },
        align: "right",
        open: false,
        visible: false,
        opacity: 0,
        fadeOut: false,
      },
      {
        title: "Motor",
        items: [
          {
            type: "radio",
            options: getOptions("Motor").radio?.map((i) => ({
              item: i,
              parent: getOptions("Motor"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            })),
          },
          {
            type: "toogle",
            options: getOptions("Motor").checkbox.map((i) => ({
              item: i,
              parent: getOptions("Motor"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            })),
          },
        ],
        position: {
          start: {
            x: 45,
            y: 45,
            z: -40,
          },
          end: {
            x: 0.03,
            y: 0.016,
          },
        },
        align: "left",
        open: false,
        visible: false,
        opacity: 0,
        fadeOut: false,
      },
    ]);
  }, [loadedConfig, contextConfig]);

  const [config, setConfig] = useState({
    bracoCurto: {
      active: true,
      cacambaPequena: false,
      cacambaGrande: true,
    },
    bracoLongo: {
      active: false,
      cacambaPequena: false,
      cacambaGrande: false,
    },
    sapata: {
      pequena: true,
      media: false,
      grande: false,
    },
  });

  const [data, setData] = useState({
    axis: true,
    feature: "",
    camPosX: 200,
    camPosY: 200,
    camPosZ: 430,
    fov: 35,
    focusDistance: 0.092,
    focalLength: 1.0,
    bokehScale: 3.68,
    height: 1080,
    targetVisible: true,
    targetPosX: 0,
    targetPosY: 60,
    targetPosZ: 1.7,
    backgroundColor: "#f3f3f3",
    fog: false,
    fogColor: "#f3f3f3",
    fogNear: 500,
    fogFar: 1000,
    menuOpacity: 0,
  });

  const handleDown = (e) => {
    if (!firstView) setInteractionStarted(true);
  };

  const useInterval = (callback, delay) => {
    const intervalId = React.useRef(null);
    const savedCallback = React.useRef(callback);

    React.useEffect(() => {
      savedCallback.current = callback;
    });

    React.useEffect(() => {
      const tick = () => savedCallback.current();

      if (typeof delay === "number") {
        intervalId.current = window.setInterval(tick, delay);

        return () => window.clearInterval(intervalId.current);
      }
    }, [delay]);

    return intervalId.current;
  };

  const onKeyPressed = (e) => {
    switch (e.key) {
      case "a":
      case "A":
        setPressedKey("a");
        isOnKeyboardRef.current && setEscavadeiraRotate("left");
        break;
      case "w":
      case "W":
        setPressedKey("w");
        isOnKeyboardRef.current && setTimeScale(1);
        break;
      case "d":
      case "D":
        setPressedKey("d");
        isOnKeyboardRef.current && setEscavadeiraRotate("right");
        break;
      case "s":
      case "S":
        setPressedKey("s");
        isOnKeyboardRef.current && setTimeScale(-1);
        break;
    }
  };

  const onKeyReleased = (e) => {
    switch (e.key) {
      case "a":
      case "A":
        setPressedKey("");
        isOnKeyboardRef.current && setEscavadeiraRotate(null);
        break;
      case "w":
      case "W":
        setPressedKey("");
        isOnKeyboardRef.current && setTimeScale(0);
        break;
      case "d":
      case "D":
        setPressedKey("");
        isOnKeyboardRef.current && setEscavadeiraRotate(null);
        break;
      case "s":
      case "S":
        setPressedKey("");
        isOnKeyboardRef.current && setTimeScale(0);
        break;
    }
  };

  useEffect(() => {
    document.onpointerdown = handleDown;
    document.addEventListener("keydown", onKeyPressed);
    document.addEventListener("keyup", onKeyReleased);
  }, []);

  useEffect(() => {
    isOnKeyboardRef.current = isOnKeyboard;
    if (!isOnKeyboard && !reseted) {
      setTimeScale(-1);
      setEscavadeiraRotate("reset");
    }
  }, [isOnKeyboard]);

  return (
    <>
      <div
        className={`warning360 ${instructionOff && "off"}`}
        onClick={() => setInstructionOff(true)}
      >
        <img
          className="close-btn"
          src={closeInstruction}
          width="50"
          height="50"
        />
        <img
          className="image"
          src={trezentosesessenta}
          width="80"
          height="80"
        />
        {`ROTACIONE 360º
LIVREMENTE`}
      </div>
      <Canvas
        mode={"concurrent"}
        className={"canvas " + canvasClass}
        // dpr={[1, 2]}
        shadows={true}
        frameloop={"demand"}
        performance={{ min: 0.7 }}
        gl={{
          alpha: true,
          outputEncoding: THREE.sRGBEncoding,
          antialias: true,
          preserveDrawingBuffer: true,
          logarithmicDepthBuffer: true,
          powerPreference: "high-performance",
        }}
        style={{ pointerEvents: firstView ? "none" : "all" }}
        camera={{
          position: [0, 0, 0],
          fov: data.fov,
          near: 0.1,
          far: 1000,
          zoom: 1,
        }}
      >
        {/* <ambientLight intensity={0.2} /> */}
        <Light
          pos={[500, 200, 320]}
          targetPos={[100, -90, 0]}
          intensity={1}
          lightColor={"#ffffff"}
        />
        <Environment files={hdri} />

        <Suspense fallback={<PreloaderCanvas />}>
          <EC140
            invalidate={toggleInvalidate}
            renderOrder={10}
            scale={[3, 3, 3]}
            position={[0, 0, 0]}
            escavadeiraRotate={escavadeiraRotate}
            setEscavadeiraRotate={setEscavadeiraRotate}
            setReseted={setReseted}
            timeScale={timeScale}
            config={_3DConfig}
            contextConfig={contextConfig}
            envMapIntensity={1}
          />
          <Menu3D
            alert={alert}
            setAlert={setAlert}
            setMenuItems={setMenuItems}
            menuItems={menuItems}
            selectOption={selectOption}
            // virtualCamera={virtualCamera}
            opacity={data.menuOpacity}
            visible={screenStep === 1 && data.menuOpacity ? true : false}
          />
          <Menu3DOpcionais
            alert={alert}
            setAlert={setAlert}
            setMenuItems={setMenuItemsOpcionais}
            menuItems={menuItemsOpcionais}
            selectOption={selectOption}
            // virtualCamera={virtualCamera}
            opacity={data.menuOpacity}
            visible={screenStep === 2 && data.menuOpacity ? true : false}
          />
          <RingGround isOnRadius={isOnRadius} />
          {/* <ContactShadows
            opacity={1}
            scale={500}
            blur={0.8}
            far={80}
            resolution={256}
            color="#000000"
            transparent={true}
          /> */}
        </Suspense>

        <group>
          <mesh
            receiveShadow
            rotation={[-Math.PI / 2, 0, 0]}
            position={[0, 0, 0]}
          >
            <planeBufferGeometry attach="geometry" args={[1000, 1000]} />
            <shadowMaterial
              receiveShadow
              attach="material"
              opacity={0.5}
              transparent={true}
              // color="blue"
            />
          </mesh>
        </group>
        <CameraControls ref={refOrbit} />
        <CheckMenu
          data={data}
          setData={setData}
          isOnMenu={isOnMenu}
          reseted={reseted}
        />
        <AnimateCamera
          instructionOff={instructionOff}
          interactionStarted={interactionStarted}
          setInteractionStarted={setInteractionStarted}
          data={data}
          refOrbit={refOrbit}
        />
        <AdaptivePixelRatio />
      </Canvas>
    </>
  );
};

export default React.memo(Config3d);
