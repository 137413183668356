import styled from "styled-components";
import keyboardbase from "../../../../shared/assets/images/keyboard-base.svg";
import keyA from "../../../../shared/assets/images/a-up.svg";
import keyAdown from "../../../../shared/assets/images/a-down.svg";
import keyW from "../../../../shared/assets/images/w-up.svg";
import keyWdown from "../../../../shared/assets/images/w-down.svg";
import keyS from "../../../../shared/assets/images/s-up.svg";
import keySdown from "../../../../shared/assets/images/s-down.svg";
import keyD from "../../../../shared/assets/images/d-up.svg";
import keyDdown from "../../../../shared/assets/images/d-down.svg";

export const KeyboardContainer = styled.div`
  position: absolute;
  width: 441px;
  height: 141px;
  bottom: -300px;
  right: 50px;
  background-image: url(${keyboardbase});
  background-repeat: no-repeat;
  opacity: 0;
  pointer-events: none;
  transition: all 1s ease-in-out 0s;

  &.on {
    opacity: 1;
    bottom: 50px;
    pointer-events: all;
  }

  .botoes {
    position: relative;
    width: 100%;
    height: 100%;

    .a {
      position: absolute;
      width: 68px;
      height: 54px;
      background-image: url(${keyA});
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      left: 95px;
      top: 45px;

      &.pressed {
        background-image: url(${keyAdown});
        top: 55px;
      }
    }
    .w {
      position: absolute;
      width: 63px;
      height: 54px;
      background-image: url(${keyW});
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      left: 188px;
      top: -13px;
      touch-action: none;

      &.pressed {
        background-image: url(${keyWdown});
        top: -3px;
      }
    }
    .s {
      position: absolute;
      width: 69px;
      height: 54px;
      background-image: url(${keyS});
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      left: 185px;
      top: 45px;
      touch-action: none;

      &.pressed {
        background-image: url(${keySdown});
        top: 55px;
      }
    }
    .d {
      position: absolute;
      width: 69px;
      height: 54px;
      background-image: url(${keyD});
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      left: 275px;
      top: 45px;
      touch-action: none;

      &.pressed {
        background-image: url(${keyDdown});
        top: 55px;
      }
    }
  }
`;
