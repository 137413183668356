import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  height: ${({ height }) => height - 70}px;
  /* height: calc(100vh - 70px); */
  position: relative;
  top: 70px;
  /* background-color: red; */
`;

export const MapContainer = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
`;

export const ContentContainer = styled.div`
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: calc(100% - 4px);
  height: 100%;
  /* min-height: 100%; */
  background-color: rgba(0, 0, 0, 0.9);
  /* border: 1px solid white; */
  /* left: 10px; */
  /* margin: 20px 0px; */
  /* border-radius: 10px; */
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: white;
    font-family: ${({ theme }) => theme.items.configurador.fonts.description};
    font-weight: bold;
    width: 100%;
    min-height: 40px;
    border-bottom: 1px solid white;
  }
`;

export const ConcessionariaItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 1rem;
  color: white;
  font-family: ${({ theme }) => theme.items.configurador.fonts.description};
  font-weight: bold;
  width: calc(100% - 20px);
  padding: 10px 10px;
  min-height: 40px;
  /* border-bottom: 1px solid white; */
  background-color: ${({ id, theme }) => (id % 2 === 0 ? "black" : "grey")};

  .selected {
    color: ${({ theme }) => theme.items.configurador.colors.primary};
  }

  .content {
    display: flex;

    .text {
      display: none;
      font-size: 0.8rem;
      color: white;
      font-weight: normal;
      margin-bottom: 10px;
    }

    .actions {
      display: none;
    }

    &.show {
      height: auto;
      padding: 10px 0px;

      .text {
        display: flex;
        flex-direction: column;
      }

      .actions {
        display: flex;
      }
    }
  }

  cursor: pointer;
`;

export const ShowInfoConc = styled.div`
  display: flex;
  position: absolute;
  top: 60px;
  left: 280px;
  width: calc(380px - 20px);
  height: auto;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 10px;
  padding: 10px;
  border: 1px solid white;
  flex-direction: column;

  img {
    width: 100%;
    height: 40%;
    border-radius: 10px;
    object-fit: center;
  }

  .title {
    font-size: 1rem;
    color: white;
    font-weight: bold;
    margin: 10px 0px;
  }

  .text {
    font-size: 0.8rem;
    color: white;
    font-weight: normal;
    margin-bottom: 10px;
  }

  .buttonsArea {
    display: flex;
    width: 100%;
    height: 35px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: black;
      flex: 1;
      border-radius: 10px;
      cursor: pointer;
    }

    .share {
      background: white;
      margin-right: 5px;
    }

    .show-path {
      background-color: ${({ theme }) =>
        theme.items.configurador.colors.primary};
    }
  }
`;
