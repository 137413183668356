import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import { OpcionaisContainer, RowContainer, Switch, Infobox } from "./styles";

import {
  showDot,
  showAnimation,
  showAnimationUp,
  childrenAnimationShow,
  showAnimationInfoBox,
} from "../animations";

import { FaInfoCircle } from "react-icons/fa";
import { RiCloseCircleFill } from "react-icons/ri";

import mockImageInfo from "../../../../shared/assets/mock/infoImg.png";

import { useConfigurator } from "../../../../contexts/configurator";
export default function Opcionais({ isIos }) {
  const { getOptions, selectOption, lastItemChanged } = useConfigurator();
  const [tabs, setTabs] = useState([
    ,
    {
      label: "Motor",
      item: getOptions("Motor"),
    },
    {
      label: "Cabine",
      item: getOptions("Cabine"),
    },
    {
      label: "Assento",
      item: getOptions("Assento"),
    },
    {
      label: "Proteção do carro",
      item: getOptions("Proteção do Carro Inferior"),
    },
    {
      label: "Sistema hidráulico",
      item: getOptions("Sistema hidráulico"),
    },
    ,
    {
      label: "Linhas Hidráulicas",
      item: getOptions("Linhas Hidráulicas"),
    },
  ]);
  const [selectedTab, setSelectedTab] = useState("Motor");
  const [options, setOptions] = useState({ radio: [], checkbox: [] });
  // const [ selectedItem, setSelectedItem ] = useState('');
  const [showInfoPage, setShowInfoPage] = useState(false);

  // informações que abrem sobre o item //
  const [infoOptional, setInfoOptional] = useState({
    image: "/assets/infoImg.png",
    text:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  });

  useEffect(() => {
    // console.log(tabs);
    tabs.map((item) => {
      // console.log('11',item);
      if (item.label === selectedTab) setOptions(item.item);
    });
  }, [selectedTab]);

  const handleShowInfo = ({ data }) => {
    // data é a informação que é pra aparecer no card //
    setShowInfoPage(true);
    // console.log("mostrou!");
  };

  return (
    <OpcionaisContainer
      variants={showAnimation}
      initial="hidden"
      animate="visible"
    >
      {selectedTab === "Linhas Hidráulicas" ? (
        <div
          variants={showAnimationUp}
          initial="hidden"
          animate="visible"
          className="itensSelectorFullScreen"
        >
          <h3>Linha Hidráulica X1</h3>
          <RowContainer
            variants={childrenAnimationShow}
            initial="hidden"
            animate="visible"
          >
            {getOptions("Linha Hidráulica").radio.map((option) => (
              <motion.div
                key={option.id}
                variants={childrenAnimationShow}
                initial="hidden"
                animate="visible"
                className="item"
              >
                <div className="line"></div>
                <div className="selector">
                  <label>{option.name}</label>
                  <Switch
                    isSelected={option.selected}
                    onClick={() =>
                      selectOption(
                        getOptions("Linha Hidráulica"),
                        "checkbox",
                        option
                      )
                    }
                  >
                    <span className="circle">
                      <span className="inner"></span>
                    </span>
                  </Switch>
                </div>
              </motion.div>
            ))}
          </RowContainer>
          <h3>Linha Hidráulica X3</h3>
          <RowContainer
            variants={childrenAnimationShow}
            initial="hidden"
            animate="visible"
          >
            {getOptions("Linha Hidráulica X3").checkbox.map((option) => (
              <motion.div
                key={option.id}
                variants={childrenAnimationShow}
                initial="hidden"
                animate="visible"
                className="item"
              >
                <div className="line"></div>
                <div className="selector">
                  <label>{option.name}</label>
                  <Switch
                    isSelected={option.selected}
                    onClick={() =>
                      selectOption(
                        getOptions("Linha Hidráulica X3"),
                        "checkbox",
                        option
                      )
                    }
                  >
                    <span className="circle">
                      <span className="inner"></span>
                    </span>
                  </Switch>
                </div>
              </motion.div>
            ))}
          </RowContainer>
        </div>
      ) : (
          <motion.div
            variants={showAnimation}
            initial="hidden"
            animate="visible"
            className="itensSelectorContainer"
          >
            {selectedTab === "Proteção do carro" ? (<>
              <div className="itensSelector">
                {options?.radio?.map((i, index) => (
                  <a
                    key={String(index)}
                    className={i.selected ? "selected" : ""}
                    onClick={() => selectOption(options, "radio", i)}
                  >
                    <div className="plus">
                      <span className="line-1"></span>
                      <span className="line-2"></span>
                    </div>
                    <label className="label">{i.name}</label>
                    <FaInfoCircle
                      className="info-btn"
                      size={18}
                      onClick={handleShowInfo}
                    />
                  </a>
                ))}
                {getOptions("Proteção do Carro Superior")?.radio?.map((i, index) => (
                  <a
                    key={String(index)}
                    className={i.selected ? "selected" : ""}
                    onClick={() => selectOption(getOptions("Proteção do Carro Superior"), "radio", i)}
                  >
                    <div className="plus">
                      <span className="line-1"></span>
                      <span className="line-2"></span>
                    </div>
                    <label className="label">{i.name}</label>
                    <FaInfoCircle
                      className="info-btn"
                      size={18}
                      onClick={handleShowInfo}
                    />
                  </a>
                ))}
              </div>
            </>) : (
              <div className="itensSelector">
                {options?.checkbox?.map((i, index) => (
                  <a
                    key={String(index)}
                    className={i.selected ? "selected" : ""}
                    onClick={() => selectOption(options, "checkbox", i)}
                  >
                    <div className="plus">
                      <span className="line-1"></span>
                      <span className="line-2"></span>
                    </div>
                    <label className="label">{i.name}</label>
                    <FaInfoCircle
                      className="info-btn"
                      size={18}
                      onClick={handleShowInfo}
                    />
                  </a>
                ))}
                {options?.radio?.map((i, index) => (
                  <a
                    key={String(index)}
                    className={i.selected ? "selected" : ""}
                    onClick={() => { selectOption(options.id ? options : i.parent, "radio", i); console.log(options, i); setTabs(prev => prev) }}
                  >
                    <div className="plus">
                      <span className="line-1"></span>
                      <span className="line-2"></span>
                    </div>
                    <label className="label">{i.name}</label>
                    <FaInfoCircle
                      className="info-btn"
                      size={18}
                      onClick={handleShowInfo}
                    />
                  </a>
                ))}
              </div>)}
          </motion.div>
      )}
      <div className="tabsSelector">
        {tabs.map((item) => {
          return (
            <a
              key={item.label}
              className={item.label === selectedTab ? "selected" : ""}
              onClick={() => setSelectedTab(item.label)}
            >
              {`${item.label}`}
              {item.label === selectedTab ? (
                <motion.div
                  className="dot"
                  variants={showDot}
                  initial="hidden"
                  animate="visible"
                />
              ) : null}
            </a>
          );
        })}
      </div>
      {showInfoPage ? (
        <Infobox
          variants={showAnimationInfoBox}
          initial="hidden"
          animate="visible"
        >
          <a className="exit-button" onClick={() => setShowInfoPage(false)}>
            <RiCloseCircleFill size={24} color={"black"} />
          </a>
          {lastItemChanged?.image && (
            <div className="image-container">
              <img src={process.env.PUBLIC_URL + lastItemChanged?.image} />
            </div>
          )}
          <div className="text-container">{lastItemChanged?.description}</div>
        </Infobox>
      ) : null}
    </OpcionaisContainer>
  );
}
