const segmentoAtuacaoJSON = {
  segmentoAtuacao: [
    {
      clicked: false,
      nome: "Construção",
    },
    {
      clicked: false,
      nome: "Mineração",
    },
    {
      clicked: false,
      nome: "Agricultura",
    },
    {
      clicked: false,
      nome: "Indústria",
    },
    {
      clicked: false,
      nome: "Locação",
    },
    {
      clicked: false,
      nome: "Florestal",
    },
  ],
};

export default segmentoAtuacaoJSON;
