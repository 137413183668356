import React, { useState, useEffect, useLayoutEffect } from "react";

import {
  Container,
  ContentContainer,
  MapContainer,
  ConcessionariaItem,
  ShowInfoConc,
} from "./style";
import GoogleMap from "google-map-react";
import Marker from "../Marker";
import { FaInfoCircle } from "react-icons/fa";
import { AiOutlineCloseCircle } from "react-icons/ai";

import concessionariasJson from "../../../shared/utils/concessionarias.json";

const MapaConcessionariasMobile = () => {
  const [mapAPI, setMapAPI] = useState();
  const [showCardInfo, setShowCardInfo] = useState(false);
  const [cardInfo, setCardInfo] = useState({
    name: "",
    address: "",
    img: "",
    phone: "",
  });
  const [height, setHeight] = useState(0);
  const [concessionarias, setConsessionarias] = useState([
    {
      name: "concessionária 1",
      isSelected: true,
      img: "/assets/concessionarias/imageMock.png",
      position: {
        lat: -28.282231746307616,
        lng: -52.37656745927819,
      },
    },
  ]);
  const [defaultMapProps, setDefaultMapProps] = useState({
    center: {
      lat: -28.282231746307616,
      lng: -52.37656745927819,
    },
    zoom: 5,
  });

  useLayoutEffect(() => {
    setHeight(window.innerHeight);

    console.log("mobile");

    window.addEventListener("resize", () => {
      setHeight(window.innerHeight);
    });

    return () => {
      window.removeEventListener("resize", () => {
        setHeight(window.innerHeight);
      });
    };
  }, []);

  function showConcInfo(concessionaria, index) {
    setCardInfo(concessionaria);
    let c = [];
    const update = concessionarias.map((i, ind) => {
      if (index === ind) {
        // console.log(i);
        return { ...i, isSelected: !i.isSelected };
      } else {
        if (i.isSelected) {
          return { ...i, isSelected: false };
        } else {
          return i;
        }
      }
    });
    // console.log(concessionaria);
    centerMap({
      lat: concessionaria.position.lat,
      lng: concessionaria.position.lng,
    });
    setConsessionarias(update);
  }

  useEffect(() => {
    let conc = [];
    concessionariasJson.map((i) => {
      conc.push({
        ...i,
        isSelected: false,
        img: "/assets/concessionarias/imageMock.png",
      });
    });
    // console.log(conc);
    setConsessionarias(conc);
  }, []);

  useEffect(() => {
    let isShow = false;
    concessionarias.map((i) => {
      if (i.isSelected) {
        isShow = true;
      }
    });
    if (isShow) setShowCardInfo(true);
    else setShowCardInfo(false);
  }, [concessionarias]);

  const apiIsLoaded = (map, maps) => {
    const google = window.google;
    // console.log(map, maps);
    setMapAPI({ map: map, maps: maps, google: google });
    // setCanShowPreview(true);
  };

  function centerMap(position) {
    const { map } = mapAPI;
    map.panTo(position);
    map.setZoom(15);
  }

  return (
    <Container height={height}>
      <MapContainer>
        <GoogleMap
          bootstrapURLKeys={{
            key: "AIzaSyCMQQNhZJ9PLba-3BhKim7SA0LpmM3xOXw",
          }}
          defaultCenter={{ lat: -28.282231746307616, lng: -52.37656745927819 }}
          zoom={defaultMapProps.zoom}
          options={{ mapId: "eb9e95a991a7bb49", fullscreenControl: false }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
        >
          {concessionarias.map((i, index) => {
            return (
              <Marker
                lat={i.position.lat}
                lng={i.position.lng}
                text="My Marker"
                action={() => showConcInfo(i, index)}
              />
            );
          })}
        </GoogleMap>
      </MapContainer>
      {/* {showCardInfo ? (
        <ShowInfoConc>
          <img src={cardInfo.img} />
          <label className="title">{cardInfo.name}</label>
          <label className="text">{cardInfo.address}</label>
          <label className="text">Aberto das: 8:00 as 17:45</label>
          <label className="text">tracbel.com.br</label>
          <label className="text">{cardInfo.phone}</label>
          <div className="buttonsArea">
            <a className="share">Compartilhar</a>
            <a className="show-path">Me leve até lá</a>
          </div>
        </ShowInfoConc>
      ) : null} */}
      <ContentContainer>
        {/* <div className="header">concessionárias</div> */}
        {concessionarias.map((i, index) => {
          return (
            <ConcessionariaItem
              id={index}
              onClick={() => showConcInfo(i, index)}
            >
              <label className={`${i.isSelected ? "selected" : ""}`}>
                <label>{index + 1}. </label>
                {i.name}
              </label>
              <div className={`content ${i.isSelected ? "show" : ""}`}>
                <div className="text">
                  <label className="text">{i.address}</label>
                  <label className="text">Aberto das: 8:00 as 17:45</label>
                  <label className="text">tracbel.com.br</label>
                  <label className="text">{i.phone}</label>
                </div>
                <div className="actions"></div>
              </div>
            </ConcessionariaItem>
          );
        })}
      </ContentContainer>
    </Container>
  );
};

export default MapaConcessionariasMobile;
