import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: absolute;
  right: 300px;
  bottom: 40vh;
  width: 300px;
  height: 230px;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  border: 1px solid white;
  transition: all 0.3s ease;
  z-index: 9999;
  box-sizing: border-box;

  &.step-tutorial-1 {
    left: 450px;
  }

  &.step-tutorial-2 {
    left: calc(50vw - 170px);
    bottom: 100px;
  }

  &.step-tutorial-3 {
    right: 100px;
    bottom: 100px;
  }

  &.step-tutorial-4 {
    right: 300px;
    bottom: calc(70vh - 150px);
  }

  &.step-tutorial-5 {
    right: 300px;
    bottom: calc(70vh - 150px);
  }

  &.step-tutorial-6 {
    right: 300px;
    bottom: calc(70vh - 150px);
  }

  &.step-tutorial-7 {
    right: 300px;
    bottom: calc(70vh - 150px);
  }

  &.step-tutorial-8 {
    right: 300px;
    bottom: calc(70vh - 150px);
  }

  &.step-tutorial-9 {
    right: 300px;
    bottom: calc(30vh - 150px);
  }

  &.hide {
    display: none;
  }

  @media (max-width: 1400px) {
    &.step-tutorial-9 {
      right: 300px;
      bottom: calc(80vh - 150px);
    }
  }
`;

export const Text = styled.label`
  color: ${({ theme }) => theme.items.configurador.colors.primary};
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;

  &.thin {
    font-size: 0.9rem;
    color: white;
    font-weight: normal;
    margin-top: 10px;
  }
`;

export const ButtonsArea = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0px;
  /* background-color: cyan; */
  pointer-events: all;
`;

export const Button = styled.a`
  display: flex;
  padding: 6px 25px;
  color: black;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.items.configurador.colors.primary};
  transition: all 0.5s ease;
  margin: 10px 0px;

  &:nth-child(1) {
    margin-right: 10px;
  }

  &:hover {
    /* transform: scale(1.05); */
    cursor: pointer;
    filter: ${({ theme }) =>
      `drop-shadow(0 0 0.75rem ${theme.items.configurador.colors.primary})`};
  }
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  /* background-color: red; */

  &::before {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: ${({ theme }) =>
      theme.items.configurador.colors.greySteps};
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    width: ${({ step }) => `${(step - 1) * 12.5}%`};
    height: 3px;
    background-color: ${({ theme }) => theme.items.configurador.colors.primary};
  }

  .stepItem {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: ${({ theme }) =>
      theme.items.configurador.colors.greySteps};
    z-index: 1;

    &.active {
      background-color: ${({ theme }) =>
        theme.items.configurador.colors.primary};
      width: 18px;
      height: 18px;
    }
  }
`;
