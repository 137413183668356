import React, { useState, useEffect, useRef } from "react";
import { useUser } from "../../../../contexts/user";
import { useConfigurator } from "../../../../contexts/configurator";
import { useAuth } from "../../../../contexts/auth";
import {
  Container,
  Header,
  ContentArea,
  ContentButtons,
  ButtonPurpose,
  Steps,
  ConfigurationsArea,
  ModalPurpose,
} from "./styles";
import ConfigurationItem from "./ConfigurationItem";
import { InstructionText } from "./InstructionText";
import { InputText } from "./InputText";
import { InputTextMask } from "./InputTextMask";
import { InputCheckbox } from "./InputCheckbox";
import { Select } from "./Select";
import estadosCidadeJSON from "../../../../shared/chatbot/estados-cidades.js";
// import { setQuaternionFromProperEuler } from "three/src/math/mathutils";

const MailtoYou = ({ email, subject, body, ...props }) => {
  const sendError = () => {
    window.open(
      `mailto:${email}?subject=${subject || ""}&body=${body || ""}`,
      "_blank"
    );
  };
  return <div onClick={sendError}>{props.children}</div>;
};

export function ResumePage({ screenStep, setScreenStep }) {
  const { getConfigs } = useConfigurator();
  const [body, setBody] = useState(null);
  const [email, setEmail] = useState("comercial@tracbel.com.br");
  const [subject, setSubject] = useState("Report erro Configurador Tracbel");
  const [errorOn, setErrorOn] = useState(false);
  const alertRef = useRef(null);
  const { getToken, createLead } = useAuth();
  const { user, setUser } = useUser();
  const [step, setStep] = useState(3);
  const [modalPurpose, setModalPurpose] = useState(false);
  // const [email, setEmail] = useState("");
  // const [name, setName] = useState("");
  // const [cpf, setCpf] = useState("");
  const [maskTelefone, setMaskTelefone] = useState({
    mask: "(99)99999-9999",
  });
  const [maskCNPJ, setMaskCNPJ] = useState({
    mask: "99.999.999/9999-99",
  });
  const [maskCPF, setMaskCPF] = useState({
    mask: "999.999.999-99",
  });
  const [sendData, setSendData] = useState(null);
  const [data, setData] = useState({
    nome: user.nome,
    telefone: user.telefone,
    email: user.email,
    estado: user.estado,
    cpf: user.cpf,
    cnpj: user.cnpj,
    razaoSocial: user.razaoSocial,
    inscricaoEstadual: user.inscricaoEstadual,
    prazoEntrega: user.prazoEntrega,
    segmentoAtuacao: user.segmentoAtuacao,
    quantidadeMaquinas: user.quantidadeMaquinas,
    concordarReceberComunicacoes: user.concordarReceberComunicacoes,
    concordarArmazenarDados: user.concordarArmazenarDados,
    observacoes: `
      naturezaJuridica: ${user.naturezaJuridica}
: ${user.possueMaquinas},
      marcaEscavadeiras: ${user.marcaEscavadeiras},
      
      pesoOperacional: ${user.pesoOperacional},
    `,
    OrigemLead: user.OrigemLead,
    Midia: user.Midia,
    TipoContato: user.TipoContato,
    Produto: user.Produto,
  });
  const [sendIndexLength, setSendIndexLength] = useState(0);

  useEffect(() => {
    // console.log("updating user");
    setData((values) => ({
      ...values,
      nome: user.nome !== null ? user.nome : "",
      telefone:
        user.telefone !== null
          ? user.telefone
              .replace(/\(/g, "")
              .replace(/\)/g, "")
              .replace(/\-/g, "")
          : "",
      email: user.email !== null ? user.email : "",
      estado: user.estado !== null ? user.estado : "",
      cpf:
        user.cpf !== null ? user.cpf.replace(/\./g, "").replace(/\-/g, "") : "",
      cnpj:
        user.cnpj !== null
          ? user.cnpj
              .replace(/\./g, "")
              .replace(/\-/g, "")
              .replace(/\//g, "")
          : "",
      razaoSocial: user.razaoSocial !== null ? user.razaoSocial : "",
      inscricaoEstadual:
        user.inscricaoEstadual !== null
          ? user.inscricaoEstadual
              .replace(/\./g, "")
              .replace(/\-/g, "")
              .replace(/\//g, "")
          : "",
      prazoEntrega: user.prazoEntrega !== null ? user.prazoEntrega : "",
      segmentoAtuacao:
        user.segmentoAtuacao !== null ? user.segmentoAtuacao : "",
      quantidadeMaquinas:
        user.quantidadeMaquinas !== null ? user.quantidadeMaquinas : "",
      concordarReceberComunicacoes:
        user.concordarReceberComunicacoes !== null
          ? user.concordarReceberComunicacoes
          : false,
      concordarArmazenarDados:
        user.concordarArmazenarDados !== null
          ? user.concordarArmazenarDados
          : false,
      observacoes: `naturezaJuridica: ${
        user.naturezaJuridica !== null ? user.naturezaJuridica : ""
      }
possueMaquinas: ${user.possueMaquinas !== null ? user.possueMaquinas : ""},
marcaEscavadeiras: ${
        user.marcaEscavadeiras !== null ? user.marcaEscavadeiras : ""
      },
pesoOperacional: ${user.pesoOperacional !== null ? user.pesoOperacional : ""},
    `,
      configuracao: "",
      proprietario: "",
      OrigemLead: user.OrigemLead !== null ? user.OrigemLead : "",
      Midia: user.Midia !== null ? user.Midia : "",
      TipoContato: user.TipoContato !== null ? user.TipoContato : "",
      Produto: user.Produto !== null ? user.Produto : "",
    }));
  }, [user]);

  useEffect(() => {
    // console.log(data);
  }, [data]);

  useEffect(() => {
    if (screenStep === 4) {
      confirmPurpose();
    }
  }, [screenStep]);

  const confirmPurpose = () => {
    // console.log(currentConfig());
    setModalPurpose(true);
  };

  const backPurpose = () => {
    setModalPurpose(false);
  };

  const updateUser = (userField, value) => {
    setUser((prevUser) => ({
      ...prevUser,
      [userField]: value,
    }));
  };

  const updateUserTelefone = (userField, value) => {
    let isMobile =
      value.substring(4, 5) !== undefined && value.substring(4, 5) === "9"
        ? true
        : false;

    var newState = {
      mask: isMobile ? "(99)99999-9999" : "(99)9999-9999",
    };

    setMaskTelefone(newState);

    setUser((prevUser) => ({
      ...prevUser,
      [userField]: value,
    }));
  };

  const validateNome = (nome) => {
    if (nome.trim() !== "" && nome.length > 2) {
      return true;
    } else {
      return false;
    }
  };

  const validateTelefone = (tel) => {
    if (tel !== "" && tel !== null && tel.length >= 10) {
      var regExp = /[_]/g;
      return !regExp.test(tel);
    } else {
      return false;
    }
  };

  const validateEmail = (mail) => {
    if (mail !== "") {
      const mailformat = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
      if (mail.match(mailformat)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const validateConcordarArmazenarDados = (concordar) => {
    return concordar;
  };

  const validateCPF = (cpf) => {
    if (cpf !== null) {
      cpf = cpf.replace(/[^\d]+/g, "");
      if (cpf == "") return false;
      // Elimina CPFs invalidos conhecidos
      if (
        cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999"
      )
        return false;
      // Valida 1o digito
      let add = 0;
      for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
      let rev = 11 - (add % 11);
      if (rev == 10 || rev == 11) rev = 0;
      if (rev != parseInt(cpf.charAt(9))) return false;
      // Valida 2o digito
      add = 0;
      for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
      rev = 11 - (add % 11);
      if (rev == 10 || rev == 11) rev = 0;
      if (rev != parseInt(cpf.charAt(10))) return false;
      return true;
    } else {
      return false;
    }
  };

  const validateCNPJ = (cnpj) => {
    if (cnpj === null) {
      return false;
    }
    cnpj = cnpj
      .replace(/\./g, "")
      .replace(/\-/g, "")
      .replace(/\//g, "");

    if (cnpj == "") return false;

    if (cnpj.length != 14) return false;

    // Elimina CNPJs invalidos conhecidos
    // console.log(cnpj);
    if (
      cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999"
    )
      return false;

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) return false;

    return true;
    // if (value !== null) {
    //   if (value !== "") {
    //     var regExp = /[_]/g;
    //     return !regExp.test(value);
    //   } else {
    //     return false;
    //   }
    // } else {
    //   return false;
    // }
  };

  const formAlert = (value) => {
    alertRef.current.parentElement.parentElement.parentElement.parentElement.scrollTop = 0;
    alertRef.current.innerHTML = value;
  };

  async function sendSF() {
    let configs = getConfigs();
    let tokenResult = await getToken();
    const { token, url, token_type } = JSON.parse(
      localStorage.getItem("@dataStorage")
    );
    // console.log(tokenResult, token, url, token_type);
    let sendIndex = 0;
    let indexLength = configs.length;
    for (let conf of configs) {
      // console.log(conf);
      let configuracaoArr = conf.config;
      let configuracaoList = `Modelo: ${
        conf.model !== null ? conf.model : "-"
      } | id: ${conf.productID} | pacote: ${conf.package} 
`;
      configuracaoArr.forEach((element) => {
        configuracaoList += `${element.name}: `;
        element.selected.length > 0
          ? element.selected.forEach((element2) => {
              configuracaoList += `id: ${element2.salesforce_id.map(
                (element3) => {
                  return element3;
                }
              )} item: ${element2.name}
`;
            })
          : (configuracaoList += `-
`);
      });

      const clone = require("rfdc")();
      let dataTemp = clone(data);
      dataTemp.configuracao = configuracaoList;
      dataTemp.Produto = conf.productID;
      let leadResult = await createLead(dataTemp, token);
      if (leadResult.status === 200 && sendIndex === indexLength - 1) {
        let index = sendIndex + 1;
        formAlert(
          "Solicitação enviada! Nosso time entrará em contato com você o mais breve possível!"
        );
        setErrorOn(false);
        // console.log("Enviado configuração " + index);
        // console.log(
        //   "Solicitação enviada! Nosso time entrará em contato com você o mais breve possível!"
        // );
      } else if (leadResult.status === 200 && sendIndex < indexLength - 1) {
        let index = sendIndex + 1;
        formAlert("Enviada configuração " + index + " de " + indexLength);
        // console.log("Enviada configuração " + index);
        setErrorOn(false);
      } else {
        let words = encodeURIComponent(leadResult.data[0].message);
        setBody(words.substring(25, 1500));
        formAlert(
          `Problema ao enviar a solicitação! Tente novamente. Erro: ${leadResult.status}.`
        );
        setErrorOn(true);
        console.log(leadResult);
      }

      sendIndex++;
    }
  }

  const submit = () => {
    formAlert("");
    setErrorOn(false);
    if (
      user.nome !== null &&
      user.email !== null &&
      user.estado !== null &&
      user.concordarArmazenarDados !== null
    ) {
      if (!validateNome(user.nome)) {
        formAlert("Preencha seu nome");
      } else {
        if (!validateTelefone(user.telefone)) {
          formAlert("Preencha seu telefone ou celular");
        } else {
          if (!validateEmail(user.email)) {
            formAlert("Preencha seu e-mail corretamente");
          } else {
            if (
              user.cpf !== null &&
              user.cpf !== "" &&
              !validateCPF(user.cpf)
            ) {
              formAlert("Preencha seu CPF ou CPNJ corretamente");
            } else {
              if (
                user.cnpj !== null &&
                user.cnpj !== "" &&
                !validateCNPJ(user.cnpj)
              ) {
                formAlert("Preencha seu CPF ou CPNJ corretamente");
              } else {
                if (
                  !validateConcordarArmazenarDados(user.concordarArmazenarDados)
                ) {
                  formAlert(
                    "É necessário estar de acordo com nossa política de privacidade."
                  );
                } else {
                  formAlert("Enviando solicitação de proposta...");
                  sendSF();
                }
              }
            }
          }
        }
      }
    } else {
      formAlert("Preencha os campos obrigatórios");
      setErrorOn(false);
      // console.log(alertRef.current);
    }
  };

  return (
    <Container>
      <Header>
        <label>Versões configuradas</label>
      </Header>
      <ContentArea>
        <ContentButtons>
          <ButtonPurpose className="voltar" onClick={() => setScreenStep(2)}>
            Voltar
          </ButtonPurpose>
          {/* <ButtonPurpose className="orcamento" onClick={confirmPurpose}>
            Solicitar Orçamento
          </ButtonPurpose> */}
        </ContentButtons>
        <ConfigurationsArea>
          {getConfigs().map((conf, index) => (
            <ConfigurationItem config={conf} index={index} key={index} />
          ))}
        </ConfigurationsArea>
        <Steps>
          <div
            className={`step-item ${screenStep === 1 ? "on" : ""}`}
            onClick={() => setScreenStep(1)}
          >
            {screenStep === 1 ? "1. Config" : "1. Config"}
          </div>
          <div
            className={`step-item ${screenStep === 2 ? "on" : ""}`}
            onClick={() => setScreenStep(2)}
          >
            {screenStep === 2 ? "2. Opcionais" : "2. Opcionais"}
          </div>
          <div
            className={`step-item ${screenStep === 3 ? "on" : ""}`}
            onClick={() => setScreenStep(3)}
          >
            {screenStep === 3 ? "3. Resumo" : "3. Resumo"}
          </div>
          <div
            className={`step-item ${screenStep === 4 ? "on" : ""}`}
            onClick={() => {
              setScreenStep(4);
            }}
          >
            {screenStep === 4
              ? "4. Solicitar Orçamento"
              : "4. Solicitar Orçamento"}
          </div>
        </Steps>
      </ContentArea>
      {modalPurpose ? (
        <ModalPurpose>
          <div className="purpose-area">
            <div
              className="backdrop"
              onClick={() => {
                backPurpose();
                setScreenStep(3);
              }}
            ></div>
            <div className="content">
              <div className="row">
                <InstructionText ref={alertRef} classe="alert" width="100%" />
                {errorOn && (
                  <MailtoYou email={email} subject={subject} body={body}>
                    Clique aqui
                    <span style={{ color: "#FFB800" }}>
                      {" "}
                      para reportar o erro.
                    </span>
                  </MailtoYou>
                )}
              </div>
              <div className="row">
                <InputText
                  title={"Nome*"}
                  text={data.nome}
                  updateUser={updateUser}
                  param="nome"
                />
              </div>
              <div className="row">
                <InputTextMask
                  title={"Telefone*"}
                  text={data.telefone}
                  mask={maskTelefone.mask}
                  updateUser={updateUserTelefone}
                  param="telefone"
                />
                <InputText
                  title={"E-mail*"}
                  text={data.email}
                  updateUser={updateUser}
                  param="email"
                />
              </div>
              <div className="row">
                <Select
                  title={"UF*"}
                  width={35}
                  value={data.estado}
                  updateUser={updateUser}
                  param="estado"
                  placeholder="Seu estado..."
                  lista={estadosCidadeJSON}
                />
                <InputTextMask
                  title={"CPF**"}
                  width={35}
                  mask={maskCPF.mask}
                  text={data.cpf}
                  updateUser={updateUser}
                  param="cpf"
                />
                <InputTextMask
                  title={"CNPJ**"}
                  width={35}
                  mask={maskCNPJ.mask}
                  text={data.cnpj}
                  updateUser={updateUser}
                  param="cnpj"
                />
              </div>
              <div className="row">
                <InputText
                  title={"Razão Social"}
                  text={data.razaoSocial}
                  updateUser={updateUser}
                  param="razaoSocial"
                />
                <InputText
                  title={"Inscrição Estadual"}
                  text={data.inscricaoEstadual}
                  updateUser={updateUser}
                  param="inscricaoEstadual"
                  maxLength={14}
                />
              </div>
              <div className="row">
                <InputCheckbox
                  title={
                    'Forneço o meu consentimento, pois concordo em receber comunicações da Tracbel no meu e-mail e telefone informados acima com informações sobre produtos e serviços da Tracbel que possam ser do meu interesse, de acordo com a <a target="_blank" href="https://configurador.tracbel.com.br/politicadeprivacidade.html">Política de Privacidade Tracbel S/A.</a> Estou ciente de que o consentimento fornecido para essa finalidade poderá ser revogado posteriormente, caso eu assim deseje, mediante envio de requisição.'
                  }
                  value={data.concordarReceberComunicacoes}
                  updateUser={updateUser}
                  param="concordarReceberComunicacoes"
                />
              </div>
              <div className="row">
                <InputCheckbox
                  title={
                    'Estou ciente de que a Tracbel armazenará os meus dados fornecidos acima para a execução de contrato ou de procedimentos preliminares relacionados a contrato do qual eu sou/serei parte, de acordo com a <a target="_blank" href="https://configurador.tracbel.com.br/politicadeprivacidade.html" >Política de Privacidade Tracbel S/A.</a> *'
                  }
                  value={data.concordarArmazenarDados}
                  updateUser={updateUser}
                  param="concordarArmazenarDados"
                />
              </div>
              <div className="row">
                <InstructionText title={"* Campos obrigatórios"} width="100%" />
              </div>
              <div className="row">
                <InstructionText
                  title={"** Um dos campos obrigatório. CPF ou CNPJ"}
                  width="100%"
                />
              </div>
              <div className="rest">
                <div>
                  <ButtonPurpose
                    onClick={() => {
                      backPurpose();
                      setScreenStep(3);
                    }}
                  >
                    Voltar
                  </ButtonPurpose>
                </div>
                <div>
                  <ButtonPurpose onClick={submit}>Enviar</ButtonPurpose>
                </div>
              </div>
            </div>
          </div>
        </ModalPurpose>
      ) : null}
    </Container>
  );
}
