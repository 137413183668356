const quantidadeMaquinasJSON = {
  quantidadeMaquinas: [
    {
      clicked: false,
      nome: "1-5 Máquinas",
    },
    {
      clicked: false,
      nome: "6-10 Máquinas",
    },
    {
      clicked: false,
      nome: "11-20 Máquinas",
    },
    {
      clicked: false,
      nome: "21-50 Máquinas",
    },
    {
      clicked: false,
      nome: "> 50 Máquinas",
    },
  ],
};

export default quantidadeMaquinasJSON;
