import React from "react";

import { Container } from "./styles";
import svg from "../../../shared/assets/svg/marker.svg";

export default function Marker({ action }) {
  return (
    <Container onClick={() => action()}>
      <img src={svg} />
    </Container>
  );
}
