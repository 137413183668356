import React, { useState, useEffect } from "react";

import { ModelTextBackground, Description } from "./styles";

import Keyboard from "./Keyboard";
import { RightNavbar } from "./RightNavbar";
import { BottomNavbar } from "./BottomNavbar";
import { ResumePage } from "./ResumePage";
import { ConfiguredVersion } from "./ConfiguredVersion";
import { PopUp } from "./PopUp";
import Config3d from "./Config3d";
import { useConfigurator } from "../../../contexts/configurator";
import { useAlert } from "../../../contexts/alert";
import { useModal } from "../../../contexts/modal";
const ConfiguradorDesktop = () => {
  const { alert, setAlert } = useAlert();
  const { modal, setModal } = useModal();
  const { config, firstEntry, setFirstEntry } = useConfigurator();
  const [instructionOff, setInstructionOff] = useState(true);
  const [screenStep, setScreenStep] = useState(1);
  const [isOnMenu, setIsOnMenu] = useState(false);
  const [isOnKeyboard, setIsOnKeyboard] = useState(false);
  const [pressedKey, setPressedKey] = useState("");
  const [reseted, setReseted] = useState(true);
  const [isOnRadius, setIsOnRadius] = useState(false);
  const [isOnBulldozer, setIsOnBulldozer] = useState(false);
  const [isOnPackages, setIsOnPackages] = useState(false);
  // condição para ir para a barra proposta
  const [showPurpose, setShowPurpose] = useState(false);
  const [firstView, setFirstView] = useState(false);
  const [showInfoContainer, setShowInfoContainer] = useState(true);
  const [tutorialStep, setTutorialStep] = useState(10);
  const [escavadeiraRotate, setEscavadeiraRotate] = useState(null);
  const [timeScale, setTimeScale] = useState(0);

  // configuração inicial //
  // const skipFirstConfiguration = () => {
  //   // seta o package standard no contexto
  //   setFirstView(false);
  // };

  // const confirmFirstConfiguration = () => {
  //   // seta o package definido no contexto
  //   setFirstView(false);
  // };

  useEffect(() => {
    if (firstEntry) {
      setFirstView(true);
      setTutorialStep(9);
      // console.log("firstEntry", firstEntry);
    }
  }, []);

  const resetFirstConfiguration = () => {
    setInstructionOff(false);
    setFirstView(false);
    setFirstEntry(false);
    setTutorialStep(10);
  };

  const skipTutorial = () => {
    setTutorialStep(9);
  };

  useEffect(() => {
    // console.log(screenStep);
    if (firstEntry) setReseted(false);
    else {
      setReseted(true);
      if (screenStep === 1) {
        setIsOnMenu(true);
        if (isOnKeyboard) {
          setReseted(false);
          setIsOnKeyboard(false);
        }
        setIsOnRadius(false);
        setIsOnBulldozer(false);
        setIsOnPackages(false);
        setShowPurpose(false);
      } else if (screenStep === 2) {
        setIsOnMenu(true);
        if (isOnKeyboard) {
          setReseted(false);
          setIsOnKeyboard(false);
        }
        setIsOnRadius(false);
        setIsOnBulldozer(false);
        setIsOnPackages(false);
        setShowPurpose(false);
      } else if (screenStep === 3) {
        setIsOnMenu(true);
        if (isOnKeyboard) {
          setReseted(false);
          setIsOnKeyboard(false);
        }
        setIsOnRadius(false);
        setIsOnBulldozer(false);
        setIsOnPackages(false);
        setShowPurpose(true);
      } else if (screenStep === 4) {
        setIsOnMenu(true);
        if (isOnKeyboard) {
          setReseted(false);
          setIsOnKeyboard(false);
        }
        setIsOnRadius(false);
        setIsOnBulldozer(false);
        setIsOnPackages(false);
        setShowPurpose(true);
      }
    }
  }, [firstEntry, screenStep]);

  return (
    <>
      <ModelTextBackground>{config.name ?? ""}</ModelTextBackground>
      <Description>Imagens Ilustrativas</Description>
      <Config3d
        instructionOff={instructionOff}
        setInstructionOff={setInstructionOff}
        firstView={firstView}
        isOnMenu={isOnMenu}
        setIsOnMenu={setIsOnMenu}
        isOnKeyboard={isOnKeyboard}
        setIsOnKeyboard={setIsOnKeyboard}
        reseted={reseted}
        setReseted={setReseted}
        pressedKey={pressedKey}
        setPressedKey={setPressedKey}
        isOnRadius={isOnRadius}
        setIsOnRadius={setIsOnRadius}
        screenStep={screenStep}
        escavadeiraRotate={escavadeiraRotate}
        setEscavadeiraRotate={setEscavadeiraRotate}
        timeScale={timeScale}
        setTimeScale={setTimeScale}
      />
      <Keyboard
        alert={alert}
        setAlert={setAlert}
        isOnKeyboard={isOnKeyboard}
        setIsOnKeyboard={setIsOnKeyboard}
        reseted={reseted}
        setReseted={setReseted}
        setPressedKey={setPressedKey}
        pressedKey={pressedKey}
        setEscavadeiraRotate={setEscavadeiraRotate}
        setTimeScale={setTimeScale}
      />
      <RightNavbar
        alert={alert}
        setAlert={setAlert}
        setModal={setModal}
        isOnMenu={isOnMenu}
        setIsOnMenu={setIsOnMenu}
        isOnKeyboard={isOnKeyboard}
        setIsOnKeyboard={setIsOnKeyboard}
        reseted={reseted}
        setReseted={setReseted}
        isOnRadius={isOnRadius}
        setIsOnRadius={setIsOnRadius}
        firstView={firstView}
        firstEntry={firstEntry}
        showInfoContainer={showInfoContainer}
        setShowInfoContainer={setShowInfoContainer}
        isOnBulldozer={isOnBulldozer}
        setIsOnBulldozer={setIsOnBulldozer}
        isOnPackages={isOnPackages}
        setIsOnPackages={setIsOnPackages}
        screenStep={screenStep}
        resetFirstConfiguration={resetFirstConfiguration}
        tutorialStep={tutorialStep}
      />
      <ConfiguredVersion tutorialStep={tutorialStep} />
      <BottomNavbar
        alert={alert}
        setAlert={setAlert}
        firstEntry={firstEntry}
        firstView={firstView}
        showInfoContainer={showInfoContainer}
        setShowInfoContainer={setShowInfoContainer}
        screenStep={screenStep}
        setScreenStep={setScreenStep}
        tutorialStep={tutorialStep}
      />
      {showPurpose ? (
        <ResumePage screenStep={screenStep} setScreenStep={setScreenStep} />
      ) : null}
      {firstView ? (
        <PopUp
          // skip={resetFirstConfiguration}
          setTutorialStep={setTutorialStep}
          tutorialStep={tutorialStep}
          skipTutorial={skipTutorial}
        />
      ) : null}
    </>
  );
};

export default ConfiguradorDesktop;
