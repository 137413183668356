import React, { useEffect, useState } from "react";

import { Container, BlockActions } from "./styles";

import standardImg from "../../../../shared/assets/packagesImages/standard.png";
import construckImg from "../../../../shared/assets/packagesImages/construção.png";
import agricultureImg from "../../../../shared/assets/packagesImages/agricultura.png";
import industryImg from "../../../../shared/assets/packagesImages/industria.png";
import rentingImg from "../../../../shared/assets/packagesImages/locação.png";
import miningImg from "../../../../shared/assets/packagesImages/mineração.png";

// import img from '../../../../shared/assets/packagesImages/'
import bulldozerImg from "../../../../shared/assets/svg/bulldozerMenu.svg";
import radiusImg from "../../../../shared/assets/svg/radiusMenu.svg";
import { useConfigurator } from "../../../../contexts/configurator";
import { RiEyeFill } from "react-icons/ri";
import { FaGamepad } from "react-icons/fa";
import { FiPackage } from "react-icons/fi";
import { BiRadioCircle } from "react-icons/bi";
import { BsCircle } from "react-icons/bs";
export const RightNavbar = React.memo(
  ({
    alert,
    setAlert,
    setModal,
    isOnMenu,
    setIsOnMenu,
    isOnKeyboard,
    setIsOnKeyboard,
    reseted,
    setReseted,
    isOnRadius,
    setIsOnRadius,
    firstView,
    isOnBulldozer,
    setIsOnBulldozer,
    isOnPackages,
    setIsOnPackages,
    screenStep,
    resetFirstConfiguration,
    showInfoContainer,
    setShowInfoContainer,
    tutorialStep,
  }) => {
    return (
      <Container>
        <ShowHideMenuButton
          alert={alert}
          setAlert={setAlert}
          isOnMenu={isOnMenu}
          setIsOnMenu={setIsOnMenu}
          setIsOnKeyboard={setIsOnKeyboard}
          reseted={reseted}
          setReseted={setReseted}
          screenStep={screenStep}
          setIsOnBulldozer={setIsOnBulldozer}
          setIsOnPackages={setIsOnPackages}
          tutorialStep={tutorialStep}
        />
        <ShowRadiusButton
          alert={alert}
          setAlert={setAlert}
          isOnRadius={isOnRadius}
          setIsOnRadius={setIsOnRadius}
          screenStep={screenStep}
          setIsOnBulldozer={setIsOnBulldozer}
          setIsOnPackages={setIsOnPackages}
          tutorialStep={tutorialStep}
        />
        <ShowControlsButton
          alert={alert}
          setAlert={setAlert}
          isOnKeyboard={isOnKeyboard}
          setIsOnKeyboard={setIsOnKeyboard}
          setIsOnMenu={setIsOnMenu}
          setReseted={setReseted}
          screenStep={screenStep}
          showInfoContainer={showInfoContainer}
          setShowInfoContainer={setShowInfoContainer}
          setIsOnBulldozer={setIsOnBulldozer}
          setIsOnPackages={setIsOnPackages}
          tutorialStep={tutorialStep}
        />
        <ShowBulldozerSelector
          alert={alert}
          setAlert={setAlert}
          setModal={setModal}
          isOnBulldozer={isOnBulldozer}
          setIsOnBulldozer={setIsOnBulldozer}
          setIsOnPackages={setIsOnPackages}
          screenStep={screenStep}
          tutorialStep={tutorialStep}
        />
        {firstView && tutorialStep === 9 ? <BlockActions /> : null}
        <ShowPackages
          alert={alert}
          setAlert={setAlert}
          show={firstView && tutorialStep === 9 ? true : false}
          isOnPackages={isOnPackages}
          setIsOnPackages={setIsOnPackages}
          setIsOnBulldozer={setIsOnBulldozer}
          screenStep={screenStep}
          resetFirstConfiguration={resetFirstConfiguration}
          tutorialStep={tutorialStep}
        />
      </Container>
    );
  }
);

const ShowHideMenuButton = ({
  alert,
  setAlert,
  isOnMenu,
  setIsOnMenu,
  setIsOnKeyboard,
  reseted,
  setReseted,
  setIsOnBulldozer,
  setIsOnPackages,
  tutorialStep,
}) => {
  const [classOn, setClassOn] = useState("");

  useEffect(() => {
    if (isOnMenu) {
      setIsOnKeyboard(false);
      if (reseted) {
        setClassOn("on");
      }
    } else {
      setClassOn("");
    }
  }, [isOnMenu, reseted]);

  return (
    <div className="right-menu-wrapper">
      <label className="label">
        {" "}
        {isOnMenu === true && tutorialStep !== 5 && tutorialStep !== 4
          ? "Off"
          : "On"}{" "}
        Menus
      </label>
      <a
        className={`show-hide-menu ${classOn}`}
        onClick={() => {
          setIsOnMenu(!isOnMenu);
          setIsOnBulldozer(false);
          setIsOnPackages(false);
        }}
      >
        <span
          className={`tutorial-highlight ${
            tutorialStep === 5 ? "highlighted" : ""
          } 
           ${tutorialStep === 4 ? "highlighted-top" : ""}`}
        ></span>
        <RiEyeFill className="inner" />
        <span className="custom-border">
          <div className="row">
            <span></span>
            <span></span>
          </div>
          <div className="row">
            <span></span>
            <span></span>
          </div>
        </span>
      </a>
    </div>
  );
};

const ShowRadiusButton = ({
  alert,
  setAlert,
  isOnRadius,
  setIsOnRadius,
  setIsOnBulldozer,
  setIsOnPackages,
  tutorialStep,
}) => {
  const [classOn, setClassOn] = useState("");

  const toggleClass = () => {
    setIsOnRadius(!isOnRadius);
    setIsOnBulldozer(false);
    setIsOnPackages(false);
    if (!isOnRadius) {
      setAlert({
        show: true,
        time: 5000,
        message: "Este é o raio de giro do contrapeso de sua escavadeira",
      });
    }
  };

  useEffect(() => {
    if (isOnRadius) setClassOn("on");
    else setClassOn("");
  }, [isOnRadius]);

  return (
    <div className="right-menu-wrapper second">
      <label className="label">{isOnRadius ? "Off" : "On"} Raio</label>
      <a className={`show-hide-radius ${classOn}`} onClick={toggleClass}>
        <span
          className={`tutorial-highlight ${
            tutorialStep === 6 ? "highlighted" : ""
          }`}
        ></span>
        {/* <img src={radiusImg}/>
            <img src={radiusImg}/> */}
        <BsCircle className="ico" />
        <BsCircle className="ico" />
      </a>
    </div>
  );
};

const ShowControlsButton = ({
  alert,
  setAlert,
  isOnKeyboard,
  setIsOnKeyboard,
  setIsOnMenu,
  setReseted,
  showInfoContainer,
  setShowInfoContainer,
  setIsOnBulldozer,
  setIsOnPackages,
  tutorialStep,
}) => {
  const [classOn, setClassOn] = useState("");

  const toggleClass = () => {
    setIsOnKeyboard(!isOnKeyboard);
    setShowInfoContainer(false);
    setIsOnBulldozer(false);
    setIsOnPackages(false);
  };

  useEffect(() => {
    if (isOnKeyboard) {
      setIsOnMenu(false);
      setClassOn("on");
      setReseted(false);
      setAlert({
        show: true,
        time: 5000,
        message:
          "Utilize o teclado ou clique nas letras para controlar a escavadeira",
      });
    } else {
      setClassOn("");
    }
  }, [isOnKeyboard]);

  return (
    <div className="right-menu-wrapper third">
      <label className="label">Controles</label>
      <a className={`show-hide-controls ${classOn}`} onClick={toggleClass}>
        <span
          className={`tutorial-highlight ${
            tutorialStep === 7 ? "highlighted" : ""
          }`}
        ></span>
        <FaGamepad className="inner" />
      </a>
    </div>
  );
};

const ShowBulldozerSelector = ({
  alert,
  setAlert,
  setModal,
  isOnBulldozer,
  setIsOnBulldozer,
  setIsOnPackages,
  screenStep,
  tutorialStep,
}) => {
  const [classOn, setClassOn] = useState("");
  const { changeModel, getConfigs } = useConfigurator();
  const toggleClass = () => {
    setIsOnBulldozer(!isOnBulldozer);
    if (!isOnBulldozer) {
      setAlert({
        show: true,
        time: 5000,
        message: "Estes são os modelos disponíveis para você escolher",
      });
    }
  };

  useEffect(() => {
    if (isOnBulldozer) {
      setClassOn("on");
      setIsOnPackages(false);
    } else {
      setClassOn("");
    }
  }, [isOnBulldozer]);

  const cancel = () => {};

  return (
    <div className={`bulldozer-container ${classOn}`}>
      <section className="bulldozer-list ">
        <a
          className="bulldozer-item-list"
          onClick={() => {
            setIsOnBulldozer(false);
            if (getConfigs().length >= 3) {
              setModal({
                show: true,
                time: 5000,
                message: `Você atingiu o limite de 3 versões configuradas.
Exclua pelo menos 1 versão configurada para adicionar outra.`,
                callback: null,
              });
            } else {
              setModal({
                show: true,
                time: 5000,
                message: `Sua configuração atual será gravada na lista de versões configuradas.
Criaremos uma nova configuração para o modelo selecionado.
Deseja continuar?`,
                callback: () => {
                  changeModel("EC140DL");
                },
              });
            }
          }}
        >
          <img src={bulldozerImg} />
          <label>EC140DL</label>
        </a>
        <a
          className="bulldozer-item-list"
          onClick={() => {
            setIsOnBulldozer(false);
            if (getConfigs().length >= 3) {
              setModal({
                show: true,
                time: 5000,
                message: `Você atingiu o limite de 3 versões configuradas.
Exclua pelo menos 1 versão configurada para adicionar outra.`,
                callback: null,
              });
            } else {
              setModal({
                show: true,
                time: 5000,
                message: `Sua configuração atual será gravada na lista de versões configuradas.
Criaremos uma nova configuração para o modelo selecionado.
Deseja continuar?`,
                callback: () => {
                  changeModel("EC140DLM");
                },
              });
            }
          }}
        >
          <img src={bulldozerImg} />
          <label>EC140DLM</label>
        </a>
      </section>
      <div className="right-menu-wrapper fourth">
        <label className="label">Modelo</label>
        <a className="bulldozer-selected " onClick={toggleClass}>
          <span
            className={`tutorial-highlight ${
              tutorialStep === 8 ? "highlighted" : ""
            }`}
          ></span>
          <img src={bulldozerImg} />
        </a>
      </div>
    </div>
  );
};

const ShowPackages = ({
  alert,
  setAlert,
  show,
  isOnPackages,
  setIsOnPackages,
  setIsOnBulldozer,
  screenStep,
  resetFirstConfiguration,
  tutorialStep,
}) => {
  const [classOn, setClassOn] = useState("");
  const [packageSelected, setPackageSelected] = useState("");
  const { changePackage } = useConfigurator();
  const toggleClass = () => {
    setIsOnPackages(!isOnPackages);
    if (!isOnPackages) {
      setAlert({
        show: true,
        time: 5000,
        message:
          "Você pode recomeçar, escolhendo uma aplicação base para configurar sua escavadeira",
      });
    }
  };

  useEffect(() => {
    if (isOnPackages) {
      setClassOn("on");
      setIsOnBulldozer(false);
    } else if (show) {
      setClassOn("on");
      setIsOnBulldozer(false);
    } else {
      if (packageSelected === "") setPackageSelected("standard");
      setClassOn("");
    }
  }, [isOnPackages, show]);

  function handleSelectedPackage(p) {
    changePackage(p);
    let firstConfigurationFlag = 0;
    if (packageSelected === "standard" && tutorialStep === 9) {
      firstConfigurationFlag = 1;
    }
    setPackageSelected(p);
    if (firstConfigurationFlag === 1) resetFirstConfiguration();
    toggleClass();
    setAlert({
      show: true,
      time: 5000,
      message:
        "Aplicação escolhida. Agora você pode configurar os items básicos da escavadeira",
    });
    // console.log("firstConfigurationFlag", firstConfigurationFlag);
  }

  return (
    <>
      <div className={`right-menu-wrapper five ${classOn}`}>
        <section className={`packages-container ${classOn}`}>
          <a className={`show-hide-packages ${classOn}`} onClick={toggleClass}>
            <span
              className={`tutorial-highlight ${
                tutorialStep === 9 ? "highlighted" : ""
              }`}
            ></span>
            <FiPackage className="inner" />
            <span className="custom-border">
              <div className="row">
                <span></span>
                <span></span>
              </div>
              <div className="row">
                <span></span>
                <span></span>
              </div>
            </span>
          </a>
          <section className={`packages-list ${classOn}`}>
            <div
              className={`package-item ${
                packageSelected == "locação" ? "selected" : ""
              }`}
              onClick={() => handleSelectedPackage("locação")}
            >
              <img src={rentingImg} />
              <label>Locação</label>
            </div>
            <div
              className={`package-item ${
                packageSelected == "indústria" ? "selected" : ""
              }`}
              onClick={() => handleSelectedPackage("indústria")}
            >
              <img src={industryImg} />
              <label>Indústria</label>
              <label className="thin">• Sucata</label>
            </div>
            <div
              className={`package-item ${
                packageSelected == "construção" ? "selected" : ""
              }`}
              onClick={() => handleSelectedPackage("construção")}
            >
              <img src={construckImg} />
              <label>Construção</label>
              <label className="thin">• Demolição</label>
            </div>
            <div
              className={`package-item ${
                packageSelected == "agricultura" ? "selected" : ""
              }`}
              onClick={() => handleSelectedPackage("agricultura")}
            >
              <img src={agricultureImg} />
              <label>Agricultura</label>
              <label className="thin">• Agronegócio</label>
            </div>
            <div
              className={`package-item ${
                packageSelected == "mineração" ? "selected" : ""
              }`}
              onClick={() => handleSelectedPackage("mineração")}
            >
              <img src={miningImg} />
              <label>Mineração</label>
            </div>
            <div
              className={`package-item ${
                packageSelected == "standard" ? "selected" : ""
              }`}
              onClick={() => handleSelectedPackage("standard")}
            >
              <img src={standardImg} />
              <label>Standard</label>
            </div>
          </section>
        </section>
        <label className="label">Aplicações</label>
      </div>
    </>
  );
};
