import axios from "axios";

const apiGetToken = axios.create({
  baseURL: "https://tracbel.my.salesforce.com",
});

const apiCreateLead = axios.create({
  baseURL: "https://tracbel.my.salesforce.com",
});

export async function authSL() {
  const username = process.env.REACT_APP_SL_USER;
  const password = process.env.REACT_APP_SL_PASS;
  const clientID = process.env.REACT_APP_SL_CLIENT_ID;
  const clientSecret = process.env.REACT_APP_SL_CLIENT_SECRET;

  console.log(username, password, clientID, clientSecret);

  const querystring = require("querystring");
  try {
    const response = await apiGetToken
      .post(
        `/services/oauth2/token`,
        querystring.stringify({
          client_id: clientID,
          client_secret: clientSecret,
          username: username,
          password: password,
          grant_type: "password",
        }),
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem(
            "@dataStorage",
            JSON.stringify({
              token: response.data.access_token,
              url: response.data.instance_url,
              token_type: response.data.token_type,
            })
          );
          return "token generated";
        } else {
          return new Error("token not generated");
        }
      })
      .catch((error) => {
        return error.response;
      });
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function sendLead(data, token) {
  const querystring = require("querystring");
  try {
    const response = await apiCreateLead
      .post(
        `/services/apexrest/createlead`,
        { lead: data },
        {
          headers: {
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            "Access-Control-Allow-Headers":
              "Access-Control-Allow-Headers, Authorization, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          return response;
        } else {
          return new Error("lead not created");
        }
      })
      .catch((error) => {
        return error.response;
      });
    return response;
  } catch (error) {
    return error.response;
  }
}
