import React from "react";
import { PackagesContainer } from "./styles";

import standardImg from "../../../../shared/assets/packagesImages/standard.png";
import construckImg from "../../../../shared/assets/packagesImages/construção.png";
import agricultureImg from "../../../../shared/assets/packagesImages/agricultura.png";
import industryImg from "../../../../shared/assets/packagesImages/industria.png";
import rentingImg from "../../../../shared/assets/packagesImages/locação.png";
import miningImg from "../../../../shared/assets/packagesImages/mineração.png";

import { showAnimation } from "../animations";

export default function Packages({ setPackage, isIos }) {
  const handleSetPackage = (packageId) => {
    setPackage(packageId);
  };

  return (
    <PackagesContainer
      variants={showAnimation}
      initial="hidden"
      animate="visible"
      isIos={isIos}
    >
      <div className="scrollable">
        <a onClick={() => handleSetPackage("Standard")}>
          <img src={standardImg} />
          <label>standard</label>
        </a>
        <a onClick={() => handleSetPackage("Construção")}>
          <img src={construckImg} />
          <span className="overlay"></span>
          <label>construção</label>
        </a>
        <a onClick={() => handleSetPackage("Agricultura")}>
          <img src={agricultureImg} />
          <span className="overlay"></span>
          <label>agricultura</label>
        </a>
        <a onClick={() => handleSetPackage("Industria")}>
          <img src={industryImg} />
          <span className="overlay"></span>
          <label>industria</label>
        </a>
        <a onClick={() => handleSetPackage("Locação")}>
          <img src={rentingImg} />
          <span className="overlay"></span>
          <label>locação</label>
        </a>
        <a onClick={() => handleSetPackage("Mineração")}>
          <img src={miningImg} />
          <span className="overlay"></span>
          <label>mineração</label>
        </a>
      </div>
    </PackagesContainer>
  );
}
