import styled from "styled-components";
import { motion } from "framer-motion";

export const HeaderRowContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background: red; */
    padding: 5px 0px;
    padding-right: 16px;
    /* width: calc(100vw - ); */

    label{
        margin: 10px 10px;
        font-weight: normal;
        font-size: 16px;
        color: ${({ theme }) => theme.items.configurador.colors.selected};
    }

    .lock-container{
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid white;
        border-radius: 50px;
        padding: 5px;

        .icon{
            color: ${({ theme, locked }) => locked ? theme.items.configurador.colors.selected: '#000'};
        }

        background: ${({ locked }) => locked ? 'transparent': "#fff"};
    }


`;

export const RowContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    position: relative;
    margin-left: 20px;
    margin-bottom: 5px;
    border-left: 2px solid ${({ theme }) => theme.items.configurador.colors.selected};
    position: relative;

    &.locked{
        opacity: 0.6;
        
        &:after{
            content: '';
            width: 100vw;
            height: calc(100% + 5px);
            position: absolute;
            left: -10px;
            right: 0;
            top: 0;
            bottom: 0;
            background: black;
            opacity: 0.6;
        }
    }

    &:before{
        content: '';
        position: absolute;
        left: -5px;
        bottom: -4px;
        width: 8px;
        height: 8px;
        border-radius: 10px;
        background: ${({ theme }) => theme.items.configurador.colors.selected};
    }

    .item{
        display: flex;
        flex-direction: row;
        margin: 5px 0px;
        width: 100%;
        padding: 5px 0px;
        padding-right: 20px;
        min-height: 32px;
        /* background: red; */
        justify-content: flex-start;
        align-items: center;
        position: relative;
        overflow: visible;

        &.active{
            background: #171717;

            .selector{

                label{
                   color: ${({ theme }) => theme.items.configurador.colors.selected};
                }
            }
        }

        .line{
            width: 25px;
            height: 2px;
            margin-right: 10px;
            background: ${({ theme }) => theme.items.configurador.colors.selected};
        }

        .selector{
            display: flex;
            flex: 1;
            height: 100%;
            justify-content: space-between;
            align-items: center;

            label{
                font-size: 14px;
                width: 75%;
                text-justify: inter-word;
                font-weight: normal;
                text-rendering: optimizeLegibility;
            }
        }
    }
`;


export const Switch = styled.span`
    width: 18px;
    height: 18px;
    /* background: red; */
    margin: 0;
    padding: 0;
    transition: transform .5s cubic-bezier(0.075, 0.82, 0.165, 1);
    position: relative;

    &.active{
        transform: rotateZ(45deg);
    }

    .line-1{
        position: absolute;
        height: 3px;
        width: 100%;
        background: white;
        top: calc(50% - 1.5px);
    }

    .line-2{
        position: absolute;
        height: 100%;
        width: 3px;
        background: white;
        left: calc(50% - 1.5px);
    }
    
`;