import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Container,
  TracbelView,
  TracbelSelector,
  Item,
  ButtonsArea,
} from "./styles";

import tracbelImg from "../../../shared/assets/svg/tracbelNormal.svg";
import { PrimaryButton } from "../../../shared/Components/Buttons";
import pesoOperacionalJSON from "../../../shared/chatbot/peso-operacional.js";
import { useUser } from "../../../contexts/user";
import { useConfigurator } from "../../../contexts/configurator";

export default function SeletorDesktop() {
  const { StartConfiguration } = useConfigurator();
  const [isRecommended, setIsRecommended] = useState(false);
  const { user, setUser } = useUser();
  const [botoes, setBotoes] = useState(pesoOperacionalJSON);
  const navigate = useNavigate();
  const [recommendedModels, setRecommendedModels] = useState([]);
  const [models, setModels] = useState([
    {
      image: "",
      name: "EC140DL",
      selected: false,
      active: true,
    },
    {
      image: "",
      name: "EC140DLM",
      selected: false,
      active: true,
    },
    {
      image: "",
      name: "EC200D",
      selected: false,
      active: false,
    },
    {
      image: "",
      name: "EC210D",
      selected: false,
      active: false,
    },
    {
      image: "",
      name: "EC220D",
      selected: false,
      active: false,
    },
    {
      image: "",
      name: "EC210DL",
      selected: false,
      active: false,
    },
    {
      image: "",
      name: "EC220DL",
      selected: false,
      active: false,
    },
    {
      image: "",
      name: "EC250DL",
      selected: false,
      active: false,
    },
  ]);

  useEffect(() => {
    if (user !== null) {
      console.log(user.resultado);
      if (user.resultado !== null) {
        setIsRecommended(true);
        let arr = user.resultado;
        let newArr = [];
        arr.map((i) => {
          const index = models.findIndex((object) => {
            return object.name === i;
          });
          newArr.push({
            image: "",
            name: i,
            selected: false,
            active: models[index].active,
          });
        });
        setRecommendedModels(newArr);
      }
    }
  }, [user]);

  const submitSelection = () => {
    StartConfiguration(
      models.find((x) => x.selected)?.name ??
        recommendedModels.find((i) => i.selected).name
    );
    navigate("/configurador");
  };

  useEffect(() => {
    filterRecommended();
  }, [recommendedModels]);

  function filterRecommended() {
    let modelsTemp = [];
    models.map((i) => {
      let hasInArray = false;
      recommendedModels.map((item) => {
        if (i.name === item.name) {
          hasInArray = true;
        }
      });

      if (hasInArray === false) modelsTemp.push(i);
    });
    setModels(modelsTemp);
  }

  const handleSelectedModel = (item, normal = true) => {
    if (!isRecommended) {
      let modelsTemp = [];
      models.map((i) => {
        let tempItem = {};
        if (i === item) {
          tempItem = { ...i, selected: true };
        } else {
          tempItem = { ...i, selected: false };
        }
        modelsTemp.push(tempItem);
      });
      // console.log(modelsTemp);
      setModels(modelsTemp);
    } else {
      if (normal) {
        let modelsTemp = [];
        let modelsTempRecommended = [];
        models.map((i) => {
          let tempItem = {};
          if (i === item) {
            tempItem = { ...i, selected: true };
          } else {
            tempItem = { ...i, selected: false };
          }
          modelsTemp.push(tempItem);
        });
        recommendedModels.map((i) => {
          modelsTempRecommended.push({ ...i, selected: false });
        });

        setRecommendedModels(modelsTempRecommended);
        setModels(modelsTemp);
      } else {
        let modelsTemp = [];
        let modelsTempRecommended = [];
        models.map((i) => {
          modelsTemp.push({ ...i, selected: false });
        });
        recommendedModels.map((i) => {
          let tempItem = {};
          if (i === item) {
            tempItem = { ...i, selected: true };
          } else {
            tempItem = { ...i, selected: false };
          }
          modelsTempRecommended.push(tempItem);
        });

        setRecommendedModels(modelsTempRecommended);
        setModels(modelsTemp);
      }
    }
  };

  useEffect(() => {
    // console.log(models);
    // console.log(recommendedModels);
  }, [models]);

  return (
    <Container>
      <TracbelView>
        <img src={tracbelImg} />
      </TracbelView>
      {isRecommended ? (
        <TracbelSelector>
          <div className="selectorArea">
            <div className="recommended">
              <label className="description-recommended">
                Modelos Sugeridos
              </label>
              {recommendedModels.map((i, index) => {
                return (
                  <React.Fragment key={index}>
                    <Item
                      key={index}
                      className={`${i.selected ? "selected" : null} ${
                        i.active ? null : "off"
                      }`}
                      onClick={() => i.active && handleSelectedModel(i, false)}
                    >
                      <img src={tracbelImg} />
                      <label className="title">{i.name}</label>
                      <div className="confirm">
                        <PrimaryButton
                          text={"Confirmar"}
                          action={submitSelection}
                        />
                      </div>
                    </Item>
                  </React.Fragment>
                );
              })}
            </div>
            <div className="divisor"></div>
            <div className="others">
              <label className="description-recommended">Outros Modelos</label>
              {models.map((i, index) => {
                return (
                  <React.Fragment key={index}>
                    <Item
                      className={`disabled ${i.selected ? "selected" : null} ${
                        i.active ? null : "off"
                      }`}
                      onClick={() => i.active && handleSelectedModel(i)}
                    >
                      <img src={tracbelImg} />
                      <label className="title">{i.name}</label>
                      <div className="confirm">
                        <PrimaryButton
                          text={"Confirmar"}
                          action={submitSelection}
                        />
                      </div>
                    </Item>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
          <ButtonsArea>
            {/* <PrimaryButton text={"Confirmar"} action={submitSelection} /> */}
            {/* <label>Precisa de ajuda?</label> */}
          </ButtonsArea>
        </TracbelSelector>
      ) : (
        <TracbelSelector>
          <div className="selectorArea">
            {models.map((i, index) => {
              return (
                <>
                  <Item
                    key={index}
                    className={` ${i.selected ? "selected" : null} ${
                      i.active ? null : "off"
                    }`}
                    onClick={() => i.active && handleSelectedModel(i)}
                  >
                    <img src={tracbelImg} />
                    <label className="title">{i.name}</label>
                    <div className="confirm">
                      <PrimaryButton
                        text={"Confirmar"}
                        action={submitSelection}
                      />
                    </div>
                  </Item>
                </>
              );
            })}
          </div>
          {/* <ButtonsArea>
            <PrimaryButton text={"Confirmar"} action={submitSelection} />
          </ButtonsArea> */}
        </TracbelSelector>
      )}
    </Container>
  );
}
