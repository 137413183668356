const marcaEscavadeirasJSON = {
  marcaEscavadeiras: [
    {
      clicked: false,
      nome: "Volvo",
    },
    {
      clicked: false,
      nome: "Caterpillar",
    },
    {
      clicked: false,
      nome: "John Deere",
    },
    {
      clicked: false,
      nome: "Case",
    },
    {
      clicked: false,
      nome: "Hyundai",
    },
    {
      clicked: false,
      nome: "SDLG",
    },
    {
      clicked: false,
      nome: "Komatsu",
    },
    {
      clicked: false,
      nome: "JCB",
    },
    {
      clicked: false,
      nome: "New Holland",
    },
    {
      clicked: false,
      nome: "Sany",
    },
    {
      clicked: false,
      nome: "XCMG",
    },
    {
      clicked: false,
      nome: "Liugong",
    },
    {
      clicked: false,
      nome: "Outra",
    },
  ],
};

export default marcaEscavadeirasJSON;
