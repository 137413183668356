import React, { useState, useEffect } from "react";

import { Container, SelectedBulldozerContainer } from "./styles";

import { FaChevronLeft } from "react-icons/fa";

import tracbelImage from "../../../shared/assets/images/tracbel.png";
import { useNavigate } from "react-router-dom";

import { useUser } from "../../../contexts/user";
import { useConfigurator } from "../../../contexts/configurator";

export default function SeletorMobile() {
  const { StartConfiguration } = useConfigurator();
  const [state, setState] = useState(0);
  const [isRecommended, setIsRecommended] = useState(false);
  const { user, setUser } = useUser();
  const navigate = useNavigate();
  const [selected, setSelected] = useState({
    image: "",
    name: "teste",
    selected: true,
  });
  const [recommendedModels, setRecommendedModels] = useState([]);
  const [models, setModels] = useState([
    {
      image: "",
      name: "EC140DL",
      selected: false,
      active: true,
    },
    {
      image: "",
      name: "EC140DLM",
      selected: false,
      active: true,
    },
    {
      image: "",
      name: "EC200D",
      selected: false,
      active: false,
    },
    {
      image: "",
      name: "EC210D",
      selected: false,
      active: false,
    },
    {
      image: "",
      name: "EC220D",
      selected: false,
      active: false,
    },
    {
      image: "",
      name: "EC210DL",
      selected: false,
      active: false,
    },
    {
      image: "",
      name: "EC220DL",
      selected: false,
      active: false,
    },
    {
      image: "",
      name: "EC250DL",
      selected: false,
      active: false,
    },
  ]);
  const [height, setHeight] = useState(0);
  const [isIos, setIsIos] = useState(false);

  useEffect(() => {
    setIsIos(/iPad|iPhone|iPod/.test(navigator.userAgent));
  }, []);

  useEffect(() => {
    setHeight(window.innerHeight);

    window.addEventListener("resize", () => {
      setHeight(window.innerHeight);
    });

    return () => {
      window.removeEventListener("resize", () => {
        setHeight(window.innerHeight);
      });
    };
  }, []);

  useEffect(() => {
    if (user !== null) {
      // console.log(user.resultado);
      if (user.resultado !== null) {
        setIsRecommended(true);
        let arr = user.resultado;
        let newArr = [];
        arr.map((i) => {
          const index = models.findIndex((object) => {
            return object.name === i;
          });
          newArr.push({
            image: "",
            name: i,
            selected: false,
            active: models[index].active,
          });
        });
        setRecommendedModels(newArr);
      }
    }
  }, [user]);

  const submitSelection = () => {
    // setar no contexto a escavadeira selecionada
    StartConfiguration(
      models.find((x) => x.selected)?.name ??
        recommendedModels.find((i) => i.selected).name
    );
    navigate("/configurador");
  };

  useEffect(() => {
    models.map((i) => {
      if (i.selected === true) setSelected(i);
    });
    recommendedModels.map((i) => {
      if (i.selected === true) setSelected(i);
    });
  }, [state]);

  useEffect(() => {
    filterRecommended();
  }, [recommendedModels]);

  function filterRecommended() {
    let modelsTemp = [];
    models.map((i) => {
      let hasInArray = false;
      recommendedModels.map((item) => {
        if (i.name === item.name) {
          hasInArray = true;
        }
      });

      if (hasInArray === false) modelsTemp.push(i);
    });
    setModels(modelsTemp);
  }

  const handleSelectedModel = (item, normal = true) => {
    if (!isRecommended) {
      let modelsTemp = [];
      models.map((i) => {
        let tempItem = {};
        if (i === item) {
          tempItem = { ...i, selected: true };
        } else {
          tempItem = { ...i, selected: false };
        }
        modelsTemp.push(tempItem);
      });
      // console.log(modelsTemp);
      setModels(modelsTemp);
      setState(1);
    } else {
      if (normal) {
        let modelsTemp = [];
        let modelsTempRecommended = [];
        models.map((i) => {
          let tempItem = {};
          if (i === item) {
            tempItem = { ...i, selected: true };
          } else {
            tempItem = { ...i, selected: false };
          }
          modelsTemp.push(tempItem);
        });
        recommendedModels.map((i) => {
          modelsTempRecommended.push({ ...i, selected: false });
        });

        setRecommendedModels(modelsTempRecommended);
        setModels(modelsTemp);
        setState(1);
      } else {
        let modelsTemp = [];
        let modelsTempRecommended = [];
        models.map((i) => {
          modelsTemp.push({ ...i, selected: false });
        });
        recommendedModels.map((i) => {
          let tempItem = {};
          if (i === item) {
            tempItem = { ...i, selected: true };
          } else {
            tempItem = { ...i, selected: false };
          }
          modelsTempRecommended.push(tempItem);
        });

        setRecommendedModels(modelsTempRecommended);
        setModels(modelsTemp);
        setState(1);
      }
    }
  };

  useEffect(() => {
    // console.log(models);
    // console.log(recommendedModels);
  }, [models]);

  return (
    <Container height={height}>
      {isRecommended && state === 0 && (
        <SelectedBulldozerContainer height={height}>
          <div className="header">Modelos recomendados</div>
          <div className="cards">
            {recommendedModels.map((i, index) => {
              return (
                <div
                  key={"recommended" + index}
                  className={`card ${i.active ? null : "off"}`}
                  onClick={() => i.active && handleSelectedModel(i, false)}
                >
                  <img src={tracbelImage} />
                  <label className="truckTitle">{i.name}</label>
                </div>
              );
            })}
          </div>
          <div className="header alt">Outros modelos</div>
          <div className="cards">
            {models.map((i, index) => {
              return (
                <div
                  key={"models" + index}
                  className={`card disabled ${i.active ? null : "off"}`}
                  onClick={() => i.active && handleSelectedModel(i)}
                >
                  <img src={tracbelImage} />
                  <label className="truckTitle">{i.name}</label>
                </div>
              );
            })}
          </div>
        </SelectedBulldozerContainer>
      )}
      {!isRecommended && state === 0 && (
        <SelectedBulldozerContainer>
          {/* <div className="header">Modelos disponiveis</div> */}
          <div className="cards">
            {models.map((i, index) => {
              return (
                <div
                  key={"cards-models" + index}
                  className={`card disabled ${i.active ? null : "off"}`}
                  onClick={() => i.active && handleSelectedModel(i)}
                >
                  <img src={tracbelImage} />
                  <label className="truckTitle">{i.name}</label>
                </div>
              );
            })}
          </div>
          {/* <div className="header alt">Outros modelos</div> */}
        </SelectedBulldozerContainer>
      )}
      {state === 1 && (
        <SelectedBulldozerContainer
          height={height}
          className="confirmContainer"
        >
          <span className="backAction" onClick={() => setState(0)}>
            <FaChevronLeft size={20} color={"white"} />
            {selected.name}
          </span>
          <img src={tracbelImage} />
          <a className="buttonConfirm" onClick={submitSelection}>
            <label>Confirmar</label>
          </a>
        </SelectedBulldozerContainer>
      )}
    </Container>
  );
}
