import React, { useContext, createContext, useState, useEffect } from "react";
import { getConfig } from "../../services/api";

const ConfiguratorContext = createContext({});
export default function ConfiguratorProvider(props) {
  const [configIndexSave, setConfigIndexSave] = useState(0);
  const [config, setConfig] = useState({});
  const [loadedConfig, setLoadedConfig] = useState(false);
  const [_3DConfig, set3DConfig] = useState({
    tuboEngate: false,
    bracoLong: true,
    cacambaS: false,
    cacambaL: true,
    sapataS: false,
    sapataM: false,
    sapataL: true,
    upperFilter: false,
  });
  const [lastItemChanged, setLastItemChanged] = useState(null);

  const [storageConfig, setStorageConfig] = useState([]);
  const [firstEntry, setFirstEntry] = useState(true);

  async function loadConfig() {
    changeModel("EC140DL");
  }

  async function StartConfiguration(modelName) {
    const config = await getConfig(modelName);
    setConfig(config);
    setLoadedConfig(true);
    setStorageConfig([
      {
        id: 1,
        model: config.name,
        salesforce_id: config.salesforce_id,
        type: "STANDARD",
        progress: 0.1,
        config: { ...config },
      },
    ]);
    setConfigIndexSave(0);
  }

  useEffect(() => {
    if (!lastItemChanged) return;
    changeConfigState(configIndexSave, { ...config });
  }, [lastItemChanged]);

  useEffect(() => {
    loadConfig();
  }, []);

  useEffect(() => {
    loadDefaultConfig();
  }, [config]);

  useEffect(() => {
    if (!loadedConfig) return;
    loadDefaultConfig();
  }, [loadedConfig]);

  useEffect(() => {
    if (!storageConfig[configIndexSave]) return;
    if (storageConfig[configIndexSave].config) {
      setConfig(storageConfig[configIndexSave].config);
    } else {
      changeConfigState(configIndexSave, config);
    }
  }, [configIndexSave]);

  function changePackage(packageName) {
    const conf = [...storageConfig];
    conf[configIndexSave] = {
      ...conf[configIndexSave],
      type: packageName.toUpperCase(),
    };
    configurePackage(packageName);
    setStorageConfig(conf);
  }

  async function configurePackage(packageName) {
    const packageConfig = config.packages[packageName.toLowerCase()];
    const configDefault = await getConfig(config.name);
    if (packageConfig) {
      packageConfig.options.forEach((option) => {
        configDefault.config[option.item.id][option.type].forEach((i) => {
          if (option.type === "radio") {
            if (i.id === option.id) {
              i.selected = true;
            } else {
              i.selected = false;
            }
          } else {
            if (i.id === option.id) {
              i.selected = true;
              i.unselect?.forEach((itemRoot) => {
                itemRoot.options.forEach(unitem => {
                  configDefault.config[itemRoot.item][unitem.type].filter(opt => opt.id === unitem.id)[0].selected = false
                });
              });
            }
          }
        });
      });
    }
    setConfig(configDefault);
  }
  async function changeModel(model) {
    const config = await getConfig(model);
    setConfig(config);
    addNewConfig(config);
    setLoadedConfig(true);
  }

  function loadDefaultConfig() {
    set3DConfig({
      tuboEngate: false,
      bracoLong: true,
      cacambaS: false,
      cacambaL: true,
      sapataS: false,
      sapataM: false,
      sapataL: true,
      upperFilter: false,
    });
    config.items?.forEach((item) => {
      getOptions(item.name).radio?.forEach((i) => {
        if (i.selected) {
          change3D(getOptions(item.name), i);
          return;
        }
      });
      getOptions(item.name).checkbox?.forEach((i) => {
        if (i.selected) {
          change3D(getOptions(item.name), i);
          return;
        }
      });
    });
  }

  function addNewConfig(newConfig) {
    const conf = [...storageConfig];
    const newIndex = conf.length + 1;
    conf.push({
      ...newConfig,
      id: newIndex,
      model: newConfig.name,
      type: "STANDARD",
      progress: 0.1,
      config: { ...newConfig },
    });
    setStorageConfig(conf);
    setConfigIndexSave(conf.length - 1);
  }

  function removeConfig(index) {
    const conf = [...storageConfig];
    if (conf.length === 1) {
      return;
    }
    conf.splice(index, 1);
    setStorageConfig(conf)
    const newIndex = ((index === configIndexSave) ? (((index - 1) < 0) ? 0 : index - 1) : ((index < configIndexSave) ? configIndexSave - 1 : configIndexSave));
    setConfigIndexSave(newIndex);
    if (!conf[newIndex]) return;
    if (conf[newIndex].config)
      setConfig(conf[newIndex].config);

  }

  function changeConfigState(index, newConfig) {
    if (!newConfig.config) return;
    const conf = [...storageConfig];
    conf[index] = {
      ...conf[index],
      config: { ...newConfig },
    };
    setStorageConfig(conf);
  }

  function loadSavedConfig(configSavedIndex) {
    setConfigIndexSave(configSavedIndex);
    return;
  }

  function getItem(itemName) {
    const item = config?.items?.find(
      (x) => x.name.toLowerCase() === itemName.toLowerCase()
    );
    return item;
  }

  function getOptions(itemName) {
    const item = getItem(itemName);
    if (!item) return { radio: null, checkbox: null };
    const radio = config?.config[item.id]?.radio ?? null;
    const checkbox = config?.config[item.id]?.checkbox ?? null;
    return { ...item, radio, checkbox };
  }

  function selectOption(item, type, option) {
    let hasBlocked = false;
    option?.block?.forEach((block) => {
      if (
        config.config[item.id]["radio"].filter((opt) => opt.id === block)[0]
          ?.selected ||
        config.config[item.id]["checkbox"].filter((opt) => opt.id === block)[0]
          ?.selected
      ) {
        hasBlocked = true;
        return;
      }
    });
    if (hasBlocked) return;
    if (type === "checkbox" && option?.lock) {
      if (option.description || option.image) setLastItemChanged(option);
      return;
    }
    if (!item || !type || !option) return;
    if (type === "radio") changeRadioOption(item, option);
    if (type === "checkbox") changeCheckboxOption(item, option);
    if (option.description || option.image) setLastItemChanged(option);
    change3D(item, option);
    option.preselect?.forEach((preItem) => {
      let hasSelected = false;
      let radioItems = preItem.options.filter((options) => options.type === "radio");
      radioItems.forEach((radioItem) => {
        if (config.config[preItem.item]['radio'].filter(opt => opt.id === radioItem.id)[0].selected) {
          hasSelected = true;
        }
      })
      preItem.options.forEach((preOption) => {
        if (preOption.type === "radio") {
          if (config.config[preItem.item][preOption.type].filter(
            (opt) => opt.id === preOption.id
          )[0]?.selected) hasSelected = true;
        }
        if (!hasSelected) {
          if (
            !config.config[preItem.item][preOption.type].filter(
              (opt) => opt.id === preOption.id
            )[0]?.selected
          ) {
            selectOption(config.items.filter(x => x.id === preItem.item)[0], preOption.type, { id: preOption.id, name: "" });
          }
        }
      });
    });
    option.unselect?.forEach((itemRoot) => {
      itemRoot.options.forEach(unitem => {
        if (
          config.config[itemRoot.item][unitem.type].filter(
            (opt) => opt.id === unitem.id
          )[0]?.selected
        ) {
          selectOption(config.items.filter(x => x.id === itemRoot.item)[0], unitem.type, { id: unitem.id, name: "" });
        }
      });
    });
  }

  function change3D(item, option) {
    if (item.name.toLowerCase() === "caçamba") {
      if (option.name.search("5 dentes") > -1) {
        set3DConfig((prev) => ({
          ...prev,
          cacambaS: false,
          cacambaL: true,
        }));
      } else if (option.name.search("4 dentes") > -1) {
        set3DConfig((prev) => ({
          ...prev,
          cacambaS: true,
          cacambaL: false,
        }));
      } else if (option.name.toLowerCase().search("tubo engate") > -1) {
        set3DConfig((prev) => ({
          ...prev,
          tuboEngate: option.selected
        }));
      } else if (option.name.toLowerCase().search("sem caçamba") > -1)
      {
        set3DConfig((prev) => ({
          ...prev,
          cacambaS: false,
          cacambaL: false,
        }));
      }
    } else if (item.name.toLowerCase() === "braço") {
      if (option.name.search("2,5") > -1) {
        set3DConfig((prev) => ({
          ...prev,
          bracoLong: false,
        }));
      } else {
        set3DConfig((prev) => ({
          ...prev,
          bracoLong: true,
        }));
      }
    } else if (item.name.toLowerCase() === "sapata") {
      if (option.name.search("500") > -1) {
        set3DConfig((prev) => ({
          ...prev,
          sapataS: true,
          sapataM: false,
          sapataL: false,
        }));
      } else if (option.name.search("600") > -1) {
        set3DConfig((prev) => ({
          ...prev,
          sapataS: false,
          sapataM: true,
          sapataL: false,
        }));
      } else {
        set3DConfig((prev) => ({
          ...prev,
          sapataS: false,
          sapataM: false,
          sapataL: true,
        }));
      }
    } else if (item.name.toLowerCase() === "motor") {
      if (
        (option.name.search("filtro") > -1 ||
          option.name.search("chuva") > -1) &&
        (option.selected || option.selected === undefined)
      ) {
        set3DConfig((prev) => ({ ...prev, upperFilter: true }));
      } else if (
        (option.name.search("filtro") > -1 ||
          option.name.search("chuva") > -1) &&
        !option.selected
      ) {
        set3DConfig((prev) => ({ ...prev, upperFilter: false }));
      }
    }
  }

  function changeRadioOption(item, option) {
    let prevOption = null,
      newOption = null,
      changed = true,
      find = false;
    const newConfig = config.config[item.id]["radio"].map((opt) => {
      if (opt.selected) {
        if (opt.id === option.id) changed = false;
        prevOption = opt;
        opt.selected = false;
      }
      if (opt.id === option.id) {
        newOption = opt;
        opt.selected = true;
        find = true;
      }
      return opt;
    });
    if (!changed || !find) return false;
    setConfig((prev) => ({
      ...prev,
      config: {
        ...prev.config,
        [item.id]: {
          radio: newConfig,
          checkbox: prev.config[item.id]["checkbox"],
        },
      },
    }));
    return { prevOption, newOption };
  }

  function changeCheckboxOption(item, option) {
    let optionChanged = false;
    const newConfig = config.config[item.id]["checkbox"].map((opt) => {
      if (opt.id === option.id) {
        opt.selected = !opt.selected;
        optionChanged = opt;
      }
      return opt;
    });
    if (optionChanged) {
      setConfig((prev) => ({
        ...prev,
        config: {
          ...prev.config,
          [item.id]: {
            radio: prev.config[item.id]["radio"],
            checkbox: newConfig,
          },
        },
      }));
    }
    return optionChanged;
  }

  function currentConfig() {
    return config.items?.map((item) => ({
      name: item.name,
      selected: (
        getOptions(item.name).radio?.filter((i) => i.selected) ?? []
      ).concat(getOptions(item.name).checkbox?.filter((i) => i.selected) ?? []),
    }));
  }

  function getConfigs() {
    return storageConfig.map((cfg) => ({
      model: cfg.model,
      package: cfg.type,
      productID: cfg.salesforce_id,
      config: cfg.config.items?.map((item) => ({
        name: item.name,
        selected: (
          cfg.config.config[item.id].radio?.filter((i) => i.selected) ?? []
        ).concat(
          cfg.config.config[item.id].checkbox?.filter((i) => i.selected) ?? []
        ),
      })),
    }));
  }
  return (
    <ConfiguratorContext.Provider
      value={{
        getOptions,
        selectOption,
        currentConfig,
        loadSavedConfig,
        changeModel,
        changePackage,
        StartConfiguration,
        getConfigs,
        removeConfig,
        storageConfig,
        loadedConfig,
        config,
        _3DConfig,
        lastItemChanged,
        configIndexSave,
        firstEntry,
        setFirstEntry,
      }}
    >
      {props.children}
    </ConfiguratorContext.Provider>
  );
}

export const useConfigurator = () => useContext(ConfiguratorContext);
