import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    position: relative;
    /* background-color: red; */
    width: 100vw;
    height: calc(100vh - 70px);
    top: 70px;
    /* margin-top: 70px; */
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
`;


export const TextContainer = styled.div`
    display: flex;
    width: 40vw;
    min-width: 600px;
    max-width: 600px;
    height: 400px;
    /* background-color: silver; */
    flex-direction: column;
    align-items: center;
    margin-right: 20px;

    .title{
        font-size: 5rem;
        font-family: ${({ theme }) => theme.items.home.text.font};
        color: ${({ theme }) => theme.items.home.text.primary_color};
    }

    .description{
        font-size: 1.6rem;
        letter-spacing: 0.12rem;
        margin-top: -20px;
        white-space: nowrap;
        font-family: ${({ theme }) => theme.items.home.text.font};
        color: ${({ theme }) => theme.items.home.text.tertiary_color};
    }

    .buttons-container{
        display: flex;
        width: 100%;
        height: 40px;
        flex-direction: row;
        margin-top: 30px;
        /* background-color: red; */

        div{ 
            &:nth-child(1){
                margin-right: 10px;
            }

            &:nth-child(2){
                margin-left: 10px;
            }
        }
    }
`;

export const ImageContainer = styled.div`
    display: flex;
    margin-left: 20px;
    height: 80%;
    max-width: 40%;

`;