import React from "react";

import { Container, TextContainer, ImageContainer } from "./styles";

import imageContainer from "../../../shared/assets/svg/tracbelNormal.svg";
import { PrimaryButton } from "../../../shared/Components/Buttons";
import { useNavigate } from "react-router-dom";

const HomeDesktop = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <TextContainer>
        <label className="title">Configurador</label>
        <label className="description">
          Tracbel para máquinas e implementos
        </label>
        <div className="buttons-container">
          <PrimaryButton
            text={"Configurar Modelo"}
            size={"large"}
            action={() => navigate("/seletordemodelos")}
          />
          <PrimaryButton
            text={"Encontrar seu modelo ideal"}
            size={"large"}
            action={() => navigate("/assistentevirtual")}
          />
        </div>
      </TextContainer>
      <ImageContainer>
        <img src={imageContainer} />
      </ImageContainer>
    </Container>
  );
};

export default HomeDesktop;
