import React, { forwardRef, useEffect, useRef } from "react";
import useDynamicRefs from "use-dynamic-refs";
import * as THREE from "three";
import { useFrame, useThree } from "@react-three/fiber";
import { Html } from "@react-three/drei";

const MyPointCanvas = forwardRef((props, ref) => {
  useEffect(() => {
    // Add mesh to camera
    const meshRef2 = ref.current;
  }, [ref.current]);

  return (
    <mesh
      castShadow={false}
      ref={ref}
      position={[props.posX, props.posY, props.posZ]}
      rotation={[0, 0, 0]}
      scale={[1, 1, 1]}
      visible={props.visible}
    >
      <boxBufferGeometry
        attach="geometry"
        args={[props.size, props.size, props.size]}
      />
      <meshBasicMaterial
        color="#FDC92D"
        side={THREE.DoubleSide}
        transparent={props.opacity < 1 ? true : false}
        opacity={props.opacity}
      />
    </mesh>
  );
});

const MyPointCamera = forwardRef((props, ref) => {
  const refBoard = useRef();
  const { camera } = useThree();
  useEffect(() => {
    // Add mesh to camera
    const meshRefBoard = refBoard.current;
    const meshRef = ref.current;
    camera.add(meshRefBoard);
    camera.add(meshRef);
    // props.virtualCamera.current.add(meshRefBoard);
    // props.virtualCamera.current.add(meshRef);

    // // virtualCamera cleanup on unmount
    // return () => {
    //   props.virtualCamera.current.remove(meshRefBoard);
    //   props.virtualCamera.current.remove(meshRef);
    // };
  }, []);

  const selectRadio = (item, parent) => {
    props.setAlert({
      show: true,
      time: 2000,
      message: "Item configurado",
    });
    return props.selectOption(parent, "radio", item);
  };

  const selectToogle = (item, parent) => {
    props.setAlert({
      show: true,
      time: 2000,
      message: "Item configurado",
    });
    return props.selectOption(parent, "checkbox", item);
  };

  return (
    <>
      <mesh
        ref={refBoard}
        position={[props.posX, props.posY, props.posZ]}
        rotation={[0, 0, 0]}
        scale={[0.001, 0.001, 0.001]}
        visible={props.visible}
        castShadow={false}
      >
        <planeBufferGeometry
          attach="geometry"
          args={[props.width, props.height]}
        />
        <Html>
          <div
            className={`menu-container-d ${props.align} ${props.title ===
              "Sapata" && "top"}`}
            style={{
              visibility: props.visible ? "visible" : "hidden",
              opacity: props.opacity,
              pointerEvents: props.opacity > 0 ? "all" : "none",
            }}
          >
            {props.title !== "Sapata" && (
              <h1 className={`menu-title ${props.align}`}>{props.title}</h1>
            )}
            {props.open === true &&
              props.items.map((value, index) => {
                if (value.type === "radio") {
                  return (
                    <div
                      key={index}
                      className={`item-container  ${props.align} ${
                        props.align
                      } ${props.open ? "opened" : ""}`}
                    >
                      {value.options.map((value2, index2) => {
                        return (
                          <div
                            className={`item ${props.align} ${
                              value2.selected ? "selected" : ""
                            }`}
                            key={index2}
                            onClick={() =>
                              selectRadio(value2.item, value2.parent)
                            }
                          >
                            {value2.title}
                            <div className="info"></div>
                          </div>
                        );
                      })}
                    </div>
                  );
                }
                if (value.type === "toogle") {
                  return (
                    <div
                      key={index}
                      className={`item-container  ${props.align} ${
                        props.align
                      } ${props.open ? "opened" : ""}`}
                    >
                      {value.options.map((value2, index2) => {
                        return (
                          <div
                            className={`item-toogle ${props.align} ${
                              value2.selected ? "selected" : ""
                            }`}
                            key={index2}
                            onClick={() =>
                              selectToogle(value2.item, value2.parent)
                            }
                          >
                            <div className="circle">
                              <div
                                className={`selected ${value2.selected &&
                                  "on"}`}
                              >
                                +
                              </div>
                            </div>
                            {value2.title}
                            <div className="info"></div>
                          </div>
                        );
                      })}
                    </div>
                  );
                }
              })}
            {props.title === "Sapata" && (
              <h1 className={`menu-title under ${props.align}`}>
                {props.title}
              </h1>
            )}
          </div>
        </Html>
        <meshBasicMaterial
          color="green"
          side={THREE.DoubleSide}
          depthWrite={false}
          depthTest={false}
          transparent={true}
          opacity={0}
        />
      </mesh>
      <mesh
        castShadow={false}
        ref={ref}
        position={[
          props.posX - (props.width * 0.001) / 2 - 0.0005,
          props.posY,
          props.posZ,
        ]}
        rotation={[0, 0, 0]}
        scale={[0.001, 0.001, 0.001]}
        visible={props.visible}
      >
        <planeBufferGeometry attach="geometry" args={[0.5, 0.5]} />
        <meshBasicMaterial
          color="#FDC92D"
          side={THREE.DoubleSide}
          depthWrite={false}
          depthTest={false}
          transparent={props.opacity < 1 ? true : false}
          opacity={props.opacity}
        />
      </mesh>
    </>
  );
});

const CustomLine = forwardRef((props, ref) => {
  var origin = new THREE.Vector3();
  var target = new THREE.Vector3();
  let lineGeometry;
  useFrame(() => {
    if (props.originRef.current !== null && props.targetRef.current !== null) {
      props.originRef.current.getWorldPosition(origin);
      props.targetRef.current.getWorldPosition(target);
      lineGeometry = new THREE.BufferGeometry().setFromPoints([origin, target]);
      ref.current.geometry = lineGeometry;
    }
  }, []);

  return (
    <line ref={ref} visible={props.visible}>
      <lineBasicMaterial
        attach="material"
        color={"#FDC92D"}
        linewidth={5}
        linecap={"round"}
        linejoin={"round"}
        depthWrite={false}
        transparent={props.opacity < 1 ? true : false}
        opacity={props.opacity}
      />
    </line>
  );
});

const Menu3D = (props) => {
  const [getRef, setRef] = useDynamicRefs();
  const meshRef = useRef(null);
  const meshCanvasRef = useRef(null);
  const lineRef = useRef();
  return props.menuItems.map((value, index) => (
    <React.Fragment key={index}>
      <MyPointCanvas
        size={2}
        posX={value.position.start.x}
        posY={value.position.start.y}
        posZ={value.position.start.z}
        ref={setRef(value.title + "canvas")}
        opacity={props.opacity}
        visible={props.visible}
      />
      <MyPointCamera
        alert={props.alert}
        setAlert={props.setAlert}
        width={20}
        height={3}
        title={value.title}
        items={value.items}
        posX={value.position.end.x}
        posY={value.position.end.y}
        posZ={-0.1}
        ref={setRef(value.title + "camera")}
        // virtualCamera={props.virtualCamera}
        opacity={props.opacity}
        align={value.align}
        menuItems={props.menuItems}
        setMenuItems={props.setMenuItems}
        selectOption={props.selectOption}
        open={value.open}
        visible={props.visible}
      />
      <CustomLine
        ref={setRef(value.title + "line")}
        originRef={getRef(value.title + "canvas")}
        targetRef={getRef(value.title + "camera")}
        opacity={props.opacity}
        visible={props.visible}
      />
    </React.Fragment>
  ));
};

export default React.memo(Menu3D);
