import styled from "styled-components";

export const Wrapper = styled.div`
  user-select: none;
  position: fixed;
  color: red;
  font-family: ${({ theme }) => theme.items.configurador.fonts.title};
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 1px;
  background-color: rgba(0, 0, 0, 0.95);
  padding: 20px 30px;
  color: white;
  height: auto;
  width: auto;
  box-sizing: border-box;
  border-radius: 10px;
  white-space: pre-wrap;
  text-align: center;
  left: 50%;
  top: 40%;
  transform: translateX(-50%) translateY(-50%);
  opacity: 0;
  z-index: -1;
  transition: z-index 0s linear 0.7s, opacity 0.7s ease-in-out 0s,
    top 0.7s ease-in-out 0s;

  &.on {
    opacity: 0.9;
    top: 50%;
    z-index: 9999999999;
    transition: z-index 0s linear 0s, opacity 0.7s ease-in-out 0s,
      top 0.7s ease-in-out 0s;
  }
`;

export const ModalButtons = styled.div``;

export const ModalButton = styled.div`
  cursor: pointer;
  display: inline-block;
  background-color: ${({ theme }) => theme.items.configurador.colors.primary};
  padding: 10px 20px;
  margin: 15px;
  border-radius: 10px;

  &.danger {
    background-color: ${({ theme }) =>
      theme.items.configurador.colors.greySteps};
  }
`;
