import styled from 'styled-components'


export const Container = styled.div`
    display: flex;
    width: 100%;

    &:hover{
        cursor: pointer;
        transform: scale(1.02);
    }

    .primary{
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        background-color: ${({ theme }) => theme.items.defaults.buttons.colors.primary};
        color: ${({ theme }) => theme.items.defaults.buttons.colors.text_primary};
        padding: 5px;
        font-size: 0.9rem;

        &.large{
            padding: 10px 0px;
            font-size: 1.1rem;
            border-radius: 20px;
            /* max-height: 50px; */
        }
    }
`;