import React, { useState, useEffect } from "react";
import {
  Container,
  Steps,
  RightInteractionsItem,
  ContainerRightInteracions,
  InfoBoxContainer,
} from "./styles";

import { FaUndoAlt, FaInfoCircle } from "react-icons/fa";
import { HiOutlineShare, HiChevronUp } from "react-icons/hi";
import { useConfigurator } from "../../../../contexts/configurator";

export const BottomNavbar = React.memo(
  ({
    alert,
    setAlert,
    firstView,
    firstEntry,
    screenStep,
    setScreenStep,
    showInfoContainer,
    setShowInfoContainer,
    tutorialStep,
  }) => {
    useEffect(() => {
      if (screenStep === 1 && firstEntry) {
        setAlert({
          show: true,
          time: 5000,
          message: "Primeiro, escolha a aplicação para sua escavadeira",
        });
      } else if (screenStep === 1 && !firstEntry) {
        setAlert({
          show: true,
          time: 5000,
          message: "Aqui você pode configurar os items básicos da escavadeira",
        });
      } else if (screenStep === 2) {
        setAlert({
          show: true,
          time: 5000,
          message:
            "Aqui você configura os itens opcionais que estão em volta da escavadeira",
        });
      }
    }, [screenStep]);
    return (
      <Container>
        <InfoBox />
        <Steps className={`${tutorialStep === 2 ? "highlighted" : ""}`}>
          <div
            className={`step-item ${screenStep === 1 ? "on" : ""}`}
            onClick={() => !firstView && setScreenStep(1)}
          >
            {screenStep === 1 ? "1. Config" : "1. Config"}
          </div>
          <div
            className={`step-item ${screenStep === 2 ? "on" : ""}`}
            onClick={() => !firstView && setScreenStep(2)}
          >
            {screenStep === 2 ? "2. Opcionais" : "2. Opcionais"}
          </div>
          <div
            className={`step-item ${screenStep === 3 ? "on" : ""}`}
            onClick={() => !firstView && setScreenStep(3)}
          >
            {screenStep === 3 ? "3. Resumo" : "3. Resumo"}
          </div>
          <div
            className={`step-item ${screenStep === 4 ? "on" : ""}`}
            onClick={() => {
              if (!firstView) {
                setScreenStep(4);
              }
            }}
          >
            {screenStep === 4
              ? "4. Solicitar Orçamento"
              : "4. Solicitar Orçamento"}
          </div>
        </Steps>
        <RightInteractions
          firstView={firstView}
          showInfoContainer={showInfoContainer}
          setShowInfoContainer={setShowInfoContainer}
          tutorialStep={tutorialStep}
        />
      </Container>
    );
  }
);

const RightInteractions = ({
  firstView,
  showInfoContainer,
  setShowInfoContainer,
  tutorialStep,
}) => {
  const {
    config: configContext,
    lastItemChanged,
    firstEntry,
  } = useConfigurator();

  useEffect(() => {
    if (firstEntry) setShowInfoContainer(false);
    else {
      setShowInfoContainer(true);
    }
  }, [lastItemChanged]);

  return (
    <ContainerRightInteracions>
      <RightInteractionsItem
        className={`info-container ${showInfoContainer ? "show" : ""}`}
      >
        <div className={`arrow-up ${tutorialStep === 3 ? "highlighted" : ""}`}>
          <HiChevronUp
            className="info"
            onClick={() => setShowInfoContainer(!showInfoContainer)}
          />
        </div>
        <div className="content">
          {lastItemChanged?.image ? (
            <div className="image">
              {lastItemChanged?.image && (
                <img src={process.env.PUBLIC_URL + lastItemChanged?.image} />
              )}
            </div>
          ) : (
            <div className="image-hide"></div>
          )}
          <div className="description">{lastItemChanged?.description}</div>
        </div>
      </RightInteractionsItem>
      <RightInteractionsItem>
        {/* <FaUndoAlt className="refresh" /> */}
      </RightInteractionsItem>
      <RightInteractionsItem>
        {/* <HiOutlineShare className="share" /> */}
      </RightInteractionsItem>
    </ContainerRightInteracions>
  );
};

const InfoBox = () => {
  const [showInfo, setShowInfo] = useState(false);
  const [classShow, setClassShow] = useState("");

  useEffect(() => {
    if (showInfo) {
      setClassShow("on");
    } else {
      setClassShow("");
    }
  }, [showInfo]);

  const changeInfoState = () => {
    if (showInfo) setShowInfo(false);
    else {
      setShowInfo(true);
    }
  };

  return (
    <InfoBoxContainer>
      <section className={`info-box-hide ${classShow}`}>
        <label className="desc">texto daora</label>
        {/* <FaInfoCircle className='icon' onClick={changeInfoState}/> */}
      </section>
    </InfoBoxContainer>
  );
};
