import styled, { css } from "styled-components";
import { motion } from "framer-motion";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: ${({ height }) => height - 40}px;

  ${({ isIos }) =>
    isIos &&
    css`
      height: ${({ height }) => height - 40}px;
    `}

  /* overflow: hidden; */
  /* background-color: red; */
  canvas {
    width: 100%;
    height: 100%;
  }
`;

export const Description = styled.p`
  user-select: none;
  position: absolute;
  left: 37px;
  top: 60px;
  font-size: 10px;
  letter-spacing: 1px;
  color: #ffffff;
`;

export const Buttons = styled.div`
  position: absolute;
  z-index: 100;
  right: 15px;
  top: 55px;
  display: flex;
  /* background: red; */
  width: 180px;
  height: 40px;
  font-size: 0.8rem;
  font-weight: bold;
  color: black;

  a {
    flex: 1;
    margin: 5px 10px;
    border: 2px solid ${({ theme }) => theme.items.configurador.colors.selected};
    background-color: ${({ theme }) =>
      theme.items.configurador.colors.selected};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
  }
`;

export const ContentArea = styled.div`
  display: flex;
  width: 100vw;
  /* background-color: cyan; */
  /* height: calc(100vh - 110px); */
  height: ${({ height }) => height - 40}px;

  ${({ isIos }) =>
    isIos &&
    css`
      height: ${({ height }) => height - 40}px;
    `}
`;

export const Navbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 2%;
  width: 96%;
  height: 40px;
  background: #000;
  position: absolute;
  bottom: 0;

  ${({ isIos }) =>
    isIos &&
    css`
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      padding: 2%;
      width: 96%;
      height: 40px;
      background: #000;
      position: relative;
    `}

  .resume-nav {
    a {
      font-size: 1.1rem;
      font-weight: 900;
      color: ${({ theme }) => theme.items.defaults.text.color};

      &.selected {
        color: ${({ theme }) => theme.items.defaults.text.selected};
      }
    }
  }

  a {
    font-size: 1.1rem;
    font-weight: 200;
    color: ${({ theme }) => theme.items.defaults.text.color};

    &.selected {
      color: ${({ theme }) => theme.items.defaults.text.selected};
    }
  }
`;
