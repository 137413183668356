import React from "react";

import { isMobile } from "react-device-detect";
import MapaConcessionariasDesktop from "./desktop";
import MapaConcessionariasMobile from "./mobile";

const MapaConcessionarias = () => {
  return (
    <>
      {isMobile ? (
        <MapaConcessionariasMobile />
      ) : (
        <MapaConcessionariasDesktop />
      )}
    </>
  );
};

export default MapaConcessionarias;
