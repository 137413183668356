import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: auto;
  flex-flow: row nowrap;
  flex: 1;
  align-items: center;
  max-width: ${({ width }) => (width !== null ? width : "100")}%;
  margin: 10px;

  a {
    color: ${({ theme }) => theme.items.configurador.colors.primary};
  }

  input {
    flex-shrink: 0;
    height: 18px !important;
    width: 18px !important;
    background: ${({ theme }) =>
      theme.items.configurador.colors.backgroundResumeLight};
    border: none;
    padding: 5px 10px;
    margin: 5px 0px;
    color: white;
    border-radius: 3px;

    &:focus {
      outline: none;
      border: 1px solid
        ${({ theme }) => theme.items.configurador.colors.primary};
    }
  }

  label {
    color: white;
    margin-left: 10px;
  }

  @media only screen and (max-width: 1400px) {
    margin: 3px 10px;

    label {
      font-size: 0.65em;
    }

    input {
      font-size: 0.85em;
    }
  }
`;
