import axios from "axios";
import config from "./config";

const clone = require("rfdc")();

const api = axios.create({
  baseURL: "https://tracbel.maplus.com.br/api",
});

export async function getConfig(model = "EC140DL") {
  return await loadFromFile(model);
  // try {
  //   return (await api.post("/config", { model })).data;
  // } catch (e) {
  //   console.error("API FAILED", e);
  //   return false;
  // }
}

async function loadFromFile(model = "EC140DL") {
  const cfg = clone(config);
  return { ...cfg[model] };
}

export default api;
