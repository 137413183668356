import React, { useEffect, useContext, useState, createContext } from "react";

const userModel = {
  ajuda: null,
  nome: null,
  telefone: null,
  email: null,
  estado: "SP",
  naturezaJuridica: null,
  cpf: null,
  cnpj: null,
  razaoSocial: null,
  inscricaoEstadual: null,
  possueMaquinas: null,
  quantidadeMaquinas: null,
  marcaEscavadeiras: null,
  prazoEntrega: null,
  segmentoAtuacao: null,
  pesoOperacional: null,
  resultado: null,
  selecionado: null,
  concordarReceberComunicacoes: false,
  concordarArmazenarDados: false,
  configuracao: null,
  proprietario: null,
  OrigemLead: "Configurador",
  Midia: null,
  TipoContato: "Configurador",
  Produto: null,
};

const UserContext = React.createContext();

export default function UserProvider({ children }) {
  const [user, setUser] = useState(userModel);

  useEffect(() => {
    // console.log(user);
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  const context = useContext(UserContext);
  if (!context) throw new Error("useUser must be used within a UserProvider");

  const { user, setUser } = context;

  return { user, setUser };
}
