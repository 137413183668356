const possueMaquinasJSON = {
  possueMaquinas: [
    {
      clicked: false,
      nome: "Escavadeira",
      nextID: "questionUserQuantidadeMaquinasEscavadeiras",
      isEscavadeira: true,
    },
    {
      clicked: false,
      nome: "Miniescavadeira",
      nextID: "questionUserQuantidadeMaquinasEscavadeiras",
      isEscavadeira: true,
    },
    {
      clicked: false,
      nome: "Carregadeira",
      nextID: "questionUserQuantidadeMaquinasEscavadeiras",
      isEscavadeira: false,
    },
    {
      clicked: false,
      nome: "Minicarregadeira",
      nextID: "questionUserQuantidadeMaquinasEscavadeiras",
      isEscavadeira: false,
    },
    {
      clicked: false,
      nome: "Retroescavadeira",
      nextID: "questionUserQuantidadeMaquinasEscavadeiras",
      isEscavadeira: true,
    },
    {
      clicked: false,
      nome: "Motoniveladora",
      nextID: "questionUserQuantidadeMaquinasEscavadeiras",
      isEscavadeira: false,
    },
    {
      clicked: false,
      nome: "Rolo Compactador",
      nextID: "questionUserQuantidadeMaquinasEscavadeiras",
      isEscavadeira: false,
    },
    {
      clicked: false,
      nome: "Não Possuo",
      nextID: "questionUserPrazoEntrega",
      isEscavadeira: false,
    },
  ],
};

export default possueMaquinasJSON;
