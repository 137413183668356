import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  body {
    width:100vw;
    height:100vh;
    overflow:hidden;
    background-color: ${({ theme }) => theme.items.body.background.color};
     background-image: ${({ theme }) => theme.items.body.background.image};
    background-repeat:no-repeat;
    background-size:contain;
    color: ${({ theme }) => theme.items.body.text.color};
    font-family: ${({ theme }) => theme.items.body.text.font};
    -webkit-tap-highlight-color: transparent;
    transition: all 0.50s linear;
  }
`;
