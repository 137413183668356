import styled from "styled-components";
import { motion } from "framer-motion";

export const ResumoContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 40px;
  margin-top: 110px;
  padding: 0px 0px;
  min-width: 100vw;
  background: black;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;

  h3 {
    margin: 10px 10px;
    font-weight: normal;
    font-size: 16px;
    color: ${({ theme }) => theme.items.configurador.colors.selected};
  }

  .confirm {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    min-height: 50px;
    padding: 10px 0px;
    margin-top: 20px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid
        ${({ theme }) => theme.items.configurador.colors.selected};
      color: white;
      height: 100%;
      width: auto;
      padding: 0px 25px;
      font-size: 1.2rem;
      border-radius: 40px;
      font-weight: bold;
    }
  }
`;

export const HeaderRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: red; */
  padding: 5px 0px;
  padding-right: 16px;
  /* width: calc(100vw - ); */

  label {
    margin: 10px 10px;
    font-weight: normal;
    font-size: 16px;
    color: ${({ theme }) => theme.items.configurador.colors.selected};
  }

  .lock-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    border-radius: 50px;
    padding: 5px;

    .icon {
      color: ${({ theme, locked }) =>
        locked ? theme.items.configurador.colors.selected : "#000"};
    }

    background: ${({ locked }) => (locked ? "transparent" : "#fff")};
  }
`;

export const RowContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  position: relative;
  margin-left: 20px;
  margin-bottom: 5px;
  border-left: 2px solid
    ${({ theme }) => theme.items.configurador.colors.selected};
  position: relative;

  &.locked {
    opacity: 0.6;

    &:after {
      content: "";
      width: 100vw;
      height: calc(100% + 5px);
      position: absolute;
      left: -10px;
      right: 0;
      top: 0;
      bottom: 0;
      background: black;
      opacity: 0.6;
    }
  }

  &:before {
    content: "";
    position: absolute;
    left: -5px;
    bottom: -4px;
    width: 8px;
    height: 8px;
    border-radius: 10px;
    background: ${({ theme }) => theme.items.configurador.colors.selected};
  }

  .item {
    display: flex;
    flex-direction: row;
    margin: 5px 0px;
    width: 100%;
    padding: 5px 0px;
    padding-right: 20px;
    min-height: 32px;
    /* background: red; */
    justify-content: flex-start;
    align-items: center;
    position: relative;
    overflow: visible;

    &.active {
      background: #171717;

      .selector {
        label {
          color: ${({ theme }) => theme.items.configurador.colors.selected};
        }
      }
    }

    .line {
      width: 25px;
      height: 2px;
      margin-right: 10px;
      background: ${({ theme }) => theme.items.configurador.colors.selected};
    }

    .selector {
      display: flex;
      flex: 1;
      height: 100%;
      justify-content: space-between;
      align-items: center;

      label {
        font-size: 14px;
        width: 75%;
        text-justify: inter-word;
        font-weight: normal;
        text-rendering: optimizeLegibility;
      }
    }
  }
`;

export const Switch = styled.span`
  width: 18px;
  height: 18px;
  /* background: red; */
  margin: 0;
  padding: 0;
  transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  position: relative;

  &.active {
    transform: rotateZ(45deg);
  }

  .line-1 {
    position: absolute;
    height: 3px;
    width: 100%;
    background: white;
    top: calc(50% - 1.5px);
  }

  .line-2 {
    position: absolute;
    height: 100%;
    width: 3px;
    background: white;
    left: calc(50% - 1.5px);
  }
`;

export const DataHeader = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 5px;
  border-top: 2px solid rgba(255, 255, 255, 0.2);
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);

  .title {
    font-family: ${({ theme }) => theme.items.configurador.colors.selected};
    font-size: 1.2rem;
    font-weight: bold;
    color: white;

    &.normal {
      font-weight: normal;
    }
  }
`;

export const BulldozerDataContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: auto;
  height: calc(180px - 20px);
  margin: 10px 0px;
  /* background: red; */
  padding: 10px 5px;
  font-family: ${({ theme }) => theme.items.configurador.fonts.descriptions};

  .column {
    display: flex;
    height: 120px;
    max-width: 120px;
    justify-content: center;
    align-items: center;
    flex: 1;
    /* background: silver; */
    margin: 10px 10px;
    position: relative;

    .diameters {
      display: flex;
      width: 100%;
      height: 100%;
      border: 1px solid white;
      position: relative;
      justify-content: center;
      align-items: center;

      img {
        width: 80%;
      }

      .widthValue {
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: -30px;
        position: absolute;
        /* background: white; */
        width: 100%;
        color: black;

        &:before {
          position: absolute;
          content: "";
          width: 100%;
          height: 1px;
          background: white;
          z-index: -1;
        }

        label {
          background: black;
          color: white;
          padding: 2px 5px;
          font-family: ${({ theme }) =>
            theme.items.configurador.fonts.descriptions};
          font-weight: 700;
        }
      }

      .heightValue {
        display: flex;
        justify-content: center;
        align-items: center;
        /* bottom: -30px; */
        position: absolute;
        /* background: white; */
        width: 120px;
        color: black;
        transform: rotateZ(90deg);
        right: calc(-65px - 10px);

        &:before {
          position: absolute;
          content: "";
          width: 100%;
          height: 1px;
          background: white;
          z-index: -1;
        }

        label {
          background: black;
          color: white;
          padding: 2px 5px;
          font-family: ${({ theme }) =>
            theme.items.configurador.fonts.descriptions};
          font-weight: 700;
        }
      }
    }

    .circumference {
      display: flex;
      width: 100%;
      height: 100%;
      border-radius: 90%;
      border: 1px solid white;
      position: relative;
      justify-content: center;
      align-items: center;

      img {
        width: 60%;
      }

      .valueRange {
        position: absolute;
        bottom: -10px;
        background: black;
        padding: 2px 5px;
        font-family: ${({ theme }) =>
          theme.items.configurador.fonts.descriptions};
        font-weight: 700;
      }
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  /* background: red; */
  width: 100%;
  height: 50px;
  font-size: 1rem;
  font-weight: bold;
  color: black;

  a {
    flex: 1;
    margin: 5px 10px;
    border: 2px solid ${({ theme }) => theme.items.configurador.colors.selected};
    background-color: ${({ theme }) =>
      theme.items.configurador.colors.selected};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.items.configurador.colors.backgroundData};
  height: calc(100% - 330px);
  margin-top: 10px;
  padding: 10px;
  font-size: 0.85rem;
  color: white;
  overflow: scroll;

  span {
    font-weight: bold;
    margin: 10px 0px;

    label {
      margin-left: 10px;
      font-weight: 300;
      color: ${({ theme }) => theme.items.configurador.colors.greyFont};
    }
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  background-color: black;
  position: absolute;
  width: 100vw;
  top: 0;
  bottom: 40px;

  .button-submit {
    width: calc(100% - 20px);
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.items.defaults.colorsBase.primary};
    margin: 20px 10px;
  }

  .button-back {
    width: calc(100% - 20px);
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: ${({ theme }) =>
      theme.items.configurador.colors.backgroundResumeLight};
    margin: 20px 10px;
  }
`;
