import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: calc(25px - 5px);
  bottom: 50%;
  min-height: 300px;
  min-width: 350px;
  max-height: 300px;
  transform: translateY(80%);
  /* background: #fff; */
  /* overflow-y: hidden; */
  padding: 10px;
  padding-top: 5px;

  .scrollable {
    display: flex;
    flex-direction: column;
    height: 250px;
    /* background-color: red; */
    overflow: auto;

    ::-webkit-scrollbar {
      display: none;
    }
  }



  &.highlighted {
    @keyframes pulse {
      0% {
        box-shadow: 0 0 10px #ffb800;
      }
      50%{
        box-shadow: 0 0 20px #ffb800;
      }
      100% {
        box-shadow: 0 0 10px #ffb800;
      }
    }


    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid #fff;
      border-color: #ffb800;
      box-shadow: 0 0 20px #ffb800;
      border-radius: 5px;
      animation pulse 2s infinite;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  font-size: 1.7rem;
  font-weight: bold;
  opacity: 0.6;
  font-family: ${({ theme }) => theme.items.configurador.fonts.title};
  color: ${({ theme }) => theme.items.configurador.colors.greySteps};
  margin-bottom: 10px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  opacity: 0.4;
  position: relative;

  &:hover {
    .deletebutton {
      display: block;
    }
  }

  &.selected {
    opacity: 1;
  }

  .plus {
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    margin-right: 10px;
    position: relative;

    &:hover {
      cursor: pointer;

      label {
        color: #fff;
      }
    }

    label {
      font-size: 3rem;
      font-weight: bold;
      font-family: ${({ theme }) =>
        theme.items.configurador.fonts.descriptions};
      color: ${({ theme }) => theme.items.configurador.colors.greySteps};
      /* background-color: red; */
      display: flex;
    }

    .custom-border {
      display: flex;
      flex: 1;
      width: 100%;
      height: 100%;
      position: absolute;
      flex-direction: column;
      /* background-color: red; */

      .row {
        display: flex;
        flex: 1;
        width: 100%;

        .col {
          display: flex;
          flex: 1;
          /* background: silver; */
        }

        &:nth-child(1) {
          .col {
            &:nth-child(1) {
              position: relative;
              left: -0px;
              top: -0px;
              margin-right: 20px;
              margin-bottom: 10px;
              border-top: 4px solid white;
              border-left: 4px solid white;
            }

            &:nth-child(2) {
              position: relative;
              right: -3px;
              top: -0px;
              /* margin-left: 20px; */
              margin-bottom: 10px;
              border-top: 4px solid white;
              border-right: 4px solid white;
            }
          }
        }

        &:nth-child(2) {
          .col {
            &:nth-child(1) {
              position: relative;
              left: -0px;
              bottom: -3px;
              margin-right: 20px;
              margin-top: 10px;
              border-bottom: 4px solid white;
              border-left: 4px solid white;
            }

            &:nth-child(2) {
              position: relative;
              right: -3px;
              bottom: -3px;
              /* margin-left: 20px; */
              margin-top: 10px;
              border-bottom: 4px solid white;
              border-right: 4px solid white;
            }
          }
        }
      }
    }
  }

  .image {
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    margin-right: 10px;
    position: relative;

    img {
      display: flex;
      height: 55px;
      object-fit: cover;
    }

    .custom-border {
      display: flex;
      flex: 1;
      width: 100%;
      height: 100%;
      position: absolute;
      flex-direction: column;
      /* background-color: red; */

      .row {
        display: flex;
        flex: 1;
        width: 100%;

        .col {
          display: flex;
          flex: 1;
          /* background: silver; */
        }

        &:nth-child(1) {
          .col {
            &:nth-child(1) {
              position: relative;
              left: -0px;
              top: -0px;
              margin-right: 20px;
              margin-bottom: 10px;
              border-top: 4px solid white;
              border-left: 4px solid white;
            }

            &:nth-child(2) {
              position: relative;
              right: -3px;
              top: -0px;
              /* margin-left: 20px; */
              margin-bottom: 10px;
              border-top: 4px solid white;
              border-right: 4px solid white;
            }
          }
        }

        &:nth-child(2) {
          .col {
            &:nth-child(1) {
              position: relative;
              left: -0px;
              bottom: -3px;
              margin-right: 20px;
              margin-top: 10px;
              border-bottom: 4px solid white;
              border-left: 4px solid white;
            }

            &:nth-child(2) {
              position: relative;
              right: -3px;
              bottom: -3px;
              /* margin-left: 20px; */
              margin-top: 10px;
              border-bottom: 4px solid white;
              border-right: 4px solid white;
            }
          }
        }
      }
    }
  }

  .content {
    display: flex;
    flex: 1;
    justify-content: space-around;
    flex-direction: column;
    /* background-color: cyan; */

    .title {
      color: white;
      font-family: ${({ theme }) => theme.items.configurador.fonts.title};
      font-size: 1.3rem;
    }

    .description {
      font-size: 0.8rem;
      font-family: ${({ theme }) => theme.items.configurador.fonts.description};
      color: white;

      &.bold {
        font-weight: bold;
      }
    }
  }
`;

export const ProgressBar = styled.div`
  display: flex;
  position: relative;
  width: 50%;
  height: 8px;
  border: 2px solid white;
  border-radius: 8px;

  &:before {
    content: "";
    background-color: white;
    width: ${({ theme, progress }) => progress}%;
  }
`;

export const DeleteButton = styled.div`
  display: flex;
  /* background-color: red; */
  width: 30px;
  height: 30px;
  position: absolute;
  top: calc(50% - 15px);
  right: 0px;
  display: block;
  overflow: visible;
  opacity: 0;
  transition: all 0.2s linear;
  transform: translateX(-10px) translateZ(-10px);

  &:after {
    content: "";
    min-height: 100%;
    width: 0px;
    border-radius: 10px;
    background-color: white;
    position: absolute;
    left: 0;
    display: block;
    transition: all 0.2s linear;
  }

  &:before {
    content: "";
    height: 100%;
    width: 0px;
    border-radius: 10px;
    background-color: white;
    position: absolute;
    right: 0;
    display: block;
    transition: all 0.2s linear;
  }

  &.show {
    opacity: 0.6;
    transform: translateX(0) translateZ(0);

    &:before {
      width: 5px;
      transform: rotateZ(-45deg) translateX(-12px) translateY(-5px);
    }

    &:after {
      width: 5px;
      transform: rotateZ(45deg) translateX(12px) translateY(-5px);
    }

    &:hover {
      transform: scale(1.2);
      opacity: 1;
      cursor: pointer;
    }
  }
`;
