import React, { useEffect, useContext, useState, createContext } from "react";

const ModalContext = createContext();

const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState({
    show: false,
    time: 2000,
    message: "",
  });

  useEffect(() => {}, [modal]);

  return (
    <ModalContext.Provider value={{ modal, setModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) throw new Error("useModal must be used within a ModalProvider");

  const { modal, setModal } = context;

  return { modal, setModal };
};

export default ModalProvider;
