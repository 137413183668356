import React from "react";
import InputMask from "react-input-mask";
import { Container } from "./styles";

export const InputTextMask = ({
  title,
  text,
  mask,
  updateUser,
  param,
  width,
}) => {
  return (
    <Container width={width !== null ? width : null}>
      <label>{title}:</label>
      <InputMask
        mask={mask}
        defaultValue={text}
        onChange={(e) => updateUser(param, e.target.value)}
      >
        <input />
      </InputMask>
    </Container>
  );
};
