import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import RoutesTracbel from "./Routes";
import PreloadProvider from "./contexts/preload";
import ModalProvider from "./contexts/modal";
import AlertProvider from "./contexts/alert";
import UserProvider from "./contexts/user";
import ConfiguratorProvider from "./contexts/configurator";
import AuthProvider from "./contexts/auth";
import Preloader from "./shared/Components/Preloader";
import Alert from "./shared/Components/Alert";
import Modal from "./shared/Components/Modal";
import { GlobalStyles } from "./theme/GlobalStyles";
import { useTheme } from "./theme/useTheme";

function App() {
  const { theme, themeLoaded, getFonts } = useTheme();
  const [selectedTheme, setSelectedTheme] = useState(theme);

  useEffect(() => {
    setSelectedTheme(theme);
  }, [themeLoaded]);

  return (
    <>
      {themeLoaded && (
        <ThemeProvider theme={selectedTheme}>
          <AuthProvider>
            <GlobalStyles />
            <UserProvider>
              <ConfiguratorProvider>
                <PreloadProvider>
                  <ModalProvider>
                    <AlertProvider>
                      <Preloader />
                      <Alert />
                      <Modal />
                      <RoutesTracbel />
                    </AlertProvider>
                  </ModalProvider>
                </PreloadProvider>
              </ConfiguratorProvider>
            </UserProvider>
          </AuthProvider>
        </ThemeProvider>
      )}
    </>
  );
}

export default App;
