import React, { useEffect } from "react";
import { Container, Logo } from "./style";
import BurgerMenu from "./BurgerMenu";
import logo from "../../assets/images/logo-header.svg";

const Header = () => {
  return (
    <Container>
      <Logo
        onClick={() => {
          window.location.href = process.env.PUBLIC_URL;
        }}
      >
        <img src={logo} />
      </Logo>
      <BurgerMenu />
    </Container>
  );
};

export default Header;
