import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: calc(100vh - 70px);
  position: relative;
  top: 70px;
  /* background-color: red; */
`;

export const TracbelView = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;

  img {
    height: 80%;
  }
`;

export const TracbelSelector = styled.div`
  display: flex;
  width: 100%;
  height: 250px;
  background-color: black;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 1000px) {
    justify-content: flex-start;
  }

  .selectorArea {
    display: flex;
    /* background-color: red; */
    max-width: 70vw;
    min-width: 600px;
    height: auto;
    min-height: 150px;
    justify-content: center;
    align-items: center;
    /* overflow-x: scroll; */
  }

  .recommended {
    display: flex;
    position: relative;
    padding-right: 30px;
    .description-recommended {
      display: flex;
      position: absolute;
      font-size: 0.8rem;
      border-bottom: 2px solid
        ${({ theme }) => theme.items.defaults.colorsBase.primary};
      top: -50px;
      left: 5px;
      color: ${({ theme }) => theme.items.defaults.colorsBase.grey1};
    }
  }

  .others {
    display: flex;
    position: relative;
    margin-left: 30px;

    .description-recommended {
      color: ${({ theme }) => theme.items.defaults.colorsBase.grey1};
      display: flex;
      position: absolute;
      font-size: 0.8rem;
      border-bottom: 2px solid
        ${({ theme }) => theme.items.defaults.colorsBase.grey1};
      top: -50px;
      left: 5px;
    }
  }

  .divisor {
    margin: 10px 10px;
    display: block;
    width: 2px;
    height: 110px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.items.defaults.colorsBase.grey1};
    /* background-color: red; */
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100px;
  max-width: 100px;
  min-height: 140px;
  max-height: 140px;
  border: 2px solid ${({ theme }) => theme.items.defaults.colorsBase.grey1};
  border-radius: 8px;
  margin: 0px 5px;
  align-items: center;
  position: relative;

  img {
    height: 90px;
    margin: 10px 0px;
  }

  .title {
    font-size: 1rem;
    font-family: ${({ theme }) => theme.items.defaults.text.titleFont};
    color: ${({ theme }) => theme.items.defaults.colorsBase.grey1};
    margin: 10px 0px;
  }

  .confirm {
    display: none;
    position: absolute;
    bottom: -40px;
    width: 100%;
  }

  &.disabled {
    opacity: 0.8;
  }

  &.off {
    opacity: 0.3;

    &:after {
      position: absolute;
      content: "em breve";
      font-size: 0.7rem;
      line-height: 0.7rem;
      text-transform: uppercase;
      top: 50%;
    }
  }

  &.selected {
    border: 2px solid ${({ theme }) => theme.items.defaults.colorsBase.primary};

    .title {
      color: ${({ theme }) => theme.items.defaults.colorsBase.primary};
    }

    .confirm {
      display: flex;
    }
  }
`;

export const ButtonsArea = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 80px;
  width: 120px;
  right: 20px;
  align-items: center;
  justify-content: center;

  label {
    font-size: 0.8rem;
    margin-top: 10px;
    color: ${({ theme }) => theme.items.defaults.colorsBase.primary};
  }
`;
