import React, { forwardRef } from "react";

import { Container } from "./styles";

export const InstructionText = forwardRef(({ title, width, classe }, ref) => {
  return (
    <Container className={classe} width={width !== null ? width : null}>
      <label className={classe} ref={ref}>
        {title}
      </label>
    </Container>
  );
});
