import React, { useEffect, useContext, useState, createContext } from "react";

const AlertContext = createContext();

const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({
    show: false,
    time: 2000,
    message: "",
  });

  useEffect(() => {}, [alert]);

  return (
    <AlertContext.Provider value={{ alert, setAlert }}>
      {children}
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) throw new Error("useAlert must be used within a AlertProvider");

  const { alert, setAlert } = context;

  return { alert, setAlert };
};

export default AlertProvider;
