import React, { useEffect, useRef, useState } from "react";
import { Container, Header, Item, ProgressBar, DeleteButton } from "./styles";

import tracbelImage from "../../../../shared/assets/svg/tracbelNormal.svg";
import { useConfigurator } from "../../../../contexts/configurator";
export const ConfiguredVersion = ({ tutorialStep }) => {
  const [classNameDeleteButton, setClassNameDeleteButton] = useState("");
  const ref = useRef(null);
  const {
    loadSavedConfig,
    configIndexSave,
    storageConfig,
    changeModel,
    config,
    removeConfig,
    getConfigs,
  } = useConfigurator();

  useEffect(() => {
    if (ref !== null) {
      ref.current.scrollTop = ref.current.clientHeight;
    }
  }, [storageConfig, ref]);

  const handleCreateNewConfiguration = () => {
    changeModel(config?.name ?? "EC140DL");
  };

  return (
    <Container className={`${tutorialStep === 1 ? "highlighted" : ""}`}>
      <Header>
        <li>Versões configuradas</li>
      </Header>
      <div ref={ref} className="scrollable">
        {storageConfig?.map((conf, index) => (
          <Item
            className={index === configIndexSave ? "selected" : ""}
            key={index}
            onMouseOver={() => setClassNameDeleteButton("show")}
            onMouseOut={() => setClassNameDeleteButton("")}
          >
            <div className="image" onClick={() => loadSavedConfig(index)}>
              <img src={tracbelImage} />
              <div className="custom-border">
                <div className="row">
                  <div className="col"></div>
                  <div className="col"></div>
                </div>
                <div className="row">
                  <div className="col"></div>
                  <div className="col"></div>
                </div>
              </div>
            </div>
            <div className="content" onClick={() => loadSavedConfig(index)}>
              <label className="title">{conf.model}</label>
              <label className="description bold">{conf.type}</label>
            </div>
            <DeleteButton
              className={classNameDeleteButton}
              onClick={() => {
                removeConfig(index);
              }}
            />
          </Item>
        ))}
        {getConfigs().length < 3 && (
          <Item
            // className={index === configIndexSave ? "selected" : ""}
            // key={index}
            onClick={handleCreateNewConfiguration}
          >
            <div className="plus">
              <label>+</label>
              <div className="custom-border">
                <div className="row">
                  <div className="col"></div>
                  <div className="col"></div>
                </div>
                <div className="row">
                  <div className="col"></div>
                  <div className="col"></div>
                </div>
              </div>
            </div>
          </Item>
        )}
      </div>
    </Container>
  );
};

{
  /* <label className="description">6 Standards 4 configurados</label> */
}
{
  /* <ProgressBar progress={conf.progress * 100} /> */
}
