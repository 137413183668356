import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import InputMask from "react-input-mask";
import estadosCidadeJSON from "../../shared/chatbot/estados-cidades.js";
import naturezaJuridicaJSON from "../../shared/chatbot/natureza-juridica.js";
import possueMaquinasJSON from "../../shared/chatbot/possue-maquinas.js";
import quantidadeMaquinasJSON from "../../shared/chatbot/quantidade-maquinas.js";
import marcaEscavadeirasJSON from "../../shared/chatbot/marca-escavadeiras.js";
import prazoEntregaJSON from "../../shared/chatbot/prazo-entrega.js";
import segmentoAtuacaoJSON from "../../shared/chatbot/segmento-atuacao.js";
import pesoOperacionalJSON from "../../shared/chatbot/peso-operacional.js";
import { useUser } from "../../contexts/user";
import {
  Answer,
  ContainerButtons,
  RowButtons,
  Button,
  ButtonSmall,
  ButtonConfigurar,
  ButtonImplemento,
  ButtonQuantidade,
  ButtonResult,
  ButtonTextSend,
  ButtonLargeSend,
  ButtonLargeNo,
  InputTextGroup,
  InputText,
  InputSelect,
  Pular,
  SendIcon,
  SliderBar,
  SliderBarDrop,
  SliderResult,
} from "./styled";

const scrollDown = () => {
  const content = document.querySelector(".rsc-content");
  setTimeout(() => {
    content.scrollTo(0, content.scrollHeight);
  }, 250);
};

function retira_acentos(str) {
  const com_acento =
    "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
  const sem_acento =
    "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
  let novastr = "";
  for (let i = 0; i < str.length; i++) {
    let troca = false;
    for (let a = 0; a < com_acento.length; a++) {
      if (str.substr(i, 1) == com_acento.substr(a, 1)) {
        novastr += sem_acento.substr(a, 1);
        troca = true;
        break;
      }
    }
    if (troca == false) {
      novastr += str.substr(i, 1);
    }
  }
  return novastr;
}

export const AnswerUserAjuda = ({
  answer,
  triggerNextStep,
  nextID,
  pularID,
  menuRouteTo,
}) => {
  const [showOptions, setShowOptions] = useState(true);
  const [ajuda, setAjuda] = useState([
    { key: "sim", name: "Vamos lá", hide: false },
    { key: "nao", name: "Ver todos os modelos", hide: false },
  ]);
  const [buttonDisabled, setButtonDisable] = useState(false);

  useEffect(() => {
    scrollDown();
  }, []);

  const clicked = (e) => {
    let resposta = e.target.name;
    if (resposta === "sim") {
      setShowOptions(false);
      answer("ajuda", e.target.name);
      setButtonDisable(true);
      triggerNextStep({
        value: [e.target.textContent, nextID],
      });
    } else {
      menuRouteTo("/seletordemodelos");
    }
  };

  if (showOptions) {
    return (
      <>
        {ajuda.map((obj, index) => (
          <Button
            primary
            key={index}
            name={obj.key}
            onClick={clicked}
            disabled={buttonDisabled}
            style={{ display: obj.hide ? "none" : "block" }}
          >
            {obj.name}
          </Button>
        ))}
      </>
    );
  } else {
    return <></>;
  }
};

export const AnswerUserNome = ({
  answer,
  triggerNextStep,
  nextID,
  pularID,
}) => {
  const [showOptions, setShowOptions] = useState(true);
  const [buttonDisabled, setButtonDisable] = useState(false);
  const [nomePlaceholder, setNomePlaceholder] = useState("Seu nome...");

  useEffect(() => {
    scrollDown();
  }, []);

  const pularPara = (e) => {
    setShowOptions(false);
    setButtonDisable(true);
    triggerNextStep({ trigger: pularID });
  };

  const validateNome = (nome) => {
    if (nome !== "") {
      return true;
    } else {
      return false;
    }
  };

  const submited = (e) => {
    // Number 13 is the "Enter" key on the keyboard
    if (e.keyCode === 13) {
      // console.log(e.target.value);
      // Cancel the default action, if needed
      e.preventDefault();
      const validado = validateNome(e.target.value);
      if (validado) {
        setShowOptions(false);
        answer("nome", e.target.value);
        setButtonDisable(true);
        triggerNextStep({ value: [e.target.value, nextID] });
        setNomePlaceholder("Seu nome...");
      } else {
        setNomePlaceholder("Nome inválido. Digite seu nome...");
        e.target.value = "";
      }
    }
  };
  const submitedSend = (e) => {
    // Number 13 is the "Enter" key on the keyboard
    // Cancel the default action, if needed
    const elementNome = document.getElementById("nomeUser");
    e.preventDefault();
    const validado = validateNome(elementNome.value);
    if (validado) {
      setShowOptions(false);
      answer("nome", elementNome.value);
      setButtonDisable(true);
      triggerNextStep({ value: [elementNome.value, nextID] });
    } else {
      setNomePlaceholder("Nome inválido. Digite seu nome...");
      elementNome.value = "";
    }
  };

  if (showOptions) {
    return (
      <>
        <InputTextGroup>
          <InputText
            name="nomeUser"
            id="nomeUser"
            type="text"
            placeholder={nomePlaceholder}
            onKeyDown={submited}
            autoFocus
          />
          <ButtonTextSend onClick={submitedSend}>
            <SendIcon />
          </ButtonTextSend>
          {/* <Pular onClick={pularPara}>Pular</Pular> */}
        </InputTextGroup>
      </>
    );
  } else {
    return <></>;
  }
};

export const AnswerUserTelefone = ({
  answer,
  triggerNextStep,
  nextID,
  pularID,
}) => {
  const [showOptions, setShowOptions] = useState(true);
  const [buttonDisabled, setButtonDisable] = useState(false);
  const [telefonePlaceholder, setTelefonePlaceholder] = useState(
    "Seu telefone..."
  );
  const [maskTelefone, setMaskTelefone] = useState({
    value: "",
    mask: "(99)99999-9999",
  });

  useEffect(() => {
    scrollDown();
  }, []);

  const pularPara = (e) => {
    setShowOptions(false);
    setButtonDisable(true);
    triggerNextStep({ trigger: pularID });
  };

  const validateTelefone = (telefone) => {
    if (telefone.length >= 13) {
      return true;
    } else {
      return false;
    }
  };

  const onChange = (event) => {
    var value = event.target.value;

    let isMobile =
      value.substring(4, 5) !== undefined && value.substring(4, 5) === "9"
        ? true
        : false;

    var newState = {
      mask: isMobile ? "(99)99999-9999" : "(99)9999-9999",
      value: value,
    };

    setMaskTelefone(newState);
  };

  const submited = (e) => {
    // Number 13 is the "Enter" key on the keyboard
    if (e.keyCode === 13) {
      // console.log(e.target.value);
      // Cancel the default action, if needed
      e.preventDefault();
      const validado = validateTelefone(e.target.value);
      if (validado) {
        setShowOptions(false);
        answer("telefone", e.target.value);
        setButtonDisable(true);
        triggerNextStep({ value: [e.target.value, nextID] });
        setTelefonePlaceholder("Seu telefone...");
      } else {
        setTelefonePlaceholder("Telefone inválido. Digite seu telefone...");
        e.target.value = "";
      }
    }
  };
  const submitedSend = (e) => {
    // Number 13 is the "Enter" key on the keyboard
    // Cancel the default action, if needed
    const elementTelefone = document.getElementById("telefoneUser");
    e.preventDefault();
    const validado = validateTelefone(elementTelefone.value);
    if (validado) {
      setShowOptions(false);
      answer("telefone", elementTelefone.value);
      setButtonDisable(true);
      triggerNextStep({ value: [elementTelefone.value, nextID] });
    } else {
      setTelefonePlaceholder("Telefone inválido. Digite seu telefone...");
      elementTelefone.value = "";
    }
  };

  if (showOptions) {
    return (
      <>
        <InputTextGroup>
          <InputMask
            mask={maskTelefone && maskTelefone.mask}
            value={maskTelefone && maskTelefone.value}
            onChange={onChange}
          >
            <InputText
              name="telefoneUser"
              id="telefoneUser"
              type="tel"
              placeholder={telefonePlaceholder}
              onKeyDown={submited}
              autoFocus
              autoComplete="off"
            />
          </InputMask>
          <ButtonTextSend onClick={submitedSend}>
            <SendIcon />
          </ButtonTextSend>
          <Pular onClick={pularPara}>Pular</Pular>
        </InputTextGroup>
      </>
    );
  } else {
    return <></>;
  }
};

export const AnswerUserEmail = ({
  answer,
  triggerNextStep,
  nextID,
  pularID,
}) => {
  const [showOptions, setShowOptions] = useState(true);
  const [buttonDisabled, setButtonDisable] = useState(false);
  const [emailPlaceholder, setEmailPlaceholder] = useState("Seu e-mail...");

  useEffect(() => {
    scrollDown();
  }, []);

  const pularPara = (e) => {
    setShowOptions(false);
    setButtonDisable(true);
    triggerNextStep({ trigger: pularID });
  };

  const validateEmail = (mail) => {
    const mailformat = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    if (mail.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  };
  const submited = (e) => {
    // Number 13 is the "Enter" key on the keyboard
    if (e.keyCode === 13) {
      // console.log(e.target.value);
      // Cancel the default action, if needed
      e.preventDefault();
      const validado = validateEmail(e.target.value);
      if (validado) {
        setShowOptions(false);
        answer("email", e.target.value);
        setButtonDisable(true);
        triggerNextStep({ value: [e.target.value, nextID] });
        setEmailPlaceholder("Seu e-mail...");
      } else {
        setEmailPlaceholder("E-mail inválido. Digite seu e-mail...");
        e.target.value = "";
      }
    }
  };
  const submitedSend = (e) => {
    // Number 13 is the "Enter" key on the keyboard
    // Cancel the default action, if needed
    const elementEmail = document.getElementById("emailUser");
    e.preventDefault();
    const validado = validateEmail(elementEmail.value);
    if (validado) {
      setShowOptions(false);
      answer("email", elementEmail.value);
      setButtonDisable(true);
      triggerNextStep({ value: [elementEmail.value, nextID] });
    } else {
      setEmailPlaceholder("E-mail inválido. Digite seu e-mail...");
      elementEmail.value = "";
    }
  };

  if (showOptions) {
    return (
      <>
        <InputTextGroup>
          <InputText
            name="emailUser"
            id="emailUser"
            type="email"
            placeholder={emailPlaceholder}
            onKeyDown={submited}
            autoFocus
            autoComplete="off"
          />
          <ButtonTextSend onClick={submitedSend}>
            <SendIcon />
          </ButtonTextSend>
          {/* <Pular onClick={pularPara}>Pular</Pular> */}
        </InputTextGroup>
      </>
    );
  } else {
    return <></>;
  }
};

export const AnswerUserUF = ({ answer, triggerNextStep, nextID, pularID }) => {
  const [showOptions, setShowOptions] = useState(true);
  const [buttonDisabled, setButtonDisable] = useState(false);
  const [estadoPlaceholder, setEstadoPlaceholder] = useState("Seu estado...");

  useEffect(() => {
    scrollDown();
  }, []);

  const pularPara = (e) => {
    setShowOptions(false);
    setButtonDisable(true);
    triggerNextStep({ trigger: pularID });
  };

  const submited = (e) => {
    // Number 13 is the "Enter" key on the keyboard
    if (e.keyCode === 13) {
      // console.log(e.target.value);
      // Cancel the default action, if needed
      e.preventDefault();
      setShowOptions(false);
      answer("estado", e.target.value);
      setButtonDisable(true);
      triggerNextStep({ value: [e.target.value, nextID] });
      setEstadoPlaceholder("UF...");
    }
  };
  const submitedSend = (e) => {
    // Number 13 is the "Enter" key on the keyboard
    // Cancel the default action, if needed
    const elementEstado = document.getElementById("estadoUser");
    e.preventDefault();
    setShowOptions(false);
    answer("estado", elementEstado.value);
    setButtonDisable(true);
    triggerNextStep({ value: [elementEstado.value, nextID] });
  };

  if (showOptions) {
    return (
      <>
        <InputTextGroup>
          <InputSelect
            name="estadoUser"
            id="estadoUser"
            placeholder={estadoPlaceholder}
            onKeyDown={submited}
            autoFocus
            autoComplete="off"
          >
            {estadosCidadeJSON.estados.map((value, index) => {
              return (
                <option key={index + "estados"} value={value.sigla}>
                  {value.sigla}
                </option>
              );
            })}
          </InputSelect>
          <ButtonTextSend onClick={submitedSend}>
            <SendIcon />
          </ButtonTextSend>
          {/* <Pular onClick={pularPara}>Pular</Pular> */}
        </InputTextGroup>
      </>
    );
  } else {
    return <></>;
  }
};

export const AnswerUserNaturezaJuridica = ({
  answer,
  triggerNextStep,
  nextID,
  pularID,
}) => {
  const { user } = useUser();
  const [showOptions, setShowOptions] = useState(true);
  const [buttonDisabled, setButtonDisable] = useState(false);
  const [
    naturezaJuridicaPlaceholder,
    setNaturezaJuridicaPlaceholder,
  ] = useState("Sua Natureza Jurídica...");
  const [naturezaJuridicaJSONFinal, setNaturezaJuridicaJSONFinal] = useState(
    null
  );
  const clone = require("rfdc")();
  const naturezaJuridicaJSONTemp = clone(naturezaJuridicaJSON.naturezaJuridica);

  useEffect(() => {
    scrollDown();
  }, []);

  const pularPara = (e) => {
    setShowOptions(false);
    setButtonDisable(true);
    triggerNextStep({ trigger: pularID });
  };

  const submited = (e) => {
    // Number 13 is the "Enter" key on the keyboard
    if (e.keyCode === 13) {
      // console.log(e.target.value);
      // Cancel the default action, if needed
      e.preventDefault();
      setShowOptions(false);
      answer("naturezaJuridica", e.target.value);
      setButtonDisable(true);
      triggerNextStep({
        value: [
          e.target.value,
          e.target[e.target.selectedIndex].getAttribute("data-nextid"),
        ],
      });
      setNaturezaJuridicaPlaceholder("Sua Natureza Jurídica...");
    }
  };

  const submitedSend = (e) => {
    // Number 13 is the "Enter" key on the keyboard
    // Cancel the default action, if needed
    const elementNaturezaJuridica = document.getElementById(
      "naturezaJuridicaUser"
    );
    e.preventDefault();
    setShowOptions(false);
    answer("naturezaJuridica", elementNaturezaJuridica.value);
    setButtonDisable(true);
    triggerNextStep({
      value: [
        elementNaturezaJuridica.value,
        elementNaturezaJuridica[
          elementNaturezaJuridica.selectedIndex
        ].getAttribute("data-nextid"),
      ],
    });
  };

  useEffect(() => {
    if (user.estado === "SP") {
      naturezaJuridicaJSONTemp.splice(2, 1);
      setNaturezaJuridicaJSONFinal(naturezaJuridicaJSONTemp);
    } else if (
      user.estado !== "SP" &&
      user.estado !== "" &&
      user.estado !== null
    ) {
      naturezaJuridicaJSONTemp.splice(1, 1);
      setNaturezaJuridicaJSONFinal(naturezaJuridicaJSONTemp);
    }
  }, [user]);

  if (showOptions) {
    return (
      <>
        <InputTextGroup>
          <InputSelect
            name="naturezaJuridicaUser"
            id="naturezaJuridicaUser"
            placeholder={naturezaJuridicaPlaceholder}
            autoFocus
            onKeyDown={submited}
          >
            {naturezaJuridicaJSONFinal !== null &&
              naturezaJuridicaJSONFinal.map((value, index) => {
                return (
                  <option
                    key={index + "naturezaJuridica"}
                    value={value.nome}
                    data-nextid={value.nextID}
                  >
                    {`${value.nome}`}
                  </option>
                );
              })}
          </InputSelect>
          <ButtonTextSend onClick={submitedSend}>
            <SendIcon />
          </ButtonTextSend>
          <Pular onClick={pularPara}>Pular</Pular>
        </InputTextGroup>
      </>
    );
  } else {
    return <></>;
  }
};

export const AnswerUserRazaoSocial = ({
  answer,
  triggerNextStep,
  nextID,
  pularID,
}) => {
  const [showOptions, setShowOptions] = useState(true);
  const [buttonDisabled, setButtonDisable] = useState(false);
  const [razaoSocialPlaceholder, setRazaoSocialPlaceholder] = useState(
    "Razão social..."
  );

  useEffect(() => {
    scrollDown();
  }, []);

  const pularPara = (e) => {
    setShowOptions(false);
    setButtonDisable(true);
    triggerNextStep({ trigger: pularID });
  };

  const validateRazaoSocial = (razaoSocial) => {
    if (razaoSocial !== "") {
      return true;
    } else {
      return false;
    }
  };

  const submited = (e) => {
    // Number 13 is the "Enter" key on the keyboard
    if (e.keyCode === 13) {
      // console.log(e.target.value);
      // Cancel the default action, if needed
      e.preventDefault();
      const validado = validateRazaoSocial(e.target.value);
      if (validado) {
        setShowOptions(false);
        answer("razaoSocial", e.target.value);
        setButtonDisable(true);
        triggerNextStep({ value: [e.target.value, nextID] });
        setRazaoSocialPlaceholder("Sua Razão Social...");
      } else {
        setRazaoSocialPlaceholder(
          "Razão Social inválida. Digite sua Razão Social..."
        );
        e.target.value = "";
      }
    }
  };
  const submitedSend = (e) => {
    // Number 13 is the "Enter" key on the keyboard
    // Cancel the default action, if needed
    const elementRazaoSocial = document.getElementById("razaoSocialUser");
    e.preventDefault();
    const validado = validateRazaoSocial(elementRazaoSocial.value);
    if (validado) {
      setShowOptions(false);
      answer("razaoSocial", elementRazaoSocial.value);
      setButtonDisable(true);
      triggerNextStep({ value: [elementRazaoSocial.value, nextID] });
    } else {
      setRazaoSocialPlaceholder(
        "Razão Social inválida. Digite sua Razão Social..."
      );
      elementRazaoSocial.value = "";
    }
  };

  if (showOptions) {
    return (
      <>
        <InputTextGroup>
          <InputText
            name="razaoSocialUser"
            id="razaoSocialUser"
            type="text"
            placeholder={razaoSocialPlaceholder}
            onKeyDown={submited}
            autoFocus
          />
          <ButtonTextSend onClick={submitedSend}>
            <SendIcon />
          </ButtonTextSend>
          <Pular onClick={pularPara}>Pular</Pular>
        </InputTextGroup>
      </>
    );
  } else {
    return <></>;
  }
};

export const AnswerUserInscricaoEstadualSP = ({
  answer,
  triggerNextStep,
  nextID,
  pularID,
}) => {
  const [showOptions, setShowOptions] = useState(true);
  const [buttonDisabled, setButtonDisable] = useState(false);
  const [
    inscricaoEstadualSPPlaceholder,
    setInscricaoEstadualSPPlaceholder,
  ] = useState("Inscrição Estadual...");

  useEffect(() => {
    scrollDown();
  }, []);

  const pularPara = (e) => {
    setShowOptions(false);
    setButtonDisable(true);
    triggerNextStep({ trigger: pularID });
  };

  const validateInscricaoEstadualSP = (inscricaoEstadualSP) => {
    if (inscricaoEstadualSP !== "") {
      return true;
    } else {
      return false;
    }
  };

  const submited = (e) => {
    // Number 13 is the "Enter" key on the keyboard
    if (e.keyCode === 13) {
      // console.log(e.target.value);
      // Cancel the default action, if needed
      e.preventDefault();
      const validado = validateInscricaoEstadualSP(e.target.value);
      if (validado) {
        setShowOptions(false);
        answer("inscricaoEstadualSP", e.target.value);
        setButtonDisable(true);
        triggerNextStep({ value: [e.target.value, nextID] });
        setInscricaoEstadualSPPlaceholder("Sua inscricão Estadual...");
      } else {
        setInscricaoEstadualSPPlaceholder(
          "Inscrição Estadual inválida. Digite sua Inscrição Estadual..."
        );
        e.target.value = "";
      }
    }
  };
  const submitedSend = (e) => {
    // Number 13 is the "Enter" key on the keyboard
    // Cancel the default action, if needed
    const elementInscricaoEstadualSP = document.getElementById(
      "inscricaoEstadualSPUser"
    );
    e.preventDefault();
    const validado = validateInscricaoEstadualSP(
      elementInscricaoEstadualSP.value
    );
    if (validado) {
      setShowOptions(false);
      answer("inscricaoEstadualSP", elementInscricaoEstadualSP.value);
      setButtonDisable(true);
      triggerNextStep({ value: [elementInscricaoEstadualSP.value, nextID] });
    } else {
      setInscricaoEstadualSPPlaceholder(
        "Inscriçãp Estadual inválida. Digite sua inscricão Estadual..."
      );
      elementInscricaoEstadualSP.value = "";
    }
  };

  if (showOptions) {
    return (
      <>
        <InputTextGroup>
          <InputText
            name="inscricaoEstadualSPUser"
            id="inscricaoEstadualSPUser"
            type="text"
            placeholder={inscricaoEstadualSPPlaceholder}
            onKeyDown={submited}
            autoFocus
            maxLength={14}
          />
          <ButtonTextSend onClick={submitedSend}>
            <SendIcon />
          </ButtonTextSend>
          <Pular onClick={pularPara}>Pular</Pular>
        </InputTextGroup>
      </>
    );
  } else {
    return <></>;
  }
};

export const AnswerUserInscricaoEstadual = ({
  answer,
  triggerNextStep,
  nextID,
  pularID,
}) => {
  const [showOptions, setShowOptions] = useState(true);
  const [buttonDisabled, setButtonDisable] = useState(false);
  const [
    inscricaoEstadualPlaceholder,
    setInscricaoEstadualPlaceholder,
  ] = useState("Inscrição Estadual...");

  useEffect(() => {
    scrollDown();
  }, []);

  const pularPara = (e) => {
    setShowOptions(false);
    setButtonDisable(true);
    triggerNextStep({ trigger: pularID });
  };

  const validateInscricaoEstadual = (inscricaoEstadual) => {
    if (inscricaoEstadual !== "") {
      return true;
    } else {
      return false;
    }
  };

  const submited = (e) => {
    // Number 13 is the "Enter" key on the keyboard
    if (e.keyCode === 13) {
      // console.log(e.target.value);
      // Cancel the default action, if needed
      e.preventDefault();
      const validado = validateInscricaoEstadual(e.target.value);
      if (validado) {
        setShowOptions(false);
        answer("inscricaoEstadual", e.target.value);
        setButtonDisable(true);
        triggerNextStep({ value: [e.target.value, nextID] });
        setInscricaoEstadualPlaceholder("Sua inscricão Estadual...");
      } else {
        setInscricaoEstadualPlaceholder(
          "Inscrição Estadual inválida. Digite sua Inscrição Estadual..."
        );
        e.target.value = "";
      }
    }
  };
  const submitedSend = (e) => {
    // Number 13 is the "Enter" key on the keyboard
    // Cancel the default action, if needed
    const elementInscricaoEstadual = document.getElementById(
      "inscricaoEstadualUser"
    );
    e.preventDefault();
    const validado = validateInscricaoEstadual(elementInscricaoEstadual.value);
    if (validado) {
      setShowOptions(false);
      answer("inscricaoEstadual", elementInscricaoEstadual.value);
      setButtonDisable(true);
      triggerNextStep({ value: [elementInscricaoEstadual.value, nextID] });
    } else {
      setInscricaoEstadualPlaceholder(
        "Inscriçãp Estadual inválida. Digite sua inscricão Estadual..."
      );
      elementInscricaoEstadual.value = "";
    }
  };

  if (showOptions) {
    return (
      <>
        <InputTextGroup>
          <InputText
            name="inscricaoEstadualUser"
            id="inscricaoEstadualUser"
            type="text"
            placeholder={inscricaoEstadualPlaceholder}
            onKeyDown={submited}
            autoFocus
            maxLength={14}
          />
          <ButtonTextSend onClick={submitedSend}>
            <SendIcon />
          </ButtonTextSend>
          <Pular onClick={pularPara}>Pular</Pular>
        </InputTextGroup>
      </>
    );
  } else {
    return <></>;
  }
};

export const AnswerUserCNPJ = ({
  answer,
  triggerNextStep,
  nextID,
  pularID,
}) => {
  const [showOptions, setShowOptions] = useState(true);
  const [buttonDisabled, setButtonDisable] = useState(false);
  const [cnpjPlaceholder, setCNPJPlaceholder] = useState("Seu cnpj...");
  const [maskCNPJ, setMaskCNPJ] = useState({
    value: "",
    mask: "99 999 999/9999-99",
  });

  useEffect(() => {
    scrollDown();
  }, []);

  const pularPara = (e) => {
    setShowOptions(false);
    setButtonDisable(true);
    triggerNextStep({ trigger: pularID });
  };

  const validateCNPJ = (cnpj) => {
    if (cnpj === null) {
      return false;
    }
    cnpj = cnpj
      .replace(/\./g, "")
      .replace(/\-/g, "")
      .replace(/\//g, "");

    if (cnpj == "") return false;

    if (cnpj.length != 14) return false;

    // Elimina CNPJs invalidos conhecidos
    // console.log(cnpj);
    if (
      cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999"
    )
      return false;

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) return false;

    return true;
    // if (value !== null) {
    //   if (value !== "") {
    //     var regExp = /[_]/g;
    //     return !regExp.test(value);
    //   } else {
    //     return false;
    //   }
    // } else {
    //   return false;
    // }
  };

  const onChange = (event) => {
    var value = event.target.value;
    var newState = {
      mask: "99 999 999/9999-99",
      value: value,
    };
    setMaskCNPJ(newState);
  };

  const InputCNPJ = (props) => (
    <InputMask mask={maskCNPJ.mask} value={maskCNPJ.value} onChange={onChange}>
      {maskCNPJ && (
        <InputText
          name="cnpjUser"
          id="cnpjUser"
          type="tel"
          placeholder={cnpjPlaceholder}
          onKeyDown={submited}
          autoFocus
          disableUnderline
        />
      )}
    </InputMask>
  );

  const submited = (e) => {
    // Number 13 is the "Enter" key on the keyboard
    if (e.keyCode === 13) {
      // console.log(e.target.value);
      // Cancel the default action, if needed
      e.preventDefault();
      let elementCNPJNew = e.target.value.replaceAll(" ", ".");
      const validado = validateCNPJ(elementCNPJNew);
      if (validado) {
        setShowOptions(false);
        answer("cnpj", elementCNPJNew);
        setButtonDisable(true);
        triggerNextStep({ value: [elementCNPJNew, nextID] });
        setCNPJPlaceholder("Seu cnpj...");
      } else {
        setCNPJPlaceholder("CPF inválido. Digite seu cnpj...");
        e.target.value = "";
      }
    }
  };

  const submitedSend = (e) => {
    // Number 13 is the "Enter" key on the keyboard
    // Cancel the default action, if needed
    const elementCNPJ = document.getElementById("cnpjUser");
    e.preventDefault();
    let elementCNPJNew = elementCNPJ.value.replaceAll(" ", ".");
    const validado = validateCNPJ(elementCNPJNew);
    if (validado) {
      setShowOptions(false);
      answer("cnpj", elementCNPJNew);
      setButtonDisable(true);
      triggerNextStep({ value: [elementCNPJNew, nextID] });
    } else {
      setCNPJPlaceholder("CNPJ inválido. Digite seu cnpj...");
      elementCNPJ.value = "";
    }
  };

  if (showOptions) {
    return (
      <>
        <InputTextGroup>
          <InputCNPJ />
          <ButtonTextSend onClick={submitedSend}>
            <SendIcon />
          </ButtonTextSend>
          <Pular onClick={pularPara}>Pular</Pular>
        </InputTextGroup>
      </>
    );
  } else {
    return <></>;
  }
};

export const AnswerUserCpf = ({ answer, triggerNextStep, nextID, pularID }) => {
  const [showOptions, setShowOptions] = useState(true);
  const [buttonDisabled, setButtonDisable] = useState(false);
  const [cpfPlaceholder, setCpfPlaceholder] = useState("Seu cpf...");
  const [maskCpf, setMaskCpf] = useState({
    value: "",
    mask: "999 999 999-99",
  });

  useEffect(() => {
    scrollDown();
  }, []);

  const pularPara = (e) => {
    setShowOptions(false);
    setButtonDisable(true);
    triggerNextStep({ trigger: pularID });
  };

  const validateCPF = (cpf) => {
    if (cpf !== null) {
      cpf = cpf.replace(/[^\d]+/g, "");
      if (cpf == "") return false;
      // Elimina CPFs invalidos conhecidos
      if (
        cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999"
      )
        return false;
      // Valida 1o digito
      let add = 0;
      for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
      let rev = 11 - (add % 11);
      if (rev == 10 || rev == 11) rev = 0;
      if (rev != parseInt(cpf.charAt(9))) return false;
      // Valida 2o digito
      add = 0;
      for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
      rev = 11 - (add % 11);
      if (rev == 10 || rev == 11) rev = 0;
      if (rev != parseInt(cpf.charAt(10))) return false;
      return true;
    } else {
      return false;
    }
  };

  const onChange = (event) => {
    var value = event.target.value;
    var newState = {
      mask: "999 999 999-99",
      value: value,
    };

    setMaskCpf(newState);
  };

  const InputCpf = (props) => (
    <InputMask
      mask={maskCpf && maskCpf.mask}
      value={maskCpf && maskCpf.value}
      onChange={onChange}
    >
      <InputText
        name="cpfUser"
        id="cpfUser"
        type="tel"
        placeholder={cpfPlaceholder}
        onKeyDown={submited}
        autoFocus
        disableUnderline
      />
    </InputMask>
  );

  const submited = (e) => {
    // Number 13 is the "Enter" key on the keyboard
    if (e.keyCode === 13) {
      // console.log(e.target.value);
      // Cancel the default action, if needed
      e.preventDefault();
      let elementCpfNew = e.target.value.replaceAll(" ", ".");
      const validado = validateCPF(elementCpfNew);
      if (validado) {
        setShowOptions(false);
        answer("cpf", elementCpfNew);
        setButtonDisable(true);
        triggerNextStep({ value: [elementCpfNew, nextID] });
        setCpfPlaceholder("Seu cpf...");
      } else {
        setCpfPlaceholder("CPF inválido. Digite seu cpf...");
        e.target.value = "";
      }
    }
  };

  const submitedSend = (e) => {
    // Number 13 is the "Enter" key on the keyboard
    // Cancel the default action, if needed
    const elementCpf = document.getElementById("cpfUser");
    e.preventDefault();
    let elementCpfNew = elementCpf.value.replaceAll(" ", ".");
    const validado = validateCPF(elementCpfNew);
    if (validado) {
      setShowOptions(false);
      answer("cpf", elementCpfNew);
      setButtonDisable(true);
      triggerNextStep({ value: [elementCpfNew, nextID] });
    } else {
      setCpfPlaceholder("Cpf inválido. Digite seu cpf...");
      elementCpf.value = "";
    }
  };

  if (showOptions) {
    return (
      <>
        <InputTextGroup>
          <InputCpf />
          <ButtonTextSend onClick={submitedSend}>
            <SendIcon />
          </ButtonTextSend>
          <Pular onClick={pularPara}>Pular</Pular>
        </InputTextGroup>
      </>
    );
  } else {
    return <></>;
  }
};

export const AnswerUserPossueMaquinas = ({
  answer,
  triggerNextStep,
  nextID,
  pularID,
}) => {
  const [showOptions, setShowOptions] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState();
  const [buttonDisabled, setButtonDisable] = useState(false);
  const [botoes, setBotoes] = useState(possueMaquinasJSON);
  const [hasItems, setHasItems] = useState({
    escavadeira: false,
    maquina: false,
  });

  useEffect(() => {
    scrollDown();
  }, []);

  const pularPara = (e) => {
    setShowOptions(false);
    setButtonDisable(true);
    triggerNextStep({ trigger: pularID });
  };

  const clickedNo = (id) => {
    answer("possueMaquinas", "não");
    setButtonDisable(true);
    triggerNextStep({ trigger: id });
  };

  const clicked = (e, index) => {
    let tempBotoes = JSON.parse(JSON.stringify(botoes));
    tempBotoes.possueMaquinas[index].clicked = !tempBotoes.possueMaquinas[index]
      .clicked;
    if (tempBotoes.possueMaquinas[index].isEscavadeira) {
      setHasItems((values) => ({
        ...values,
        escavadeira: true,
      }));
    } else {
      setHasItems((values) => ({
        ...values,
        maquina: true,
      }));
    }
    setBotoes(tempBotoes);
  };

  const submitedSend = (e) => {
    // Number 13 is the "Enter" key on the keyboard
    // Cancel the default action, if needed
    e.preventDefault();
    if (selectedOptions.length > 0) {
      setShowOptions(false);
      answer("possueMaquinas", selectedOptions);
      setButtonDisable(true);
      triggerNextStep({ value: [selectedOptions, nextID] });
    }
  };

  useEffect(() => {
    let selectedOptionsTemp = botoes.possueMaquinas.filter(
      (value) => value.clicked
    );
    let selectedOptionsNomesTemp = [];
    selectedOptionsTemp.map((value, index) => {
      selectedOptionsNomesTemp.push(value.nome);
    });
    setSelectedOptions(selectedOptionsNomesTemp);
  }, [botoes]);

  useEffect(() => {
    // console.log(selectedOptions);
  }, [selectedOptions]);

  if (showOptions) {
    return (
      <>
        <ContainerButtons>
          {botoes.possueMaquinas.map((value, index) => {
            return (
              <div key={index} style={{ display: "contents" }}>
                {value.nextID ===
                "questionUserQuantidadeMaquinasEscavadeiras" ? (
                  <ButtonSmall
                    primary
                    name={value.name}
                    onClick={(e) => {
                      clicked(e, index);
                    }}
                    disabled={buttonDisabled}
                    className={value.clicked && "clicked"}
                  >
                    {value.nome}
                  </ButtonSmall>
                ) : (
                  <ButtonLargeNo
                    onClick={(e) => {
                      clickedNo(value.nextID);
                    }}
                  >
                    {value.nome}
                  </ButtonLargeNo>
                )}
              </div>
            );
          })}
          <ButtonLargeSend onClick={submitedSend}>
            <SendIcon />
          </ButtonLargeSend>
          <Pular onClick={pularPara}>Pular</Pular>
        </ContainerButtons>
      </>
    );
  } else {
    return <></>;
  }
};

export const AnswerUserQuantidadeMaquinasEscavadeiras = ({
  answer,
  triggerNextStep,
  nextID,
  pularID,
}) => {
  const [showOptions, setShowOptions] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState();
  const [buttonDisabled, setButtonDisable] = useState(false);
  const [botoes, setBotoes] = useState(quantidadeMaquinasJSON);

  useEffect(() => {
    scrollDown();
  }, []);

  const pularPara = (e) => {
    setShowOptions(false);
    setButtonDisable(true);
    triggerNextStep({ trigger: pularID });
  };

  const clickedNo = (id) => {
    triggerNextStep({ trigger: id });
  };

  const clicked = (e, index) => {
    let tempBotoes = JSON.parse(JSON.stringify(botoes));
    tempBotoes.quantidadeMaquinas.map(
      (value, index) => (value.clicked = false)
    );
    tempBotoes.quantidadeMaquinas[index].clicked = true;
    setBotoes(tempBotoes);
  };

  const submitedSend = (e) => {
    e.preventDefault();
    if (selectedOptions.length > 0) {
      setShowOptions(false);
      answer("quantidadeMaquinas", selectedOptions);
      setButtonDisable(true);
      triggerNextStep({ value: [selectedOptions, nextID] });
    }
  };

  useEffect(() => {
    let selectedOptionsTemp = botoes.quantidadeMaquinas.filter(
      (value) => value.clicked
    );
    selectedOptionsTemp.map((value, index) => {
      setSelectedOptions(value.nome);
    });
  }, [botoes]);

  useEffect(() => {
    // console.log(selectedOptions);
  }, [selectedOptions]);

  if (showOptions) {
    return (
      <>
        <ContainerButtons>
          {botoes.quantidadeMaquinas.map((value, index) => {
            return (
              <div key={index} style={{ display: "contents" }}>
                <ButtonSmall
                  primary
                  name={value.name}
                  onClick={(e) => {
                    clicked(e, index);
                  }}
                  disabled={buttonDisabled}
                  className={value.clicked && "clicked"}
                >
                  {value.nome}
                </ButtonSmall>
              </div>
            );
          })}
          <ButtonLargeSend onClick={submitedSend}>
            <SendIcon />
          </ButtonLargeSend>
          <Pular onClick={pularPara}>Pular</Pular>
        </ContainerButtons>
      </>
    );
  } else {
    return <></>;
  }
};

export const AnswerUserQuantidadeMaquinas = ({
  answer,
  triggerNextStep,
  nextID,
  pularID,
}) => {
  const { user } = useUser();
  const [showOptions, setShowOptions] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState();
  const [buttonDisabled, setButtonDisable] = useState(false);
  const [botoes, setBotoes] = useState(quantidadeMaquinasJSON);

  useEffect(() => {
    scrollDown();
  }, []);

  const pularPara = (e) => {
    setShowOptions(false);
    setButtonDisable(true);
    triggerNextStep({ trigger: pularID });
  };

  const clickedNo = (id) => {
    triggerNextStep({ trigger: id });
  };

  const clicked = (e, index) => {
    let tempBotoes = JSON.parse(JSON.stringify(botoes));
    tempBotoes.quantidadeMaquinas.map(
      (value, index) => (value.clicked = false)
    );
    tempBotoes.quantidadeMaquinas[index].clicked = true;
    setBotoes(tempBotoes);
  };

  const submitedSend = (e) => {
    e.preventDefault();
    if (selectedOptions.length > 0) {
      setShowOptions(false);
      answer("quantidadeMaquinas", selectedOptions);
      setButtonDisable(true);
      let next =
        user.possueMaquinas.indexOf("Escavadeira") !== -1
          ? "questionUserMarcaEscavadeiras"
          : nextID;
      // console.log(selectedOptions, next);
      triggerNextStep({ value: [selectedOptions, next] });
    }
  };

  useEffect(() => {
    let selectedOptionsTemp = botoes.quantidadeMaquinas.filter(
      (value) => value.clicked
    );
    // console.log(selectedOptionsTemp);
    let selectedOptionsNomesTemp = [];
    selectedOptionsTemp.map((value, index) => {
      setSelectedOptions(value.nome);
    });
  }, [botoes]);

  useEffect(() => {
    // console.log(selectedOptions);
  }, [selectedOptions]);

  if (showOptions) {
    return (
      <>
        <ContainerButtons>
          {botoes.quantidadeMaquinas.map((value, index) => {
            return (
              <div key={index} style={{ display: "contents" }}>
                <ButtonSmall
                  primary
                  name={value.name}
                  onClick={(e) => {
                    clicked(e, index);
                  }}
                  disabled={buttonDisabled}
                  className={value.clicked && "clicked"}
                >
                  {value.nome}
                </ButtonSmall>
              </div>
            );
          })}
          <ButtonLargeSend onClick={submitedSend}>
            <SendIcon />
          </ButtonLargeSend>
          <Pular onClick={pularPara}>Pular</Pular>
        </ContainerButtons>
      </>
    );
  } else {
    return <></>;
  }
};

export const AnswerUserMarcaEscavadeiras = ({
  answer,
  triggerNextStep,
  nextID,
  pularID,
}) => {
  const [showOptions, setShowOptions] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState();
  const [buttonDisabled, setButtonDisable] = useState(false);
  const [botoes, setBotoes] = useState(marcaEscavadeirasJSON);
  const [hasItems, setHasItems] = useState({
    escavadeira: false,
    maquina: false,
  });

  useEffect(() => {
    scrollDown();
  }, []);

  const pularPara = (e) => {
    setShowOptions(false);
    setButtonDisable(true);
    triggerNextStep({ trigger: pularID });
  };

  const clickedNo = (id) => {
    answer("marcaEscavadeiras", "não");
    setButtonDisable(true);
    triggerNextStep({ trigger: id });
  };

  const clicked = (e, index) => {
    let tempBotoes = JSON.parse(JSON.stringify(botoes));
    tempBotoes.marcaEscavadeiras[index].clicked = !tempBotoes.marcaEscavadeiras[
      index
    ].clicked;
    if (tempBotoes.marcaEscavadeiras[index].isEscavadeira) {
      setHasItems((values) => ({
        ...values,
        escavadeira: true,
      }));
    } else {
      setHasItems((values) => ({
        ...values,
        maquina: true,
      }));
    }
    setBotoes(tempBotoes);
  };

  const submitedSend = (e) => {
    // Number 13 is the "Enter" key on the keyboard
    // Cancel the default action, if needed
    e.preventDefault();
    if (selectedOptions.length > 0) {
      setShowOptions(false);
      answer("marcaEscavadeiras", selectedOptions);
      setButtonDisable(true);
      triggerNextStep({ value: [selectedOptions, nextID] });
    }
  };

  useEffect(() => {
    let selectedOptionsTemp = botoes.marcaEscavadeiras.filter(
      (value) => value.clicked
    );
    let selectedOptionsNomesTemp = [];
    selectedOptionsTemp.map((value, index) => {
      selectedOptionsNomesTemp.push(value.nome);
    });
    setSelectedOptions(selectedOptionsNomesTemp);
  }, [botoes]);

  useEffect(() => {
    // console.log(selectedOptions);
  }, [selectedOptions]);

  if (showOptions) {
    return (
      <>
        <ContainerButtons>
          {botoes.marcaEscavadeiras.map((value, index) => {
            return (
              <div key={index} style={{ display: "contents" }}>
                <ButtonSmall
                  primary
                  name={value.name}
                  onClick={(e) => {
                    clicked(e, index);
                  }}
                  disabled={buttonDisabled}
                  className={value.clicked && "clicked"}
                >
                  {value.nome}
                </ButtonSmall>
              </div>
            );
          })}
          <ButtonLargeSend onClick={submitedSend}>
            <SendIcon />
          </ButtonLargeSend>
          <Pular onClick={pularPara}>Pular</Pular>
        </ContainerButtons>
      </>
    );
  } else {
    return <></>;
  }
};

export const AnswerUserPrazoEntrega = ({
  answer,
  triggerNextStep,
  nextID,
  pularID,
}) => {
  const [showOptions, setShowOptions] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState();
  const [buttonDisabled, setButtonDisable] = useState(false);
  const [botoes, setBotoes] = useState(prazoEntregaJSON);

  useEffect(() => {
    scrollDown();
  }, []);

  const pularPara = (e) => {
    setShowOptions(false);
    setButtonDisable(true);
    triggerNextStep({ trigger: pularID });
  };

  const clickedNo = (id) => {
    triggerNextStep({ trigger: id });
  };

  const clicked = (e, index) => {
    let tempBotoes = JSON.parse(JSON.stringify(botoes));
    tempBotoes.prazoEntrega.map((value, index) => (value.clicked = false));
    tempBotoes.prazoEntrega[index].clicked = true;
    setBotoes(tempBotoes);
  };

  const submitedSend = (e) => {
    e.preventDefault();
    if (selectedOptions.length > 0) {
      setShowOptions(false);
      answer("prazoEntrega", selectedOptions);
      setButtonDisable(true);
      triggerNextStep({ value: [selectedOptions, nextID] });
    }
  };

  useEffect(() => {
    let selectedOptionsTemp = botoes.prazoEntrega.filter(
      (value) => value.clicked
    );
    selectedOptionsTemp.map((value, index) => {
      setSelectedOptions(value.nome);
    });
  }, [botoes]);

  useEffect(() => {
    // console.log(selectedOptions);
  }, [selectedOptions]);

  if (showOptions) {
    return (
      <>
        <ContainerButtons>
          {botoes.prazoEntrega.map((value, index) => {
            return (
              <div key={index} style={{ display: "contents" }}>
                <ButtonSmall
                  primary
                  name={value.name}
                  onClick={(e) => {
                    clicked(e, index);
                  }}
                  disabled={buttonDisabled}
                  className={value.clicked && "clicked"}
                >
                  {value.nome}
                </ButtonSmall>
              </div>
            );
          })}
          <ButtonLargeSend onClick={submitedSend}>
            <SendIcon />
          </ButtonLargeSend>
          <Pular onClick={pularPara}>Pular</Pular>
        </ContainerButtons>
      </>
    );
  } else {
    return <></>;
  }
};

export const AnswerUserSegmentoAtuacao = ({
  answer,
  triggerNextStep,
  nextID,
  pularID,
}) => {
  const [showOptions, setShowOptions] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [buttonDisabled, setButtonDisable] = useState(false);
  const [botoes, setBotoes] = useState(segmentoAtuacaoJSON);

  useEffect(() => {
    scrollDown();
  }, []);

  const pularPara = (e) => {
    setShowOptions(false);
    setButtonDisable(true);
    triggerNextStep({ trigger: pularID });
  };

  const clickedNo = (id) => {
    triggerNextStep({ trigger: id });
  };

  const clicked = (e, index) => {
    let tempBotoes = JSON.parse(JSON.stringify(botoes));
    tempBotoes.segmentoAtuacao.map((value, index) => (value.clicked = false));
    tempBotoes.segmentoAtuacao[index].clicked = true;
    setBotoes(tempBotoes);
  };

  const submitedSend = (e) => {
    e.preventDefault();
    if (selectedOptions.length > 0) {
      setShowOptions(false);
      answer("segmentoAtuacao", selectedOptions);
      setButtonDisable(true);
      triggerNextStep({ value: [selectedOptions, nextID] });
    }
  };

  useEffect(() => {
    let selectedOptionsTemp = botoes.segmentoAtuacao.filter(
      (value) => value.clicked
    );
    selectedOptionsTemp.map((value, index) => {
      setSelectedOptions(retira_acentos(value.nome));
    });
  }, [botoes]);

  useEffect(() => {
    // console.log(selectedOptions);
    if (selectedOptions !== null) {
      setShowOptions(false);
      answer("segmentoAtuacao", selectedOptions);
      setButtonDisable(true);
      triggerNextStep({ value: [selectedOptions, nextID] });
    }
  }, [selectedOptions]);

  if (showOptions) {
    return (
      <>
        <ContainerButtons>
          {botoes.segmentoAtuacao.map((value, index) => {
            return (
              <div key={index} style={{ display: "contents" }}>
                <ButtonSmall
                  primary
                  name={value.name}
                  onClick={(e) => {
                    clicked(e, index);
                  }}
                  disabled={buttonDisabled}
                  className={value.clicked && "clicked"}
                >
                  {value.nome}
                </ButtonSmall>
              </div>
            );
          })}
          {/* <ButtonLargeSend onClick={submitedSend}>
            <SendIcon />
          </ButtonLargeSend> */}
          <Pular onClick={pularPara}>Pular</Pular>
        </ContainerButtons>
      </>
    );
  } else {
    return <></>;
  }
};

export const AnswerUserPesoOperacional = ({
  answer,
  triggerNextStep,
  nextID,
  pularID,
}) => {
  const [showOptions, setShowOptions] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [resultado, setResultado] = useState();
  const [buttonDisabled, setButtonDisable] = useState(false);
  const [botoes, setBotoes] = useState(pesoOperacionalJSON);

  useEffect(() => {
    scrollDown();
  }, []);

  const pularPara = (e) => {
    setShowOptions(false);
    setButtonDisable(true);
    triggerNextStep({ trigger: pularID });
  };

  const clickedNo = (id) => {
    triggerNextStep({ trigger: id });
  };

  const clicked = (e, index) => {
    let tempBotoes = JSON.parse(JSON.stringify(botoes));
    tempBotoes.pesoOperacional.map((value, index) => (value.clicked = false));
    tempBotoes.pesoOperacional[index].clicked = true;
    setBotoes(tempBotoes);
  };

  const submitedSend = () => {
    // e.preventDefault();
    if (selectedOptions.length > 0) {
      setShowOptions(false);
      answer("pesoOperacional", selectedOptions);
      answer("resultado", resultado);
      setButtonDisable(true);
      triggerNextStep({ value: [selectedOptions, nextID] });
    }
  };

  useEffect(() => {
    let selectedOptionsTemp = botoes.pesoOperacional.filter(
      (value) => value.clicked
    );
    selectedOptionsTemp.map((value, index) => {
      setSelectedOptions(value.nome);
      console.log(value);
      setResultado(value.model);
    });
  }, [botoes]);

  useEffect(() => {
    console.log(selectedOptions);
    if (selectedOptions !== null) {
      setShowOptions(false);
      answer("pesoOperacional", selectedOptions);
      answer("resultado", resultado);
      setButtonDisable(true);
      triggerNextStep({ value: [selectedOptions, nextID] });
    }
  }, [selectedOptions]);

  if (showOptions) {
    return (
      <>
        <ContainerButtons>
          {botoes.pesoOperacional.map((value, index) => {
            return (
              <div key={index} style={{ display: "contents" }}>
                <ButtonSmall
                  primary
                  name={value.name}
                  onClick={(e) => {
                    clicked(e, index);
                  }}
                  disabled={buttonDisabled}
                  className={value.clicked && "clicked"}
                >
                  {value.nome}
                </ButtonSmall>
              </div>
            );
          })}
          {/* <ButtonLargeSend onClick={submitedSend}>
            <SendIcon />
          </ButtonLargeSend> */}
          <Pular onClick={pularPara}>Pular</Pular>
        </ContainerButtons>
      </>
    );
  } else {
    return <></>;
  }
};

export const CheckAnswerUser = ({ triggerNextStep, previousStep }) => {
  const [grow, setGrow] = useState(false);
  useEffect(() => {
    let next = previousStep.value[1];
    triggerNextStep({ trigger: next });
    setGrow(true);
  }, []);
  return (
    <Answer primary disabled={true} show={grow}>
      {Array.isArray(previousStep.value[0])
        ? previousStep.value[0].map(
            (value, index) => `${value}
`
          )
        : previousStep.value[0]}
    </Answer>
  );
};
