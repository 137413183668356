import styled from "styled-components";

export const ModelTextBackground = styled.h1`
  user-select: none;
  position: absolute;
  top: -2rem;
  left: 0;
  /* transform: translateY(85%); */
  font-size: 16rem;
  line-height: 16rem;
  color: white;
  font-family: "industry-inc-outline", sans-serif;
  font-weight: 100;
  opacity: 0.02;
`;

export const Description = styled.p`
  user-select: none;
  position: absolute;
  left: 30px;
  bottom: 10px;
  font-size: 10px;
  letter-spacing: 1px;
  color: #ffffff;

  @media (max-width: 768px) {
    bottom: unset;
    top: 30px;
  }
`;
