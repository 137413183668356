const naturezaJuridicaJSON = {
  naturezaJuridica: [
    {
      sigla: "PJ",
      nome: "Pessoa Jurídica",
      nextID: "questionUserRazaoSocial",
    },
    {
      sigla: "PRSP",
      nome: "Produtor Rural",
      nextID: "questionUserInscricaoEstadualSP",
    },
    {
      sigla: "PR",
      nome: "Produtor Rural",
      nextID: "questionUserInscricaoEstadual",
    },
    {
      sigla: "PF",
      nome: "Pessoa Física",
      nextID: "questionUserCpf",
    },
  ],
};

export default naturezaJuridicaJSON;
