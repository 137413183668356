import styled, { css } from "styled-components";
import { motion } from "framer-motion";

export const ConfigContainer = styled(motion.div)`
    min-width: calc(100vw - 20px);
    min-height: calc(100px - 10px);
    padding: 5px 10px;
    position: absolute;
    bottom: 45px;
    left: 0px;
    z-index: 99;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    ${({ isIos }) =>
      isIos &&
      css`
        bottom: 45px;
      `}
    /* justify-content: flex-start; */
    align-items: flex-start;

    .tabsSelector{
        display: flex;
        width: 100%;
        /* background-color: silver; */
        justify-content: space-between;
        position: relative;

        a{
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 10px 0px;
          padding: 0px 5px;
          min-width: 68px;
          height: 34px;
          background-color: #000;
          border-radius: 8px;
          position: relative;

          &.selected{
              border: 1px solid ${({ theme }) =>
                theme.items.configurador.colors.selected};
              /* color: ${({ theme }) =>
                theme.items.configurador.colors.selected}; */
          }
          
          .dot{
                position: absolute;
                top: -12px;
                left: calc(50% - 2.5px);
                /* right: 0; */
                height: 5px;
                width: 5px;
                border-radius: 5px;
                background-color: ${({ theme }) =>
                  theme.items.configurador.colors.selected};
            }

            .underline{
                position: absolute;
                top: -12px;
                left: calc(50% - 2.5px);
                /* right: 0; */
                height: 5px;
                width: 5px;
                border-radius: 5px;
                background-color: ${({ theme }) =>
                  theme.items.configurador.colors.selected};
            }
        } 
    }

    .itensSelector{
        display: flex;
        max-width: calc(100vw - 20px);
        /* background-color: silver; */
        position: relative;
        overflow: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        a{
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px 0px;
            margin-right: 10px;
            padding: 0px 10px;
            min-width: auto;
            max-height: 50px;
            min-height: 34px;
            background-color: #000;
            border-radius: 20px;
            position: relative;
            white-space: nowrap;
            /* user-select: all; */

            .info-btn{
                margin-left: 10px;
            }

            &.selected{
                border: 1px solid ${({ theme }) =>
                  theme.items.configurador.colors.selected};
                /* color: ${({ theme }) =>
                  theme.items.configurador.colors.selected}; */
            }
        }
    }
`;

export const Infobox = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: calc(100vw - 10px);
  max-height: calc(100vh - 200px);
  min-height: calc(100vh - 240px);
  left: 3px;
  position: absolute;
  background-color: black;
  top: 110px;
  border: 2px solid white;
  border-radius: 10px;
  z-index: 9999;

  .exit-button {
    display: flex;
    margin: 10px;
    height: 30px;
    border-radius: 20px;
    background-color: ${({ theme }) => theme.items.configurador.colors.primary};
    justify-content: center;
    align-items: center;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30vh;
    /* background-color: silver; */

    img {
      width: 100%;
    }
  }

  .text-container {
    display: flex;
    flex: 1;
    max-height: 52vh;
    margin: 10px;
    /* background-color: red; */
    overflow: auto;
    color: ${({ theme }) => theme.items.configurador.colors.primary};
  }
`;
