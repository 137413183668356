import React, { useState, useEffect, useLayoutEffect } from "react";

import { Container, Description, Navbar, ContentArea, Buttons } from "./styles";

import { showAnimation } from "./animations";

import { AnimatePresence } from "framer-motion";

import Config3d from "./Config3d";
import Config from "./Config";
import Opcionais from "./Opcionais";
import Resumo from "./Resumo";
import Packages from "./Packages";
import { useConfigurator } from "../../../contexts/configurator";

const ConfiguradorMobile = () => {
  const [currentNav, setCurrentNav] = useState(0);
  const [resumePage, setResumePage] = useState(0);
  const [height, setHeight] = useState(0);
  const { changePackage } = useConfigurator();
  const [isIos, setIsIos] = useState(false);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    setIsIos(/iPad|iPhone|iPod/.test(navigator.userAgent));
  }, []);

  useLayoutEffect(() => {
    setHeight(window.innerHeight);

    console.log("mobile");

    window.addEventListener("resize", () => {
      setHeight(window.innerHeight);
    });

    return () => {
      window.removeEventListener("resize", () => {
        setHeight(window.innerHeight);
      });
    };
  }, []);

  const setPackage = (packageId) => {
    changePackage(packageId);
    // setCurrentNav(2);
    setTimeout(() => {
      setCurrentNav(1);
    }, 10);
  };

  return (
    <>
      <Container height={height}>
        <Description>Imagens Ilustrativas</Description>

        <ContentArea height={height}>
          <Buttons>
            <a
              onClick={() => {
                console.log("clicou");
                setCurrentNav(3);
                setShowForm(true);
                setResumePage(1);
              }}
            >
              Solicitar Orçamento
            </a>
          </Buttons>
          <Config3d
          // firstView={firstView}
          // isOnMenu={isOnMenu}
          // setIsOnMenu={setIsOnMenu}
          // isOnKeyboard={isOnKeyboard}
          // setIsOnKeyboard={setIsOnKeyboard}
          // reseted={reseted}
          // setReseted={setReseted}
          // pressedKey={pressedKey}
          // setPressedKey={setPressedKey}
          // isOnRadius={isOnRadius}
          // setIsOnRadius={setIsOnRadius}
          // screenStep={screenStep}
          />
          {currentNav === 0 && (
            // <AnimatePresence exitBeforeEnter>

            // </AnimatePresence>
            <Packages setPackage={setPackage} isIos={isIos} />
          )}
          {currentNav === 1 && (
            // <AnimatePresence exitBeforeEnter>

            // </AnimatePresence>
            <Config isIos={isIos} />
          )}
          {currentNav === 2 && (
            <AnimatePresence exitBeforeEnter>
              <Opcionais isIos={isIos} />
            </AnimatePresence>
          )}
          {currentNav === 3 && (
            <AnimatePresence exitBeforeEnter>
              <Resumo
                page={resumePage}
                setCurrentNav={setResumePage}
                isIos={isIos}
                showForm={showForm}
                setShowForm={setShowForm}
              />
            </AnimatePresence>
          )}
        </ContentArea>
        {currentNav !== 3 && (
          <Navbar isIos={isIos}>
            <a
              className={`${currentNav === 0 ? "selected" : ""}`}
              onClick={() => setCurrentNav(0)}
            >
              Aplicação
            </a>
            <a
              className={`${currentNav === 1 ? "selected" : ""}`}
              onClick={() => setCurrentNav(1)}
            >
              Config
            </a>
            <a
              className={`${currentNav === 2 ? "selected" : ""}`}
              onClick={() => setCurrentNav(2)}
            >
              Opcionais
            </a>
            <a
              className={`${currentNav === 3 ? "selected" : ""}`}
              onClick={() => setCurrentNav(3)}
            >
              Resumo
            </a>
          </Navbar>
        )}
        {currentNav === 3 && (
          <Navbar className="resume-nav" isIos={isIos}>
            <a onClick={() => setCurrentNav(2)}>Voltar</a>
            <a
              className={`${resumePage === 0 ? "selected" : ""}`}
              onClick={() => setResumePage(0)}
            >
              Configuração
            </a>
            <a
              className={`${resumePage === 1 ? "selected" : ""}`}
              onClick={() => setResumePage(1)}
            >
              Resumo
            </a>
          </Navbar>
        )}
      </Container>
    </>
  );
};

export default ConfiguradorMobile;
