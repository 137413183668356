import React, {
  Suspense,
  useEffect,
  useState,
  useRef,
  forwardRef,
} from "react";
import Canvas from "../../../../contexts/ForwardCanvasContext";
import * as THREE from "three";
// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import {
  useFrame,
  extend,
  useThree,
  useLoader,
  invalidate,
} from "@react-three/fiber";
import {
  Stats,
  PerspectiveCamera,
  Box,
  RoundedBox,
  Ring,
  useTexture,
  Environment,
  QuadraticBezierLine,
  Line,
  Html,
  OrbitControls,
  AdaptiveDpr,
  AdaptiveEvents,
} from "@react-three/drei";
import PreloaderCanvas from "../../../../shared/Components/PreloaderCanvas";
import "./style.scss";
import hdri from "./Envi/shudu_lake_1k-yellow.hdr";
import { useConfigurator } from "../../../../contexts/configurator";
// extend({ OrbitControls });

const EC140 = React.lazy(() => import("../../../../assets/Modelos/EC140"));

function AdaptivePixelRatio() {
  const { gl, performance } = useThree();
  const current = performance.current;

  function lerp(start, end, amt) {
    return (1 - amt) * start + amt * end;
  }

  useEffect(() => {
    performance.debounce = 10;
  }, []);

  useFrame(() => {
    let pr = gl.getPixelRatio();
    if (current < 1) {
      invalidate();
      gl.setPixelRatio(lerp(pr, window.devicePixelRatio * current, 0.1));
    } else {
      if (pr < window.devicePixelRatio - 0.01) {
        invalidate();
        gl.setPixelRatio(lerp(pr, window.devicePixelRatio * current, 0.1));
      } else if (
        pr >= window.devicePixelRatio - 0.01 &&
        pr < window.devicePixelRatio
      ) {
        invalidate();
        gl.setPixelRatio(window.devicePixelRatio * current);
      }
    }
  });
  return null;
}

const CameraControls = forwardRef((props, refOrbit) => {
  const {
    camera,
    gl: { domElement },
  } = useThree();
  return (
    <OrbitControls
      ref={refOrbit}
      args={[camera, domElement]}
      minPolarAngle={-Math.PI / 20}
      maxPolarAngle={Math.PI / 2}
      minDistance={400}
      maxDistance={800}
      enableZoom={true}
      enablePan={false}
      enableRotate={true}
      autoRotate={false}
      enableDamping={true}
      dampingFactor={0.2}
      regress
    />
  );
});

const AnimateCamera = ({
  interactionStarted,
  setInteractionStarted,
  data,
  refOrbit,
}) => {
  const { camera } = useThree();
  const [animationSpeed, setAnimationSpeed] = useState(0.03);

  useFrame(() => {
    if (!interactionStarted) {
      camera.position.lerp(
        new THREE.Vector3(data.camPosX, data.camPosY, data.camPosZ),
        animationSpeed
      );
      refOrbit.current.target = new THREE.Vector3(
        data.targetPosX,
        data.targetPosY,
        data.targetPosZ
      );
      refOrbit.current.update();
      if (camera.position.x >= data.camPosX - 5) {
        setInteractionStarted(true);
        // if (screenStep === 2) {
        //   setIsOnMenu(true);
        // }
      }
    }
  });
};

const Config3d = (
  {
    // firstView,
    // isOnMenu,
    // setIsOnMenu,
    // isOnKeyboard,
    // setIsOnKeyboard,
    // reseted,
    // setReseted,
    // pressedKey,
    // setPressedKey,
    // isOnRadius,
    // setIsOnRadius,
    // screenStep,
  }
) => {
  const [shouldInvalidate, toggleInvalidate] = useState(false);
  // const isOnKeyboardRef = useRef(isOnKeyboard);
  const refOrbit = useRef(null);
  const [interactionStarted, setInteractionStarted] = useState(false);
  const [animationSpeed, setAnimationSpeed] = useState(0.03);
  const initRotationVal = 89.5;
  const [escavadeiraRotation, setEscavadeiraRotation] = useState(
    initRotationVal
  );
  const [escavadeiraRotate, setEscavadeiraRotate] = useState(false);
  const [timeScale, setTimeScale] = useState(0);

  const {
    getOptions,
    loadedConfig,
    selectOption,
    _3DConfig,
    config: contextConfig,
  } = useConfigurator();

  const [menuItems, setMenuItems] = useState([]);
  const [menuItemsOpcionais, setMenuItemsOpcionais] = useState([]);

  useEffect(() => {
    if (!loadedConfig) return;
    setMenuItems([
      {
        title: "Sapata",
        open: true,
        items: [
          {
            type: "radio",
            options: getOptions("sapata").radio.map((i) => ({
              item: i,
              parent: getOptions("sapata"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            })),
          },
        ],
        position: {
          start: {
            x: 38,
            y: 25,
            z: -50,
          },
          end: {
            x: 0.029,
            y: 0.007,
          },
        },
        align: "left",
      },
      {
        title: "Lança",
        open: true,
        items: [
          {
            type: "radio",
            options: getOptions("Lança").radio.map((i) => ({
              item: i,
              parent: getOptions("Lança"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            })),
          },
        ],
        position: {
          start: {
            x: -5,
            y: 125,
            z: 70,
          },
          end: {
            x: 0.01,
            y: 0.02,
          },
        },
        align: "left",
      },
      {
        title: "Braço",
        open: true,
        items: [
          {
            type: "radio",
            options: getOptions("Braço").radio.map((i) => ({
              item: i,
              parent: getOptions("Braço"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            })),
          },
        ],
        position: {
          start: {
            x: -8,
            y: 125,
            z: 145,
          },
          end: {
            x: -0.016,
            y: 0.021,
          },
        },
        align: "right",
      },
      {
        title: "Caçamba",
        open: true,
        items: [
          {
            type: "radio",
            options: getOptions("caçamba").radio.map((i) => ({
              item: i,
              parent: getOptions("caçamba"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            })),
          },
          {
            type: "toogle",
            options: getOptions("caçamba").checkbox.map((i) => ({
              item: i,
              parent: getOptions("caçamba"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            })),
          },
        ],
        position: {
          start: {
            x: -8,
            y: 20,
            z: 145,
          },
          end: {
            x: -0.014,
            y: -0.016,
          },
        },
        align: "right",
      },
    ]);
    setMenuItemsOpcionais([
      {
        title: "Linha Hidráulica",
        open: false,
        items: [
          {
            type: "radio-big",
            title: "X1",
            options: getOptions("Linha Hidráulica").radio.map((i) => ({
              item: i,
              parent: getOptions("Linha Hidráulica"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            })),
          },
          {
            type: "radio-big-2",
            title: "X3",
            options: getOptions("Linha Hidráulica X3").checkbox.map((i) => ({
              item: i,
              parent: getOptions("Linha Hidráulica X3"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            })),
          },
        ],
        position: {
          start: {
            x: -8,
            y: 125,
            z: 55,
          },
          end: {
            x: -0.01,
            y: 0.026,
          },
        },
        align: "right",
        open: false,
        visible: false,
        opacity: 0,
        fadeOut: false,
      },
      {
        title: "Sistema Elétrico",
        items: [
          {
            type: "radio",
            options: getOptions("Luzes").radio.map((i) => ({
              item: i,
              parent: getOptions("Luzes"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            })),
          },
          {
            type: "toogle",
            options: getOptions("Sistema Elétrico").checkbox.map((i) => ({
              item: i,
              parent: getOptions("Sistema Elétrico"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            })),
          },
          {
            type: "fixed",
            options: [
              {
                title: "Câmera Traseira",
                value: "cameraTraseira",
                selected: false,
              },
              {
                title: "Alarme de Deslocamento",
                value: "alarmeDeslocamento",
                selected: false,
              },
            ],
          },
        ],
        position: {
          start: {
            x: 20,
            y: 90,
            z: -5,
          },
          end: {
            x: 0.015,
            y: 0.026,
          },
        },
        align: "left",
        open: false,
        visible: false,
        opacity: 0,
        fadeOut: false,
      },
      {
        title: "Cabine",
        items: [
          {
            type: "toogle",
            options: getOptions("Cabine").checkbox.map((i) => ({
              item: i,
              parent: getOptions("Cabine"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            })),
          },
        ],
        position: {
          start: {
            x: 20,
            y: 85,
            z: 25,
          },
          end: {
            x: -0.02,
            y: 0.01,
          },
        },
        align: "right",
        open: false,
        visible: false,
        opacity: 0,
        fadeOut: false,
      },
      {
        title: "Sistema Hidráulico",
        items: [
          {
            type: "toogle",
            options: getOptions("Sistema Hidráulico").checkbox.map((i) => ({
              item: i,
              parent: getOptions("Sistema Hidráulico"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            })),
          },
        ],
        position: {
          start: {
            x: -0,
            y: 70,
            z: -50,
          },
          end: {
            x: 0.022,
            y: 0.019,
          },
        },
        align: "left",
        open: false,
        visible: false,
        opacity: 0,
        fadeOut: false,
      },
      {
        title: "Proteção do Carro",
        items: [
          {
            type: "radio",
            options: [...getOptions("Proteção do Carro Inferior").radio.map((i) => ({
              item: i,
              parent: getOptions("Proteção do Carro Inferior"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            })),...getOptions("Proteção do Carro Superior").radio.map((i) => ({
              item: i,
              parent: getOptions("Proteção do Carro Superior"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            }))],
          },
        ],
        position: {
          start: {
            x: 45,
            y: 70,
            z: -7,
          },
          end: {
            x: 0.027,
            y: 0.012,
          },
        },
        align: "left",
        open: false,
        visible: false,
        opacity: 0,
        fadeOut: false,
      },
      {
        title: "Assento",
        items: [
          {
            type: "toogle",
            options: getOptions("Assento").checkbox.map((i) => ({
              item: i,
              parent: getOptions("Assento"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            })),
          },
        ],
        position: {
          start: {
            x: 40,
            y: 50,
            z: 40,
          },
          end: {
            x: -0.019,
            y: -0.01,
          },
        },
        align: "right",
        open: false,
        visible: false,
        opacity: 0,
        fadeOut: false,
      },
      {
        title: "Motor",
        items: [
          {
            type: "toogle",
            options: getOptions("Motor").checkbox.map((i) => ({
              item: i,
              parent: getOptions("Motor"),
              title: i.name,
              selected: i.selected,
              value: i.id,
            })),
          },
        ],
        position: {
          start: {
            x: 45,
            y: 45,
            z: -40,
          },
          end: {
            x: 0.03,
            y: 0.005,
          },
        },
        align: "left",
        open: false,
        visible: false,
        opacity: 0,
        fadeOut: false,
      },
    ]);
  }, [loadedConfig, contextConfig]);

  const [config, setConfig] = useState({
    bracoCurto: {
      active: true,
      cacambaPequena: false,
      cacambaGrande: true,
    },
    bracoLongo: {
      active: false,
      cacambaPequena: false,
      cacambaGrande: false,
    },
    sapata: {
      pequena: true,
      media: false,
      grande: false,
    },
  });

  const [data, setData] = useState({
    axis: true,
    feature: "",
    camPosX: 200,
    camPosY: 200,
    camPosZ: 430,
    fov: 35,
    focusDistance: 0.092,
    focalLength: 1.0,
    bokehScale: 3.68,
    height: 1080,
    targetVisible: true,
    targetPosX: 0,
    targetPosY: 60,
    targetPosZ: 1.7,
    backgroundColor: "#f3f3f3",
    fog: false,
    fogColor: "#f3f3f3",
    fogNear: 500,
    fogFar: 1000,
    menuOpacity: 0,
  });

  // const handleDown = (e) => {
  //   if (!firstView) setInteractionStarted(true);
  // };

  const useInterval = (callback, delay) => {
    const intervalId = React.useRef(null);
    const savedCallback = React.useRef(callback);

    React.useEffect(() => {
      savedCallback.current = callback;
    });

    React.useEffect(() => {
      const tick = () => savedCallback.current();

      if (typeof delay === "number") {
        intervalId.current = window.setInterval(tick, delay);

        return () => window.clearInterval(intervalId.current);
      }
    }, [delay]);

    return intervalId.current;
  };

  const setResetedAnimation = () => {
    setEscavadeiraRotate(null);
    // setReseted(true);
  };

  useEffect(() => {
    // console.log(config);
  }, []);

  const Light = (props) => {
    const targetObject = new THREE.Object3D();
    const { scene } = useThree();
    scene.add(targetObject);
    targetObject.position.set(
      props.targetPos[0],
      props.targetPos[1],
      props.targetPos[2]
    );
    const light = new THREE.DirectionalLight(
      props.lightColor,
      props.intensity,
      10
    );
    light.position.set(props.pos[0], props.pos[1], props.pos[2]);
    light.target = targetObject;
    light.castShadow = true;
    light.shadow.mapSize.width = 256;
    light.shadow.mapSize.height = 256;
    light.shadow.camera.near = 0.1;
    light.shadow.camera.far = 1000;
    light.shadow.camera.top = -200;
    light.shadow.camera.right = 200;
    light.shadow.camera.left = -200;
    light.shadow.camera.bottom = 200;
    light.shadow.bias = -0.01;

    return <primitive object={light} />;
  };

  return (
    <>
      <Canvas
        mode={"concurrent"}
        className={"canvas "}
        shadows={true}
        // dpr={[1, 2]}
        performance={{ min: 0.3 }}
        frameloop={"demand"}
        gl={{
          alpha: true,
          outputEncoding: THREE.sRGBEncoding,
          antialias: true,
          preserveDrawingBuffer: true,
          logarithmicDepthBuffer: true,
        }}
        camera={{
          position: [0, 0, 0],
          fov: data.fov,
          near: 0.1,
          far: 1000,
          zoom: 1,
        }}
      >
        {/* <Light
          pos={[500, 200, 320]}
          targetPos={[100, -90, 0]}
          intensity={1}
          lightColor={"#886666"}
        /> */}
        <Environment files={hdri} />
        {/* <ambientLight intensity={0.5} /> */}
        <Suspense fallback={<PreloaderCanvas />}>
          <EC140
            renderOrder={10}
            scale={[3, 3, 3]}
            position={[0, 0, 0]}
            escavadeiraRotation={escavadeiraRotation}
            timeScale={timeScale}
            config={_3DConfig}
            contextConfig={contextConfig}
            envMapIntensity={2}
          />
        </Suspense>

        <group>
          <mesh
            receiveShadow
            rotation={[-Math.PI / 2, 0, 0]}
            position={[0, 0, 0]}
          >
            <planeBufferGeometry attach="geometry" args={[500, 500]} />
            <shadowMaterial
              receiveShadow
              attach="material"
              opacity={0.5}
              transparent={true}
              // color="blue"
            />
          </mesh>
        </group>
        <CameraControls ref={refOrbit} />
        <AnimateCamera
          interactionStarted={interactionStarted}
          setInteractionStarted={setInteractionStarted}
          data={data}
          refOrbit={refOrbit}
        />
        <AdaptivePixelRatio />
      </Canvas>
    </>
  );
};

export default React.memo(Config3d);
