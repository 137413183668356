import styled from "styled-components";
import { motion } from "framer-motion";

export const OpcionaisContainer = styled(motion.div)`
    min-width: 100vw;
    min-height: calc(100px - 10px);
    padding-bottom: 5px;
    position: absolute;
    bottom: 45px;
    left: 0px;
    /* top: 0px; */
    z-index: 99;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    /* background-color: red; */

    .tabsSelector{
        display: flex;
        max-width: calc(100vw - 20px);
        margin: 0px 10px;
        /* background-color: silver; */
        position: relative;
        overflow: scroll;
        justify-content: flex-start;
        min-height: 60px;

        &::-webkit-scrollbar {
            display: none;
        }

        a{
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            margin: 10px 0px;
            margin-right: 10px;
            padding: 0px 10px;
            width: 100%;
            /* min-width: 68px; */
            height: 34px;
            white-space: nowrap;
            overflow: visible;
            background-color: #000;
            border-radius: 8px;
            position: relative;

            &.selected{
                border: 1px solid ${({ theme }) =>
                  theme.items.configurador.colors.selected};
                /* color: ${({ theme }) =>
                  theme.items.configurador.colors.selected}; */
            }
        }

        .dot{
                position: absolute;
                top: -12px;
                left: calc(50% - 2.5px);
                /* right: 0; */
                height: 5px;
                width: 5px;
                border-radius: 5px;
                background-color: ${({ theme }) =>
                  theme.items.configurador.colors.selected};
            }
    }

    .itensSelectorFullScreen{
        display: flex;
        flex-direction: column;
        margin-bottom: 11px;
        padding: 5px 0px;
        padding-top: 70px;
        min-width: 100vw;
        background: black;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;
        flex: 1;


        h3{
            margin: 10px 10px;
            font-weight: normal;
            font-size: 16px;
            color: ${({ theme }) => theme.items.configurador.colors.selected};
        }
    }

    .itensSelectorContainer{
        height: 100%;
        max-height: 120px;
        display: flex;
        flex: 1;
        width: calc(100% - 20px);
        margin: 0px 10px;
        margin-bottom: 15px;
        flex-direction: column;
        /* justify-content: flex-end; */
        overflow: scroll;
    }


    .itensSelector{
        display: flex;
        flex: 1;
        width: 100%;
        margin-bottom: 15px;
        /* background-color: red; */
        height: 100%;
        flex-direction: column;
        /* overflow: scroll; */

        a{
            display: flex;
            width: calc(100% - 30px);
            min-height: 22px;
            padding: 5px 15px;
            margin-bottom: 2px;
            background-color: #000;
            border-radius: 4px;
            align-items: center;
            font-size: 14px;
            height: 100%;
            transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

            &.selected{

                background: ${({ theme }) =>
                  theme.items.configurador.colors.selected};

                .label{
                    color: #000;
                }

                .plus{
                    transform: rotateZ(45deg);
                    margin-right: 10px;
                }
            }

            .label{
                flex: 1;
                transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
            }

            .info-btn{
                pointer-events: all;
            }

            .plus{
                width: 18px;
                height: 18px;
                /* background: red; */
                margin: 0;
                padding: 0;
                transition: transform .5s cubic-bezier(0.075, 0.82, 0.165, 1);
                position: relative;
                margin-right: 10px;

                .line-1{
                    position: absolute;
                    height: 3px;
                    width: 100%;
                    background: white;
                    top: calc(50% - 1.5px);
                }

                .line-2{
                    position: absolute;
                    height: 100%;
                    width: 3px;
                    background: white;
                    left: calc(50% - 1.5px);
                }
                
            }
        }
    }
`;

export const RowContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  position: relative;
  margin: 0px 20px;
  margin-bottom: 5px;
  border-left: 2px solid
    ${({ theme }) => theme.items.configurador.colors.selected};
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: -5px;
    bottom: -4px;
    width: 8px;
    height: 8px;
    border-radius: 10px;
    background: ${({ theme }) => theme.items.configurador.colors.selected};
  }

  .item {
    display: flex;
    flex-direction: row;
    margin: 10px 0px;
    width: 100%;
    min-height: 32px;
    /* background: red; */
    justify-content: flex-start;
    align-items: center;
    position: relative;
    overflow: visible;

    .line {
      width: 25px;
      height: 2px;
      margin-right: 10px;
      background: ${({ theme }) => theme.items.configurador.colors.selected};
    }

    .selector {
      display: flex;
      flex: 1;
      height: 100%;
      justify-content: space-between;
      align-items: center;

      label {
        font-size: 14px;
        width: 75%;
        text-justify: inter-word;
        font-weight: normal;
        text-rendering: optimizeLegibility;
      }

      .switch {
        display: flex;
        max-width: 44px;
        max-height: 30px;
        min-width: 44px;
        min-height: 30px;
        background: red;
        position: relative;
        justify-content: center;
        align-items: center;

        .circle {
          width: 23px;
          height: 23px;
          border-radius: 23px;
          border: 1px solid
            ${({ theme }) => theme.items.configurador.colors.selected};
        }
      }
    }
  }
`;

export const Switch = styled.span`
  display: flex;
  max-width: 44px;
  max-height: 30px;
  min-width: 44px;
  min-height: 30px;
  /* background: red; */
  position: relative;
  justify-content: center;
  align-items: center;

  .circle {
    width: 23px;
    height: 23px;
    border-radius: 23px;
    border: 3px solid
      ${({ isSelected, theme }) =>
        isSelected ? theme.items.configurador.colors.selected : "#fff"};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .inner {
    display: flex;
    width: 17px;
    height: 17px;
    border-radius: 17px;
    background: ${({ isSelected, theme }) =>
      isSelected ? theme.items.configurador.colors.selected : "transparent"};
    /* background: cyan; */
  }
`;

export const Infobox = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: calc(100vw - 10px);
  height: calc(100vh - 340px);
  left: 3px;
  top: 110px;
  position: absolute;
  background-color: black;
  border: 2px solid white;
  border-radius: 10px;

  .exit-button {
    display: flex;
    margin: 10px;
    height: 30px;
    border-radius: 20px;
    background-color: ${({ theme }) => theme.items.configurador.colors.primary};
    justify-content: center;
    align-items: center;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30vh;
    /* background-color: silver; */

    img {
      width: 100%;
    }
  }

  .text-container {
    display: flex;
    flex: 1;
    max-height: 52vh;
    margin: 10px;
    /* background-color: red; */
    overflow: auto;
    color: ${({ theme }) => theme.items.configurador.colors.primary};
  }
`;
