import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ChatBot from "react-simple-chatbot";
import { useUser } from "../../contexts/user";
import { useAuth } from "../../contexts/auth";
import { useConfigurator } from "../../contexts/configurator";

import {
  AnswerUserAjuda,
  AnswerUserNome,
  AnswerUserTelefone,
  AnswerUserEmail,
  AnswerUserUF,
  AnswerUserNaturezaJuridica,
  AnswerUserRazaoSocial,
  AnswerUserInscricaoEstadualSP,
  AnswerUserInscricaoEstadual,
  AnswerUserCpf,
  AnswerUserCNPJ,
  AnswerUserPossueMaquinas,
  AnswerUserQuantidadeMaquinas,
  AnswerUserMarcaEscavadeiras,
  AnswerUserPrazoEntrega,
  AnswerUserSegmentoAtuacao,
  AnswerUserPesoOperacional,
  CheckAnswerUser,
} from "./Answers";
import { ContainerChatbot } from "./styled";

const AssistenteVirtual = () => {
  let { id } = useParams();
  const { user, setUser } = useUser();
  const [chatbotKey, setChatbotKey] = useState("");
  const navigate = useNavigate();
  const { getConfigs } = useConfigurator();
  const { getToken, createLead } = useAuth();
  const [data, setData] = useState({
    nome: user.nome,
    telefone: user.telefone,
    email: user.email,
    estado: user.estado,
    cpf: user.cpf,
    cnpj: user.cnpj,
    razaoSocial: user.razaoSocial,
    inscricaoEstadual: user.inscricaoEstadual,
    prazoEntrega: user.prazoEntrega,
    segmentoAtuacao: user.segmentoAtuacao,
    quantidadeMaquinas: user.quantidadeMaquinas,
    concordarReceberComunicacoes: user.concordarReceberComunicacoes,
    concordarArmazenarDados: user.concordarArmazenarDados,
    observacoes: `
      naturezaJuridica: ${user.naturezaJuridica}
: ${user.possueMaquinas},
      marcaEscavadeiras: ${user.marcaEscavadeiras},
      
      pesoOperacional: ${user.pesoOperacional},
    `,
    OrigemLead: user.OrigemLead,
    Midia: user.Midia,
    TipoContato: user.TipoContato,
    Produto: user.Produto,
  });

  const updateUser = (key, value) => {
    setUser((prevUser) => ({
      ...prevUser,
      [key]: value,
    }));
  };

  const answerUser = (area, answer) => {
    // console.log(`reposta user ${area}: ${answer}`);
    updateUser(area, answer);
  };

  const menuRouteTo = (newRoute) => {
    navigate(newRoute);
  };

  async function sendSF() {
    let configs = getConfigs();
    let tokenResult = await getToken();
    const { token, url, token_type } = JSON.parse(
      localStorage.getItem("@dataStorage")
    );
    // console.log(tokenResult, token, url, token_type);
    let sendIndex = 0;
    let indexLength = configs.length;
    for (let conf of configs) {
      // console.log(conf);
      let configuracaoArr = conf.config;
      let configuracaoList = `Modelo: ${
        conf.model !== null ? conf.model : "-"
      } | id: ${conf.productID} | pacote: ${conf.package} 
`;
      configuracaoArr.forEach((element) => {
        configuracaoList += `${element.name}: `;
        element.selected.length > 0
          ? element.selected.forEach((element2) => {
              configuracaoList += `id: ${element2.salesforce_id.map(
                (element3) => {
                  return element3;
                }
              )} item: ${element2.name}
`;
            })
          : (configuracaoList += `-
`);
      });

      const clone = require("rfdc")();
      let dataTemp = clone(data);
      dataTemp.configuracao = configuracaoList;
      dataTemp.Produto = conf.productID;
      let leadResult = await createLead(dataTemp, token);
      if (leadResult.status === 200 && sendIndex === indexLength - 1) {
        let index = sendIndex + 1;
        // console.log("Dados enviados!");
      } else if (leadResult.status === 200 && sendIndex < indexLength - 1) {
        let index = sendIndex + 1;
        // console.log("Enviado dado " + index + " de " + indexLength);
      } else {
        console.log(
          `Problema ao enviar dados! Tente novamente. Erro: ${leadResult}.`
        );
      }

      sendIndex++;
    }
  }

  useEffect(() => {
    setChatbotKey(id);
  }, [id]);

  useEffect(() => {
    // console.log("updating user");
    setData((values) => ({
      ...values,
      nome: user.nome !== null ? user.nome : "",
      telefone:
        user.telefone !== null
          ? user.telefone
              .replace(/\(/g, "")
              .replace(/\)/g, "")
              .replace(/\-/g, "")
          : "",
      email: user.email !== null ? user.email : "",
      estado: user.estado !== null ? user.estado : "",
      cpf:
        user.cpf !== null ? user.cpf.replace(/\./g, "").replace(/\-/g, "") : "",
      cnpj:
        user.cnpj !== null
          ? user.cnpj
              .replace(/\./g, "")
              .replace(/\-/g, "")
              .replace(/\//g, "")
          : "",
      razaoSocial: user.razaoSocial !== null ? user.razaoSocial : "",
      inscricaoEstadual:
        user.inscricaoEstadual !== null
          ? user.inscricaoEstadual
              .replace(/\./g, "")
              .replace(/\-/g, "")
              .replace(/\//g, "")
          : "",
      prazoEntrega: user.prazoEntrega !== null ? user.prazoEntrega : "",
      segmentoAtuacao:
        user.segmentoAtuacao !== null ? user.segmentoAtuacao : "",
      quantidadeMaquinas:
        user.quantidadeMaquinas !== null ? user.quantidadeMaquinas : "",
      concordarReceberComunicacoes:
        user.concordarReceberComunicacoes !== null
          ? user.concordarReceberComunicacoes
          : false,
      concordarArmazenarDados:
        user.concordarArmazenarDados !== null
          ? user.concordarArmazenarDados
          : false,
      observacoes: `naturezaJuridica: ${
        user.naturezaJuridica !== null ? user.naturezaJuridica : ""
      }
possueMaquinas: ${user.possueMaquinas !== null ? user.possueMaquinas : ""},
marcaEscavadeiras: ${
        user.marcaEscavadeiras !== null ? user.marcaEscavadeiras : ""
      },
pesoOperacional: ${user.pesoOperacional !== null ? user.pesoOperacional : ""},
    `,
      configuracao: "",
      proprietario: "",
      OrigemLead: user.OrigemLead !== null ? user.OrigemLead : "",
      Midia: user.Midia !== null ? user.Midia : "",
      TipoContato: user.TipoContato !== null ? user.TipoContato : "",
      Produto: user.Produto !== null ? user.Produto : "",
    }));
  }, [user]);

  const steps = [
    {
      id: "questionUserAjuda",
      message: `Olá! Tudo bem com você? Vamos
      configurar sua próxima escavadeira
      hidráulica Volvo CE?`,
      trigger: "answerUserAjuda",
      hideInput: true,
    },
    {
      id: "answerUserAjuda",
      component: (
        <AnswerUserAjuda
          answer={answerUser}
          nextID={"questionUserNome"}
          pularID={"resultado"}
          menuRouteTo={menuRouteTo}
        />
      ),
      waitAction: true,
      trigger: "answerUser",
      hideInput: true,
    },
    {
      id: "questionUserNome",
      message: `Antes de mais nada, qual o seu nome e sobrenome?`,
      trigger: "answerUserNome",
      hideInput: true,
    },
    {
      id: "answerUserNome",
      component: (
        <AnswerUserNome
          answer={answerUser}
          nextID={"questionUserTelefone"}
          pularID={"questionUserTelefone"}
          menuRouteTo={menuRouteTo}
        />
      ),
      waitAction: true,
      trigger: "answerUser",
      hideInput: true,
    },
    {
      id: "questionUserTelefone",
      message: `Qual o telefone para contato?`,
      trigger: "answerUserTelefone",
      hideInput: true,
    },
    {
      id: "answerUserTelefone",
      component: (
        <AnswerUserTelefone
          answer={answerUser}
          nextID={"questionUserEmail"}
          pularID={"questionUserEmail"}
          menuRouteTo={menuRouteTo}
        />
      ),
      waitAction: true,
      trigger: "answerUser",
      hideInput: true,
    },
    {
      id: "questionUserEmail",
      message: `Qual o seu e-mail?`,
      trigger: "answerUserEmail",
      hideInput: true,
    },
    {
      id: "answerUserEmail",
      component: (
        <AnswerUserEmail
          answer={answerUser}
          nextID={"questionUserUF"}
          pularID={"questionUserUF"}
          menuRouteTo={menuRouteTo}
        />
      ),
      waitAction: true,
      trigger: "answerUser",
      hideInput: true,
    },
    {
      id: "questionUserUF",
      message: `Qual é o seu estado(UF)?`,
      trigger: "answerUserUF",
      hideInput: true,
    },
    {
      id: "answerUserUF",
      component: (
        <AnswerUserUF
          answer={answerUser}
          nextID={"questionUserNaturezaJuridica"}
          pularID={"questionUserNaturezaJuridica"}
          menuRouteTo={menuRouteTo}
        />
      ),
      waitAction: true,
      trigger: "answerUser",
      hideInput: true,
    },
    {
      id: "questionUserNaturezaJuridica",
      message: `Tudo certo! Queremos entender melhor o seu contexto. Qual sua natureza jurídica?`,
      trigger: "answerUserNaturezaJuridica",
      hideInput: true,
    },
    {
      id: "answerUserNaturezaJuridica",
      component: (
        <AnswerUserNaturezaJuridica
          answer={answerUser}
          nextID={"questionUserSegmentoAtuacao"}
          pularID={"questionUserSegmentoAtuacao"}
          menuRouteTo={menuRouteTo}
        />
      ),
      waitAction: true,
      trigger: "answerUser",
      hideInput: true,
    },
    {
      id: "questionUserRazaoSocial",
      message: `Qual a sua razão social?`,
      trigger: "answerUserRazaoSocial",
      hideInput: true,
    },
    {
      id: "answerUserRazaoSocial",
      component: (
        <AnswerUserRazaoSocial
          answer={answerUser}
          nextID={"questionUserCNPJ"}
          pularID={"questionUserCNPJ"}
          menuRouteTo={menuRouteTo}
        />
      ),
      waitAction: true,
      trigger: "answerUser",
      hideInput: true,
    },
    {
      id: "questionUserInscricaoEstadualSP",
      message: `Qual o sua inscrição estadual?`,
      trigger: "answerUserInscricaoEstadualSP",
      hideInput: true,
    },
    {
      id: "answerUserInscricaoEstadualSP",
      component: (
        <AnswerUserInscricaoEstadualSP
          answer={answerUser}
          nextID={"questionUserCNPJ"}
          pularID={"questionUserCNPJ"}
          menuRouteTo={menuRouteTo}
        />
      ),
      waitAction: true,
      trigger: "answerUser",
      hideInput: true,
    },
    {
      id: "questionUserInscricaoEstadual",
      message: `Qual a sua inscrição estadual?`,
      trigger: "answerUserInscricaoEstadual",
      hideInput: true,
    },
    {
      id: "answerUserInscricaoEstadual",
      component: (
        <AnswerUserInscricaoEstadual
          answer={answerUser}
          nextID={"questionUserCpf"}
          pularID={"questionUserCpf"}
          menuRouteTo={menuRouteTo}
        />
      ),
      waitAction: true,
      trigger: "answerUser",
      hideInput: true,
    },
    {
      id: "questionUserCpf",
      message: `Qual o seu CPF?`,
      trigger: "answerUserCpf",
      hideInput: true,
    },
    {
      id: "answerUserCpf",
      component: (
        <AnswerUserCpf
          answer={answerUser}
          nextID={"questionUserSegmentoAtuacao"}
          pularID={"questionUserSegmentoAtuacao"}
          menuRouteTo={menuRouteTo}
        />
      ),
      waitAction: true,
      trigger: "answerUser",
      hideInput: true,
    },
    {
      id: "questionUserCNPJ",
      message: `Qual o seu CNPJ?`,
      trigger: "answerUserCNPJ",
      hideInput: true,
    },
    {
      id: "answerUserCNPJ",
      component: (
        <AnswerUserCNPJ
          answer={answerUser}
          nextID={"questionUserSegmentoAtuacao"}
          pularID={"questionUserSegmentoAtuacao"}
          menuRouteTo={menuRouteTo}
        />
      ),
      waitAction: true,
      trigger: "answerUser",
      hideInput: true,
    },
    {
      id: "questionUserPossueMaquinas",
      message: `E você já possui alguma dessas máquinas? Quais?`,
      trigger: "answerUserPossueMaquinas",
      hideInput: true,
    },
    {
      id: "answerUserPossueMaquinas",
      component: (
        <AnswerUserPossueMaquinas
          answer={answerUser}
          nextID={"questionUserQuantidadeMaquinas"}
          pularID={"questionUserPrazoEntrega"}
          menuRouteTo={menuRouteTo}
        />
      ),
      waitAction: true,
      trigger: "answerUser",
      hideInput: true,
    },
    {
      id: "questionUserQuantidadeMaquinas",
      message: `Entendi! E quantas máquinas tem sua frota?`,
      trigger: "answerUserQuantidadeMaquinas",
      hideInput: true,
    },
    {
      id: "answerUserQuantidadeMaquinas",
      component: (
        <AnswerUserQuantidadeMaquinas
          answer={answerUser}
          nextID={"questionUserPrazoEntrega"}
          pularID={"questionUserPrazoEntrega"}
          menuRouteTo={menuRouteTo}
        />
      ),
      waitAction: true,
      trigger: "answerUser",
      hideInput: true,
    },
    {
      id: "questionUserMarcaEscavadeiras",
      message: `Legal! Qual a marca de suas escavadeiras?`,
      trigger: "answerUserMarcaEscavadeiras",
      hideInput: true,
    },
    {
      id: "answerUserMarcaEscavadeiras",
      component: (
        <AnswerUserMarcaEscavadeiras
          answer={answerUser}
          nextID={"questionUserPrazoEntrega"}
          pularID={"questionUserPrazoEntrega"}
          menuRouteTo={menuRouteTo}
        />
      ),
      waitAction: true,
      trigger: "answerUser",
      hideInput: true,
    },
    {
      id: "questionUserPrazoEntrega",
      message: `Qual o seu desejo em relação ao prazo de entrega da sua nova escavadeira?`,
      trigger: "answerUserPrazoEntrega",
      hideInput: true,
    },
    {
      id: "answerUserPrazoEntrega",
      component: (
        <AnswerUserPrazoEntrega
          answer={answerUser}
          nextID={"questionUserSegmentoAtuacao"}
          pularID={"questionUserSegmentoAtuacao"}
          menuRouteTo={menuRouteTo}
        />
      ),
      waitAction: true,
      trigger: "answerUser",
      hideInput: true,
    },
    {
      id: "questionUserSegmentoAtuacao",
      message: `Agora que já sabemos um pouco sobre você, vamos falar da sua rotina. Qual destes é seu principal Segmento de Atuação?`,
      trigger: "answerUserSegmentoAtuacao",
      hideInput: true,
    },
    {
      id: "answerUserSegmentoAtuacao",
      component: (
        <AnswerUserSegmentoAtuacao
          answer={answerUser}
          nextID={"questionUserPesoOperacional"}
          pularID={"questionUserPesoOperacional"}
          menuRouteTo={menuRouteTo}
        />
      ),
      waitAction: true,
      trigger: "answerUser",
      hideInput: true,
    },
    {
      id: "questionUserPesoOperacional",
      message: `Qual é o peso operacional desejado?`,
      trigger: "answerUserPesoOperacional",
      hideInput: true,
    },
    {
      id: "answerUserPesoOperacional",
      component: (
        <AnswerUserPesoOperacional
          answer={answerUser}
          nextID={"resposta"}
          pularID={"resposta"}
          menuRouteTo={menuRouteTo}
        />
      ),
      waitAction: true,
      trigger: "answerUser",
      hideInput: true,
    },
    {
      id: "answerUser",
      component: <CheckAnswerUser />,
      waitAction: true,
      hideInput: true,
    },
    {
      id: "resposta",
      message: ({ previousValue, steps }) =>
        `Terminamos! Suas respostas nos ajudaram a selecionar os modelos de escavadeiras Volvo CE ideais para seu Segmento de Atuação!`,
      trigger: "resultado",
      hideInput: true,
    },
    {
      id: "resultado",
      delay: 3000,
      component: <></>,
      // component: <ShowResults trucks={trucks} isPortfolio={false} />,
      end: true,
      hideInput: true,
    },
  ];

  const handleEnd = async () => {
    await sendSF();
    menuRouteTo("/seletordemodelos");
  };

  return (
    <ContainerChatbot>
      <ChatBot
        key={chatbotKey}
        className="chat-tracbel"
        enableSmoothScroll={true}
        botDelay={1000}
        customDelay={0}
        userDelay={0}
        avatarStyle={{ display: "none" }}
        style={{ height: "auto" }}
        width="100%"
        contentStyle={{
          height: "calc(100vh - 120px)",
        }}
        headerComponent={<></>}
        footerStyle={{ input: { backgroundColor: "green" } }}
        steps={steps}
        handleEnd={() => handleEnd("/seletordemodelos")}
      />
    </ContainerChatbot>
  );
};

export default AssistenteVirtual;
