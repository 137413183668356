import React from "react";

import { isMobile } from "react-device-detect";
import SeletorMobile from "./mobile";
import SeletorDesktop from "./desktop";

const SeletorDeModelos = () => {
  return (<>
    {isMobile ? <SeletorMobile/>: <SeletorDesktop/>} 
  </>);
};

export default SeletorDeModelos;
