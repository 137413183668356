import styled from "styled-components";
import { keyframes } from "styled-components";

const pulse = keyframes`
  0% {
    box-shadow: 0 0 10px #ffb800;
  }
  50%{
    box-shadow: 0 0 20px #ffb800;
  }
  100% {
    box-shadow: 0 0 10px #ffb800;
  }
`;

export const Container = styled.div`
  pointer-events: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: calc(80px - 20px);
  width: calc(100vw - 30px);
  /* background-color: cyan; */
  position: fixed;
  bottom: 0;
  padding: 10px 15px;
  align-items: center;
  z-index: 999999;
`;

export const Steps = styled.div`
  display: flex;
  justify-content: space-between;
  /* background-color: black; */
  min-width: 100px;
  position: relative;

  &.highlighted {
    &:after {
      content: "";
      position: absolute;
      top: -20%;
      left: -10%;
      width: 120%;
      height: 140%;
      border: 1px solid #fff;
      border-color: #ffb800;
      box-shadow: 0 0 20px #ffb800;
      border-radius: 5px;
      animation: ${pulse} 2s infinite;
    }
  }

  &:before {
    content: "";
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) =>
      theme.items.configurador.colors.greySteps};
    position: absolute;
    top: 50%;
    z-index: 0;
  }

  .step-item {
    pointer-events: all;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    padding: 10px 20px;
    background-color: ${({ theme }) =>
      theme.items.configurador.colors.greySteps};
    border-radius: 50px;
    /* min-width: calc(28px - 20px); */
    width: auto;
    min-height: calc(28px - 20px);
    /* max-width: calc(28px - 20px); */
    max-height: calc(28px - 20px);
    font-family: ${({ theme }) => theme.items.configurador.fonts.descriptions};
    color: ${({ theme }) => theme.items.configurador.colors.greyTextSteps};
    font-size: 1rem;
    z-index: 9;
    transition: all 0.4s ease;
    cursor: pointer;

    &.on {
      background-color: ${({ theme }) =>
        theme.items.configurador.colors.primary};
      color: ${({ theme }) =>
        theme.items.configurador.colors.greySelectedTextSteps};
    }

    &:nth-child(4) {
      margin-right: 0;
    }
  }
`;

export const RightInteractionsItem = styled.div`
  pointer-events: all;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  max-width: 30px;
  min-height: 38px;
  max-height: 38px;
  /* background-color: red; */
  margin-right: 10px;
  overflow: visible;

  &:nth-child(3) {
    margin-right: 0;
  }

  .refresh {
    background-color: ${({ theme }) =>
      theme.items.configurador.colors.greyItemBottomRight};
    border-radius: 50px;
    padding: 4px;
    overflow: visible;
    transition: transform 0.3s ease;
    transform: rotateZ(-140deg) rotateX(180deg);
    font-size: 1rem;
    color: black;

    &:hover {
      transform: rotateZ(140deg) rotateX(180deg);
    }
  }

  .share {
    display: flex;
    font-size: 1.4rem;
    transition: transform 0.3s ease;
    padding: 4px;
    position: relative;
    /* background-color: black; */
    overflow: visible;

    /* &:before{
            content: '';
            display: flex;
            position: relative;
            color: white;
            min-height: 30px;
            min-width: 30px;
            background-color: red;

        } */

    &:hover {
      transform: rotateY(180deg);
    }
  }

  &.info-container {
    flex-direction: column;
    position: relative;
    transition: all 0.2s ease;

    .arrow-up {
      position: absolute;
      width: 120%;
      height: 100%;
      /* background-color: red; */

      &.highlighted {
        @keyframes pulse {
          0% {
            box-shadow: 0 0 10px #ffb800;
          }
          50% {
            box-shadow: 0 0 20px #ffb800;
          }
          100% {
            box-shadow: 0 0 10px #ffb800;
          }
        }

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 120%;
          height: 140%;
          border: 1px solid #fff;
          border-color: #ffb800;
          box-shadow: 0 0 20px #ffb800;
          border-radius: 5px;
          animation: ${pulse} 2s infinite;
        }
      }
    }

    &.show {
      top: -340px;
      z-index: 999;

      .info {
        transform: rotateX(180deg);
        color: ${({ theme }) => theme.items.configurador.colors.primary};
      }

      .content {
        top: 100%;
      }
    }

    .info {
      font-size: 3rem;
      /* padding: 10px; */
      transition: all 0.2s ease;
    }

    .content {
      display: flex;
      /* background-color: red; */
      position: absolute;
      top: 100px;
      /* left: calc(-27vh + 40px); */
      left: calc(-400px + 30px);
      flex-direction: column;

      .image-hide {
        height: 180px;
        background-color: rgba(0, 0, 0, 0);
        overflow: hidden;
        border: none;
      }
      .image {
        /* min-width: 27vh; */
        min-width: 400px;
        height: 180px;
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.6);
        overflow: hidden;
        border: none;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .description {
        margin-top: 20px;
        min-width: calc(400px - 20px);
        height: calc(140px - 20px);
        border-radius: 10px;
        color: ${({ theme }) => theme.items.configurador.colors.primary};
        background-color: rgba(0, 0, 0, 0.6);
        padding: 10px;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 8px; /* width of the entire scrollbar */
        }

        &::-webkit-scrollbar-track {
          background: rgba(0, 0, 0, 0.6); /* color of the tracking area */
        }

        &::-webkit-scrollbar-thumb {
          background-color: ${({ theme }) =>
            theme.items.configurador.colors
              .primary}; /* color of the scroll thumb */
          border-radius: 20px; /* roundness of the scroll thumb */
        }
      }
    }
  }

  @media only screen and (max-width: 1400px) {
    &.info-container {
      &.show {
        top: -300px;
      }

      .content {
        .description {
          height: calc(100px - 20px);
        }
      }
    }
  }
`;

export const ContainerRightInteracions = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  padding: 10px 0px;
  flex: 1;
`;

export const InfoBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex: 1;
  position: relative;

  .info-box-hide {
    display: flex;
    justify-content: center;
    align-items: center;
    /* min-width: 28px; */
    width: 31px;
    height: 31px;
    /* min-height: 28px; */
    border-radius: 50px;
    padding: 10px;
    /* background-color: red; */
    font-size: 1.5rem;
    position: relative;

    transition: width 0.2s, height 0.2s;
    -webkit-transition: width 0.2s, height 0.2s;
    -moz-transition: width 0.2s, height 0.2s;

    /* transition: height 0.2s ease; */

    &.on {
      /* transition: width 0.4s ease;
            transition: height 0.2s ease; */
      transition: width 0.2s, height 0.2s;
      -webkit-transition: width 0.2s, height 0.2s;
      -moz-transition: width 0.2s, height 0.2s;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 200px;
      height: 200px;
      background-color: black;
      top: -90px;
      border-radius: 10px;
      border: 2px solid
        ${({ theme }) => theme.items.configurador.colors.greyInfoBox};

      .icon {
        border: 2px solid black;
        font-size: 1.2rem;
        position: absolute;
        left: 5px;
        bottom: 5px;
      }

      .desc {
        display: block;
        font-size: 1rem;
        color: ${({ theme }) => theme.items.configurador.colors.greySteps};
      }
    }

    .icon {
      border: 2px solid
        ${({ theme }) => theme.items.configurador.colors.greyInfoBox};
      padding: 2px;
      border-radius: 100%;
      color: ${({ theme }) => theme.items.configurador.colors.greyInfoBox};
      font-size: 1.5rem;
    }

    .desc {
      display: none;
    }
  }
`;
