import styled from "styled-components";
import { Send } from "@styled-icons/fluentui-system-filled/Send";

export const ContainerChatbot = styled.div`
  /* display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  padding: 70px 10px 0px; */

  position: absolute;
  z-index: 1;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  width: 100%;
  height: calc(100vh - 4.68rem);
  margin-top: 4.68rem;
  justify-content: center;
  background-color: ${({ theme }) => theme.background};

  .chat-tracbel {
    width: 40%;
    min-width: 640px;
    height: calc(100%);
    box-sizing: border-box;
    @media (max-width: 640px) {
      width: 100%;
      min-width: 0;
    }

    .rsc-container {
      height: calc(100% - 70px) !important;
      box-shadow: none;
      .rsc-content {
        box-sizing: border-box;
        overflow-y: scroll;
        padding-bottom: 50px;
        &::-webkit-scrollbar {
          display: none;
        }
        .rsc-ts-bubble {
          box-shadow: none;
          width: 60%;
          max-width: 480px;
          margin-top: 10px;
          padding: 10px;
          color: ${({ theme }) =>
            theme.items.assistentevirtual.colors.text_primary};
          font-family: ${({ theme }) => theme.items.defaults.text.font};
          font-size: 0.8rem;
          line-height: 1.2rem;
          letter-spacing: 1px;
          text-align: center;
          text-rendering: optimizeLegibility;
          /* font-smoothing: antialised;
          font-smoothing: subpixel-antialiased; */
          border: 1px solid
            ${({ theme }) =>
              theme.items.assistentevirtual.buttons.colors.primary};
          border-radius: 20px;

          @media (max-width: 640px) {
            width: 80%;
          }
        }
        .rsc-cs {
          padding: 0;
          border: none;
          box-shadow: none;
          background-color: transparent;
          justify-content: flex-end;
        }
      }
    }
  }
`;

export const ContainerButtons = styled.div`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  flex-shrink: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button`
  width: 40% !important;
  background: ${({ theme }) =>
    theme.items.assistentevirtual.buttons.colors.primary};
  color: ${({ theme }) =>
    theme.items.assistentevirtual.buttons.colors.text_primary};
  cursor: pointer;
  flex-basis: auto;
  flex-grow: 1;
  margin: 5px;
  padding: 10px;
  font-family: ${({ theme }) => theme.items.defaults.text.font};
  font-size: 0.8rem;
  border: 0px solid #96b0b6;
  border-radius: 50px;

  &.clicked {
    ${({ theme }) => theme.items.assistentevirtual.buttons.colors.secondary};
  }
`;

export const ButtonSmall = styled.button`
  width: 30% !important;
  background: transparent;
  color: ${({ theme }) =>
    theme.items.assistentevirtual.buttons.colors.text_primary};
  cursor: pointer;
  flex-basis: auto;
  flex-grow: 1;
  margin: 5px;
  padding: 10px;
  font-family: ${({ theme }) => theme.items.defaults.text.font};
  font-size: 0.8rem;
  color: ${({ theme }) => theme.items.assistentevirtual.colors.text_primary};
  border: 1px solid
    ${({ theme }) => theme.items.assistentevirtual.buttons.colors.primary};
  border-radius: 100px;

  @media (max-width: 640px) {
    width: 100% !important;
  }

  &.clicked {
    border: 1px solid #e4e4e4;
  }
`;

export const ButtonQuantidade = styled.button`
  /* Adapt the colors based on primary prop */
  width: 40% !important;
  background: #96b0b6;
  color: #000000;
  cursor: pointer;
  flex-basis: auto;
  flex-grow: 1;
  height: 50px;
  margin: 5px;
  font-family: "Font Volvo Novum";
  font-size: 12px;
  font-weight: bold;
  border: 0px solid #96b0b6;
`;

export const ButtonImplemento = styled.button`
  /* Adapt the colors based on primary prop */
  width: 26.66% !important;
  background: #96b0b6;
  color: #000000;
  cursor: pointer;
  flex-basis: auto;
  flex-grow: 1;
  height: 50px;
  margin: 5px;
  font-family: "Font Volvo Novum";
  font-size: 12px;
  font-weight: bold;
  // padding: 0.5em 1em;
  border: 0px solid #96b0b6;
  // border-radius: 0px;
`;

export const ButtonConfigurar = styled.button`
  /* Adapt the colors based on primary prop */
  width: 40% !important;
  background: #96b0b6;
  color: #000000;
  cursor: pointer;
  flex-basis: auto;
  flex-grow: 1;
  max-width: 96%;
  height: 50px;
  margin: 50px auto;
  font-family: "Font Volvo Novum";
  font-size: 12px;
  font-weight: bold;
  // padding: 0.5em 1em;
  border: 0px solid #96b0b6;
  // border-radius: 0px;
`;

export const InputTextGroup = styled.form`
  position: relative;
  display: flex;
  width: 60%;
  max-width: 480px;
  margin-top: 10px;
  border: 1px solid
    ${({ theme }) => theme.items.assistentevirtual.buttons.colors.primary};
  border-radius: 100px;
  @media (max-width: 640px) {
    width: 80%;
  }
`;

export const InputText = styled.input`
  background: transparent;
  color: #666666;
  flex-basis: auto;
  flex-grow: 2;
  padding: 10px;
  color: ${({ theme }) => theme.items.assistentevirtual.colors.text_primary};
  font-family: ${({ theme }) => theme.items.defaults.text.font};
  font-size: 0.8rem;
  line-height: 1.2rem;
  letter-spacing: 1px;
  text-align: left;
  text-rendering: optimizeLegibility;
  /* font-smoothing: antialised;
  font-smoothing: subpixel-antialiased; */
  border: none;
  outline: none;
`;

export const InputSelect = styled.select`
  /* Adapt the colors based on primary prop */
  width: 90% !important;
  background: transparent;
  flex-basis: auto;
  flex-grow: 2;
  color: ${({ theme }) => theme.items.assistentevirtual.colors.text_primary};
  font-family: ${({ theme }) => theme.items.defaults.text.font};
  font-size: 0.8rem;
  /* line-height: 1.2rem; */
  letter-spacing: 1px;
  text-align: left;
  text-rendering: optimizeLegibility;
  /* font-smoothing: antialised;
  font-smoothing: subpixel-antialiased; */
  border: none;
  outline: none;
  padding: 0px 15px 0px 15px;

  option {
    background: ${({ theme }) =>
      theme.items.assistentevirtual.buttons.colors.select_background};
  }
`;

export const SendIcon = styled(Send)`
  position: absolute;
  z-index: 9999;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  color: #000000;
  width: 18px;
`;

export const ButtonTextSend = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;
  margin: 5px;
  width: 30px;
  height: 30px;
  background-color: ${({ theme }) =>
    theme.items.assistentevirtual.buttons.colors.primary};
  border: none;
  border-radius: 60px;
`;

export const ButtonLargeSend = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-grow: 1;
  flex-shrink: 0;
  margin: 5px;
  width: 30%;
  max-width: calc(30% + 10px);
  height: 35px;
  background-color: ${({ theme }) =>
    theme.items.assistentevirtual.buttons.colors.primary};
  border: none;
  border-radius: 50px;
  font-size: 12px;

  @media (max-width: 640px) {
    width: 40%;
  }

  &:before {
    content: "Enviar";
    padding-right: 10px;
  }
`;

export const ButtonLargeNo = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-grow: 1;
  flex-shrink: 0;
  margin: 5px;
  width: 30%;
  height: 35px;
  background-color: ${({ theme }) =>
    theme.items.assistentevirtual.buttons.colors.primary};
  border: none;
  border-radius: 50px;
  font-size: 12px;

  @media (max-width: 640px) {
    width: 40%;
  }
`;

export const Answer = styled.button`
  /* Adapt the colors based on primary prop */
  background: none;
  color: ${({ theme }) => theme.items.assistentevirtual.colors.text_primary};
  cursor: pointer;
  width: 60%;
  max-width: 480px;
  padding: 10px;
  font-family: ${({ theme }) => theme.items.defaults.text.font};
  font-size: 0.8rem;
  letter-spacing: 1px;
  text-rendering: optimizeLegibility;
  /* font-smoothing: antialised;
  font-smoothing: subpixel-antialiased; */
  // padding: 0.5em 1em;
  border: 1px solid #e4e4e4;
  border-radius: 20px;
  transform-origin: bottom right;
  backface-visibility: hidden;
  transform: scale(0) translateZ(0);
  transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  transform: ${(props) => (props.show ? "scale(1)" : "scale(0)")};
  white-space: pre-wrap;

  @media (max-width: 640px) {
    width: 80%;
  }
`;

export const Pular = styled.button`
  position: absolute;
  margin: 0px;
  right: 10px;
  bottom: -30px;
  z-index: 10000;
  color: ${({ theme }) => theme.items.assistentevirtual.colors.text_primary};
  font-family: ${({ theme }) => theme.items.defaults.text.font};
  font-size: 0.8rem;
  /* line-height: 1.2rem; */
  letter-spacing: 1px;
  text-align: left;
  text-rendering: optimizeLegibility;
  /* font-smoothing: antialised;
  font-smoothing: subpixel-antialiased; */
  background: none;
  border: 0px;
  padding: 5px;
  cursor: pointer;
`;

export const SliderResult = styled.div`
  pointer-events: all;
  cursor: grab;
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  width: 100%;
  // height: 50vh;
  padding: 0;
  margin: 0;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  border: 0px solid black;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-behavior: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  &.active {
    scroll-snap-type: none;
    scroll-behavior: auto;
  }
  &.anima {
    scroll-snap-type: none;
    scroll-behavior: smooth;
  }

  /***********  Safari scroll-snap-type change bug  *****************/
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      &.active {
        scroll-snap-type: x mandatory;
        scroll-behavior: auto;
      }
      &.anima {
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
      }
    }
  }
`;

export const ButtonResult = styled.button`
  pointer-events: none;
  position: relative;
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */

  user-select: none;
  display: flex !important;
  align-items: flex-start;
  justify-content: center;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center !important;
  overflow: visible;
  flex-shrink: 0;
  background: #96b0b6;
  cursor: pointer;
  flex-basis: auto;
  // flex-grow: 1;
  font-size: 28px;
  font-family: "Font Volvo";
  font-weight: 400;
  color: #4d4e53;
  border: 0px solid gray;
  // border-radius: 0px;
  scroll-snap-align: center;

  .text {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding-top: 20px;
  }
`;

export const SliderBar = styled.ul`
  display: flex;
  justify-content: center;
  flex-flow: row nowrap;
  width: 100%;
  height: 7px;
  margin: 25px auto;
`;

export const SliderBarDrop = styled.li`
  cursor: pointer;
  list-style-type: none;
  flex-grow: 1;
  max-width: 40px;
  margin: 10px;
  background-color: #96b0b6;
  height: 7px;
  &.active {
    background-color: #396976;
  }
`;
