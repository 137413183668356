import React, { useEffect, useState } from "react";
import { motion, LayoutGroup } from "framer-motion";
import { ConfigContainer, Infobox } from "./styles";

import { showAnimation, showDot, showAnimationInfoBox } from "../animations";
import { useConfigurator } from "../../../../contexts/configurator";

import { FaInfoCircle } from "react-icons/fa";
import { RiCloseCircleFill } from "react-icons/ri";

export default function Config({ isIos }) {
  const { getOptions, selectOption, lastItemChanged } = useConfigurator();
  const [tabs, setTabs] = useState([
    {
      label: "Braço",
      item: getOptions("braço"),
    },
    {
      label: "Lança",
      item: getOptions("lança"),
    },
    {
      label: "Caçamba",
      item: getOptions("caçamba"),
    },
    {
      label: "Sapata",
      item: getOptions("sapata"),
    },
  ]);

  const [selectedTab, setSelectedTab] = useState("Braço");
  const [itens, setItens] = useState({ radio: [], checkbox: [] });

  const [showInfoPage, setShowInfoPage] = useState(false);
  // informações que abrem sobre o item //
  const [infoOptional, setInfoOptional] = useState({
    image: "/assets/infoImg.png",
    text:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  });

  useEffect(() => {
    // console.log(tabs);
    tabs.map((item) => {
      if (item.label === selectedTab) setItens(item.item);
    });
  }, [selectedTab]);

  const handleShowInfo = ({ data }) => {
    // data é a informação que é pra aparecer no card //
    setShowInfoPage(true);
    // console.log("mostrou!");
  };

  return (
    <>
      <ConfigContainer
        variants={showAnimation}
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0 }}
        isIos={isIos}
      >
        <div className="itensSelector">
          {itens.radio.map((item, index) => {
            return (
              <a
                key={index}
                className={item.selected ? "selected" : ""}
                onClick={() => {
                  selectOption(itens, "radio", item);
                }}
              >
                {item.name}
                <FaInfoCircle
                  className="info-btn"
                  size={18}
                  onClick={handleShowInfo}
                />
              </a>
            );
          })}
          {itens?.checkbox?.map((item, index) => {
            return (
              <a
                key={index}
                className={item.selected ? "selected" : ""}
                onClick={() => {
                  selectOption(itens, "checkbox", item);
                }}
              >
                {item.name}
                <FaInfoCircle
                  className="info-btn"
                  size={18}
                  onClick={handleShowInfo}
                />
              </a>
            );
          })}
        </div>
        <div className="tabsSelector">
          <LayoutGroup>
            {tabs.map((item) => (
              <a
                key={item.label}
                className={item.label === selectedTab ? "selected" : ""}
                onClick={() => setSelectedTab(item.label)}
              >
                {`${item.label}`}
                {item.label === selectedTab ? (
                  // <motion.div className="dot" variants={showDot} initial="hidden" animate="visible"/>
                  <motion.div className="dot" layoutId="underline" />
                ) : // <motion.div className="underline" layoutId="underline"/>
                null}
              </a>
            ))}
          </LayoutGroup>
        </div>
      </ConfigContainer>
      {showInfoPage ? (
        <Infobox
          variants={showAnimationInfoBox}
          initial="hidden"
          animate="visible"
        >
          <a className="exit-button" onClick={() => setShowInfoPage(false)}>
            <RiCloseCircleFill size={24} color={"black"} />
          </a>
          {lastItemChanged?.image && (
            <div className="image-container">
              <img src={process.env.PUBLIC_URL + lastItemChanged?.image} />
            </div>
          )}
          <div className="text-container">{lastItemChanged?.description}</div>
        </Infobox>
      ) : null}
    </>
  );
}
