import React, { useState, useEffect } from "react";

import { PrimaryButton } from "../../../shared/Components/Buttons";

import { Container, SelectedBulldozerContainer } from "./styles";

import tracbelImage from "../../../shared/assets/images/tracbel.png";

import { FaChevronLeft } from "react-icons/fa";

import { useNavigate } from "react-router-dom";

const HomeMobile = () => {
  const [state, setState] = useState(0);
  const navigate = useNavigate();
  const [isIos, setIsIos] = useState(false);

  useEffect(() => {
    setIsIos(/iPad|iPhone|iPod/.test(navigator.userAgent));
  }, []);

  console.log(navigator.userAgent);

  return (
    <Container>
      <div className="home-title-container">
        <h1>Configurador</h1>
        <label>tracbel para máquinas e implementos</label>
      </div>
      <div className="home-buttons-area">
        <PrimaryButton
          text={"Configurar Modelo"}
          size={"large"}
          action={() => navigate("/seletordemodelos")}
        />
        <PrimaryButton
          text={"Encontrar seu modelo ideal"}
          size={""}
          action={() => navigate("/assistentevirtual")}
        />
      </div>
    </Container>
  );
};

export default HomeMobile;
