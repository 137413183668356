import { useEffect, useState, useRef } from "react";
import { Wrapper, ModalButton, ModalButtons } from "./styled";
import { useModal } from "../../../contexts/modal";

const Modal = () => {
  const { modal, setModal } = useModal();
  const [classname, setClassname] = useState("");
  // let timer = useRef(null);

  useEffect(() => {
    if (modal.show) {
      setClassname("on");
      // try {
      //   clearTimeout(timer.current);
      // } catch (err) {}
      // timer.current = setTimeout(() => {
      //   setModal((values) => ({
      //     ...values,
      //     show: false,
      //   }));
      // }, modal.time);
    } else {
      setClassname("");
    }
  }, [modal]);

  const cancelar = () => {
    setModal((values) => ({
      ...values,
      show: false,
    }));
  };

  const continuar = (callback) => {
    setModal((values) => ({
      ...values,
      show: false,
    }));
    callback();
  };

  return (
    <Wrapper className={classname}>
      {modal.message}
      <ModalButtons>
        {modal.callback !== null ? (
          <>
            <ModalButton className="danger" onClick={cancelar}>
              Cancelar
            </ModalButton>
            <ModalButton onClick={() => continuar(modal.callback)}>
              Continuar
            </ModalButton>
          </>
        ) : (
          <ModalButton onClick={cancelar}>OK</ModalButton>
        )}
      </ModalButtons>
    </Wrapper>
  );
};

export default Modal;
