import styled from "styled-components";

export const Container = styled.header`
  pointer-events: none;
  position: absolute;
  z-index: 1000000000;
  display: flex;
  width: 100vw;
  height: 70px;
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.items.header.background.color};
`;

export const Logo = styled.div`
  position: absolute;
  width: 125px;
  height: 24px;
  left: 35px;
  top: calc((70px - 24px) / 2);
  z-index: 100;
`;
