import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 50px;
  height: 50px;
  position: relative;

  img {
    position: relative;
    top: -50px;
    left: -17px;
  }
  /* background-color: red; */
`;
