import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 55px;
  flex-direction: column;
  flex: 1;
  max-width: ${({ width }) => (width !== null ? width : "100")}%;
  margin: 10px;

  label {
    color: white;
  }

  select {
    background: ${({ theme }) =>
      theme.items.configurador.colors.backgroundResumeLight};
    border: none;
    padding: 5px 10px;
    margin: 5px 0px;
    color: white;
    border-radius: 3px;

    &:focus {
      outline: none;
      border: 1px solid
        ${({ theme }) => theme.items.configurador.colors.primary};
    }
  }
`;
